import classes from "./detail.module.css"
import DetailSlider from './detailslider';
import Detailtable from './detailtable';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import Breadcrumb from '../../components/ui/breadcrumb/breadcrumb';
import Menu from '../../components/ui/menu/index';
import { useSalon } from '../../hooks/query/products';
import SimilarProduct from './similarProduct';
import Meta from '../../components/ui/meta'
// import img from "../../assets/images/kaptal.png"
import DetailSliderMobil from "./detailSliderMobil";
import { getBaskets, postProduct } from "../../services/basketService";
import { ToastContainer, toast } from 'react-toastify';
import './map.css';
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import { useEffect, useState, useRef, useContext } from "react";
// import { ModalNav } from "../../layouts/navbar/modalNavbar/modalNav";
import { ModalCall } from "./modalcall";
import { HomeIcon, LocationIcon, EditProductIcon, AddFavtIcon, DelProductIcon, PremiumProductIcon, ReportProductIcon, ForwardProductIcon } from "../../assets/icons/Icons";
import { AuthContext } from '../../context/AuthContext';
import { MenuContext } from "../../context/MenuContext";
import { baseURL } from "../../helpers/api/request";
// import { FaHeart } from "react-icons/fa";
// import {CgDanger} from "react-icons/cg";
// import {EyeCardIcon} from "../../assets/icons/Icons"


const Detail = () => {

  const navigate = useNavigate();
  const { isAuthenticated, basketListsCount, setBasketListsCount } = useContext(AuthContext)
  const { setShowBasket } = useContext(MenuContext)
  const { productId } = useParams()
  const urlArray = productId.split("-");
  const id = urlArray[urlArray.length - 1];
  const { data, isFetching } = useSalon(id);
  const [addToCart, setAddToCart] = useState(false)
  const [loading, setLoading] = useState(false)
  const [basket, setBasket] = useState(false)
  const [basketLists, setBasketLists] = useState([])
  const [isModalOpenCall, setIsModalOpenCall] = useState(false);
  // const mapContainer = useRef(null);
  const mapLocation = useRef(null);
  const [lng] = useState(49.94901057127427);
  const [lat] = useState(40.41384243911918);
  const [zoom] = useState(9);
  const [API_KEY] = useState('j4WWgdwepZdF7D5WbiTh');

  // const openModal = () => {
  //   setIsModalOpenCall(true);
  // };

  // const sendData = {
  //   product_id: data?.data?.id,
  //   count: 1
  // }

  // const [locations, setLocations] = useState([]);


  // useEffect(() => {

  //   if(!isFetching) {
  //     const shopMap = data?.data?.shop?.shop_information?.map((item) => {
  //       return {
  //         lng: item?.longitude,
  //         lat: item?.latitude
  //       };
  //     });

  //     setLocations((p) => [...locations, shopMap].flat(1))
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isFetching]);


  // // useEffect(() => {
  // //   if(mapLocation.current) return; //stops map from intializing more than once
  // //   mapLocation.current = new maplibregl.Map({
  // //     container: mapContainer.current,
  // //     style: `https://api.maptiler.com/maps/streets-v2/style.json?key=${API_KEY}`,
  // //     center: [lng, lat],
  // //     zoom: zoom
  // //   });
  // //   // map.current.addControl(new maplibregl.NavigationControl(), 'top-right');

  // // });

  // // locations?.map((location) => {
  // //   return new maplibregl.Marker({ color: "#FF0000" })
  // //     .setLngLat([location.lng, location.lat])
  // //     .addTo(mapLocation.current);
  // // });

  // // locations?.map((location) => {
  // //   if(locations[0] === undefined) {
  // //     return navigate('/not-found')
  // //   }
  // //   if(location.lng && location.lat) {
  // //     return new maplibregl.Marker({ color: "#FF0000" })
  // //       .setLngLat([location.lng, location.lat])
  // //       .addTo(mapLocation.current);
  // //   }
  // // });

  // // console.log("locations");
  // // console.log(locations);
  // // console.log("locations");

  // const sendDataSmall = {
  //   product_id: data?.data?.id,
  //   count: 1,
  //   product: {
  //     cover: `${data?.data?.images[0]?.directory}/${data?.data?.images[0]?.file_name}`,
  //     price: data?.data?.price,
  //     product_name: data?.data?.product_name
  //   },
  //   id: data?.data?.id
  // }



  // const handleProduct = async () => {
  //   setLoading(true)
  //   try {
  //     const response = await postProduct(sendData)
  //     if(response.success) {
  //       toast.success('Səbətə əlavə edildi');
  //     }
  //     setBasketListsCount([...basketListsCount, sendDataSmall])
  //     setAddToCart(true)
  //     setShowBasket(true)
  //   } catch(error) {
  //     console.log(error)
  //   } finally {
  //     setLoading(false)
  //   }

  // }

  // const handleOrderProduct = async () => {

  //   try {
  //     const response = await postProduct(sendData)
  //     setBasketListsCount([...basketListsCount, sendData])
  //     toast.success('Səbətə əlavə edildi');
  //     if(response.success) {
  //       navigate("/cart/cartlist")
  //     }
  //     setAddToCart(true)
  //   } catch(error) {
  //     console.log(error)
  //   }

  // }


  // const getAllBaskets = async () => {
  //   try {
  //     const response = await getBaskets()
  //     const data = await response?.data;
  //     setBasketLists(data)
  //   } catch(error) {
  //     console.log(error)
  //   }
  // }

  // useEffect(() => {
  //   if(isAuthenticated) {
  //     getAllBaskets()
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])


  // useEffect(() => {
  //   const checkId = basketLists?.some((basket) => basket.product_id === Number(id))
  //   setBasket(checkId)
  // }, [basketLists, id])


  let dateString = data?.data.created_at;
  let date = new Date(dateString);
  let formattedDate = '';
  if(!isNaN(date.getTime())) {
    const parts = date.toISOString().substring(0, 10).split("-");
    const outputDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
    formattedDate += outputDate;
  }


  // const adress = data?.data?.shop?.shop_information[0]?.address
  // const phone1 = data?.data?.shop?.shop_information[0]?.phone1
  // const phone2 = data?.data?.shop?.shop_information[0]?.phone2
  const price1 = data?.data.price
  const price2 = data?.data.old_price

  // // const parseData = new DOMParser().parseFromString(data?.data?.information, 'text/html');
  // // const textContent = parseData.documentElement.textContent;
  // // const brandName = textContent.replace(/(.*)&nbsp;&nbsp;.*/, '$1');

  const parseData = data?.data.description;
  const textContent = parseData;
  const brandName = textContent;


  let contentBrandName;
  if(brandName === "undefined" || brandName === undefined || brandName === null || brandName === 'null') {
    contentBrandName = ""
  } else {
    contentBrandName = brandName
  }


  let priceNew;
  if(price1 === '0.00' || price1 === 0.00 || price1 === null || price1 === "null" || typeof price1 === "undefined" || price1 === undefined) {
    priceNew = ""
  } else {
    priceNew = `${price1} ₼`
  }



  let priceOld;
  if(price2 === '0.00' || price2 === 0.00 || price2 === null || price2 === "null" || typeof price2 === "undefined" || price2 === undefined) {
    priceOld = ``
  } else {
    priceOld = `${price2} ₼`
  }

  // let goShop;
  // if(data?.data?.shop?.shop_type === 2) {
  //   goShop = `/shops/${data?.data?.shop_id}?shop_type=2&shop_id=${data?.data?.shop_id}`
  // } else if(data?.data?.shop?.shop_type === 1) {
  //   goShop = `/dealers/${data?.data?.shop_id}?shop_type=1&shop_id=${data?.data?.shop_id}`
  // }


  const pageLink = (data?.data.breadcrumb && data?.data.breadcrumb.length > 0) ? data?.data.breadcrumb.map(bread => {
    return {
      link: `/cars?category_id=${bread.id}`,
      content: bread?.category_name
    }
  }) : []


  const image_url = `https://s3.eu-central-1.amazonaws.com/uploads.avtopro.general/storage/`
  const seo_image_url = `https://s3.eu-central-1.amazonaws.com/uploads.avtopro.general/`


  const page = {
    pages: [
      {
        link: "/cars",
        content: " Bütün məhsullar",
      },
      ...pageLink,
      {
        link: `/cars?category_id=${data?.data?.category?.id ?? ""}`,
        content: data?.data?.category?.category_name ?? '',
      }

    ],
  };
  const shopLogo = image_url + data?.data.shop.cover
  const shopName = `${data?.data.shop.name}`
  const created_id = data?.data.id
  // const created_date = new Date(data?.data?.created_at);
  // // const created_at = new Intl.DateTimeFormat('tr-AZ', {
  // //   day: '2-digit',
  // //   month: '2-digit',
  // //   year: 'numeric'
  // // }).format(created_date);

  // // const formattedDate = date.toLocaleString('en-US', {
  // //   year: 'numeric',
  // //   month: '2-digit',
  // //   day: '2-digit',
  // //   hour: '2-digit',
  // //   minute: '2-digit',
  // //   hour12: false
  // // });
  // // const year = formattedDate.split('/')[2];
  // // const month = formattedDate.split('/')[0];
  // // const day = formattedDate.split('/')[1];
  let product_name
  product_name = `${data?.data.make.name} ${data?.data.model.name}`

  const title = `${product_name} | avtopro.az`;
  // // const keywords = `${data?.data?.product_name ? data?.data?.product_name : ''}`
  const keywords = "oem, məhsullar, vin, ehtiyyat hissələri, avtomobil, nəqliyyat vasitəsi, zapcast,  avtopro, aksesuar, ağır texnika, motosiklet, təkərlər, əyləc bəndi, yağlar"
  const description = `${(product_name ? product_name : "") + ' - nəqliyyat vasitələrinə aid hər növ ehtiyyat hissələri və aksesuarların satışı | avtopro.az'}`;
  const image = `${data?.data.shop.cover ? seo_image_url + data?.data.shop.cover + '/' + data?.data.shop.cover : ''}`


  // const searchTerm = 'Avtoprodan yeni müştəri';
  // const encodedSearchTerm = encodeURIComponent(searchTerm);
  // const phoneClear = phone1;
  // let reversedString = "";
  // let resultPhone = "";

  // if(phoneClear == "" || phoneClear == null || phoneClear == undefined) {
  // } else {
  //   reversedString = phoneClear.replace(/\s|\(|\)/g, '').split('').reverse().slice(0, 9).reverse().join('');
  //   resultPhone = "994" + reversedString;
  // }

  // const urlWp = `https://wa.me/${resultPhone}/?text=${encodedSearchTerm}`;
  const siteURL = "https://avtopro.az"

  const currentUrl = window.location.href;
  console.log(data?.data);
  console.log("data?.data");
  return (
    <>

      <Meta title={title} keywords={keywords} description={description} data={data} siteURL={siteURL} currentUrl={currentUrl} image={image}
        shopName={shopName} isDetailPage={true} />
      <Menu />
      <Breadcrumb page={page} />
      <section className={`container ${classes.detail__container}`}>

        <div className={`${classes.detail_slide_sect}`}>
          <Link to="/autosalon" className={`${classes.mobile_show}`}>{product_name}</Link>
          <DetailSlider data={data?.data} />
          <DetailSliderMobil data={data?.data} />
          <div className={`${classes.desctiption} ${classes.mobile_hide}`} dangerouslySetInnerHTML={{ __html: contentBrandName ? `${contentBrandName}` : "" }}></div>

        </div>
        <div className={classes.detailInfo}>
          <div className={classes.detialText}>

            <div className={`${classes.detail_row_mob}`}>
              <div className={`${classes.detail_row}`}>
                <div className={`${classes.sect_flex_mob}`}>
                  <h3><span className={`${classes.mobile_hide}`}>{product_name} </span>  {data?.data.price !== 0.0 && <p className={classes.detial_p}>
                    <strong className={classes.price}>{priceNew}</strong>
                    <b className={`${classes.discount} ${classes.discountDemo}`}>{priceOld}</b>
                  </p>}
                  </h3>
                  <div className={`${classes.top_detail_row}`}>
                    <div className={`${classes.top_detail}`}>
                      <span>Elan nömrəsi:</span>
                      <b>{created_id}</b>
                    </div>
                    <div className={`${classes.top_detail}`}>
                      <span>Yerləşdirmə tarixi:</span>
                      <b>{formattedDate}</b>
                    </div>
                  </div>

                </div>
                <div className={classes.d_flex}>
                  <h3>
                    <strong className={`${classes.price}`}>{priceNew} </strong>
                    <b className={classes.discount}>{priceOld}</b>
                  </h3>
                </div>
              </div>
              <div className={classes.logoShop}>
                {!isFetching && <img src={shopLogo} alt={shopName} />}
                <span>{shopName}</span>
              </div>
            </div>


            <div className={classes.detailMap}>
              <div className={classes.buttons}>
                <button className={`btn  ${classes.btn_call} ${classes.btn__common_call}`}>{EditProductIcon} Elana düzəliş et</button>
                <button className={`btn  ${classes.btn_call} ${classes.btn__common_call}`}>{AddFavtIcon} Seçilmişlərə əlavə et</button>
                <button className={`btn  ${classes.btn_call} ${classes.btn__common_call}`}>{DelProductIcon} Elanı sil</button>
                <button className={`btn  ${classes.btn_call} ${classes.btn__common_call}`}>{PremiumProductIcon} Premium et</button>
                <button className={`btn  ${classes.btn_call} ${classes.btn__common_call}`}>{ReportProductIcon} Şikayət et</button>
                <button className={`btn  ${classes.btn_call} ${classes.btn__common_call}`}>{ForwardProductIcon} Elanı irəli çək</button>
              </div>

            </div>
            {/* <ModalCall phone1={phone1} phone2={phone2} isModalOpenCall={isModalOpenCall} setIsModalOpenCall={setIsModalOpenCall} /> */}
          </div>
          <Detailtable data={data?.data} />

          <div className={`${classes.desctiption} ${classes.mobile_show_sect}`} dangerouslySetInnerHTML={{ __html: contentBrandName ? `${contentBrandName}` : "" }}></div>
        </div>

      </section>

      <SimilarProduct category_id={data?.data?.shop_id} product_id={productId} />

      {/* <ToastContainer position="bottom-right" /> */}
    </>
  )
}

export default Detail