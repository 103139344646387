import React from 'react';
import "./modal.css"

const Modal = ({ isOpen, onClose, children,width,height ,removeX}) => {
 

  const contentWidth={
    width:width,
    height:`${height}vh`
  }

  return (
    <div className={`modal ${isOpen ? 'is-open' : ''}`}>
      <div className="modal-overlay" onClick={onClose}></div>
      <div className={`modal-content ${isOpen ? 'showModal' :""}` }style={contentWidth}>
        {children}
        {removeX ? "" :  <button className="modal-close" onClick={onClose}>X</button>}
      </div>
    </div>
  );
};

export default Modal;