import React, { useEffect, useState, useMemo } from 'react';
import classes from './banner.module.css';
import { Link } from 'react-router-dom';
import axios from "axios";
import { baseURL } from "../../helpers/api/request";

const BannerSlider = () => {
  const [banners, setBanners] = useState([]);
  const [imageIndex, setImageIndex] = useState(0);
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const [loading, setLoading] = useState(true);

  const img_url = "https://s3.eu-central-1.amazonaws.com/uploads.avtopro.general/storage/";

  // API'den banner verilerini al
  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const response = await axios.get(`${baseURL}/banner`);
        setBanners(response.data?.data);
        setLoading(false);
      } catch(error) {
        console.error('API error: ', error);
        setLoading(false);
      }
    };

    fetchBanners();
  }, []);

  // Ekran boyutlarını dinamik olarak güncelle
  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Filtrelenmiş banner'ları hesapla
  const filteredBanners = useMemo(() => {
    return banners.filter(banner => screenSize > 600 ? banner.type === 1 : banner.type === 0);
  }, [banners, screenSize]);

  // Resim değişim fonksiyonu
  const changeImage = () => {
    const newIndex = Math.floor(Math.random() * filteredBanners.length);
    setImageIndex(newIndex);
  };

  // Impression API çağrısı
  const sendImpression = async (id) => {
    try {
      await axios.get(`${baseURL}/banner/impression/${id}`);
    } catch(error) {
      console.error('Impression API error:', error);
    }
  };

  // İlk yüklemede ve her banner değiştiğinde Impression API'ye çağrı
  useEffect(() => {
    if(!loading && filteredBanners.length > 0) {
      sendImpression(filteredBanners[imageIndex]?.id);
      const interval = setInterval(() => {
        changeImage();
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [loading, filteredBanners, imageIndex]);

  // Click API çağrısı
  const handleBannerClick = async (id) => {
    try {
      await axios.get(`${baseURL}/banner/click/${id}`);
    } catch(error) {
      console.error('Click API error:', error);
    }
  };

  // if(loading) {
  //   return <div>Loading...</div>;
  // }

  if(filteredBanners.length === 0) {
    return <div className={`${classes.nobanner}`}><h4>No banners available</h4> </div>;
  }

  const currentImage = img_url + filteredBanners[imageIndex]?.image;

  return (
    <section className={`${classes.banner} mobilNone`}>
      <Link
        to={filteredBanners[imageIndex]?.link}
        target="_blank"
        onClick={() => handleBannerClick(filteredBanners[imageIndex]?.id)}
      >
        <img src={currentImage} alt={filteredBanners[imageIndex]?.name} />
      </Link>
    </section>
  );
};

export default BannerSlider;
