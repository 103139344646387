import request from "../helpers/api/request";

export const postWishLists = async (data) => {
    const response = await request.post(`/account/wishlists`, data);
    return response.data;
};

export const getWishLists = async () => {
    const response = await request.get(`/account/wishlists`);
    return response.data;
};

export const getWishListsId = async () => {
    const response = await request.get(`/account/wishlist-list`);
    return response.data;
};

export const deleteWishlistsItem = async (id) => {
    const response = await request.delete(`account/wishlists/${id}`);
    return response.data;
};
