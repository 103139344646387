import { useQuery } from "@tanstack/react-query";
import { getCarMakes, getSalonMakes, getCountryCarMakes } from "../../services/carMakeService";

export const useCarmakes = (params) => {
    const { data, isError, error, refetch, isStale } = useQuery({
        queryKey: ["car_mekes", params],
        queryFn: () => getCarMakes(params),
        staleTime: 10 * (60 * 1000),
        cacheTime: 10 * (60 * 1000),
        enabled: false,
    });

    return { data, isError, error, refetch, isStale };
};


export const useAllCarMakes = (params) => {
    // const params = { order: "name", direction: "asc", id_car_type: 0 }
    const { data, isError, error, refetch, isStale } = useQuery({
        queryKey: ["car_makes"],
        queryFn: () => getCarMakes(params),
        staleTime: 10 * (60 * 1000),
        cacheTime: 10 * (60 * 1000)
    });

    return { data, isError, error, refetch, isStale };
};

export const useAllCountryCarMakes = (params) => {
    // const params = { order: "name", direction: "asc", id_car_type: 0 }
    const { data, isError, error, refetch, isStale } = useQuery({
        queryKey: ["car_makes"],
        queryFn: () => getCountryCarMakes(params),
        staleTime: 10 * (60 * 1000),
        cacheTime: 10 * (60 * 1000)
    });

    return { data, isError, error, refetch, isStale };
};

export const useAllSalonMakes = (params) => {
    // const params = { order: "name", direction: "asc", id_car_type: 0 }
    const { data, isError, error, refetch, isStale } = useQuery({
        queryKey: ["car_makes"],
        queryFn: () => getSalonMakes(params),
        staleTime: 10 * (60 * 1000),
        cacheTime: 10 * (60 * 1000)
    });

    return { data, isError, error, refetch, isStale };
};

export const usePopularCarMakes = () => {
    const params = { popular: 1, direction: "asc" }
    const { data, isError, error, refetch, isStale } = useQuery({
        queryKey: ["popular_car_makes"],
        queryFn: () => getCarMakes(params),
        staleTime: 10 * (60 * 1000),
        cacheTime: 10 * (60 * 1000),
    });

    return { data, isError, error, refetch, isStale };
};
export const usePopularCountryCarMakes = () => {
    const params = { popular: 1, direction: "asc" }
    const { data, isError, error, refetch, isStale } = useQuery({
        queryKey: ["popular_car_makes"],
        queryFn: () => getCountryCarMakes(params),
        staleTime: 10 * (60 * 1000),
        cacheTime: 10 * (60 * 1000),
    });

    return { data, isError, error, refetch, isStale };
};

export const useCarmake = (params) => {
    const { data, isError, error, refetch, isStale } = useQuery({
        queryKey: ["car_make", params],
        queryFn: () => getCarMakes(params),
        staleTime: 10 * (60 * 1000),
        cacheTime: 10 * (60 * 1000),
    });

    return { data, isError, error, refetch, isStale };
}