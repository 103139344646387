import React, { useState } from 'react';
import "./repairdetail.css";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import Breadcrumb from '../../components/ui/breadcrumb/breadcrumb';
import Meta from '../../components/ui/meta';
import Menu from '../../components/ui/menu';
import { AiFillCar } from "react-icons/ai"
import { Lock, experince } from "../../assets/icons/Icons"
import { HiLocationMarker } from "react-icons/hi"
import { Link, useParams } from 'react-router-dom';
import { useRepairer } from '../../hooks/query/repairers';
import { BsFillTelephoneFill } from "react-icons/bs"

const RepairerDetail = () => {


  const [names, setNames] = useState('');
  const [phone, setPhone] = useState('');
  const [service, setService] = useState('');
  const [notes, setNotes] = useState('');
  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState('');
  const [errors, setErrors] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();

    const newErrors = {};

    if(!names) newErrors.names = 'Ad sahəsi boş qala bilməz.';
    if(!phone) newErrors.phone = 'Telefon sahəsi boş qala bilməz.';
    if(!service) newErrors.service = 'Xidmət sahəsi boş qala bilməz.';
    if(!date) newErrors.date = 'Tarix sahəsi boş qala bilməz.';
    if(!time) newErrors.time = 'Vaxt sahəsi boş qala bilməz.';

    if(Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const appointmentData = {
      names,
      phone,
      service,
      notes,
      date,
      time,
    };

    console.log(appointmentData);
    // Burada verileri backend sunucunuza göndermek için gerekli kodu ekleyebilirsiniz
  };

  const unavailableTimes = ['11:00', '12:00', '15:00', '17:00']; // Örnek olarak uygun olmayan saatler
  const unavailableDates = [
    new Date(2024, 5, 24),
    new Date(2024, 5, 12),
    new Date(2024, 5, 15),
    new Date(2024, 5, 16),
    new Date(2024, 5, 19),
    new Date(2024, 5, 26),
  ]; // Örnek olarak uygun olmayan günler


  const handleTimeSlotClick = (selectedTime) => {
    if(!unavailableTimes.includes(selectedTime)) {
      setTime(selectedTime);
    }
  };

  const tileClassName = ({ date, view }) => {
    if(view === 'month') {
      return unavailableDates.find(d => d.getTime() === date.getTime()) ? 'disavailable' : null;
    }
  };

  const tileDisabled = ({ date, view }) => {
    if(view === 'month') {
      return unavailableDates.find(d => d.getTime() === date.getTime());
    }
    return false;
  };

  const { slug } = useParams()

  const { data } = useRepairer(slug)

  // const position = data?.data?.position_categories.map((category) => {
  //   return <p className='mobilNone' key={category.id}>{Lock} {category.position_category.name}</p>
  // })
  const uniquePositions = Array.from(new Set(data?.data?.position_categories.map((category) => category.position_category.name)));

  const position = uniquePositions.map((positionName) => (
    <p className='mobilNone' key={positionName}>
      {Lock} {positionName}
    </p>
  ));


  const name = `${data?.data?.name} ${data?.data?.surname} `

  const url_image = "https://s3.eu-central-1.amazonaws.com/uploads.avtopro.general/storage/";

  const parseData = new DOMParser().parseFromString(data?.data?.description, 'text/html');
  const textContent = parseData.documentElement.textContent;
  const repairDesciption = textContent.replace(/(.*)&nbsp;&nbsp;.*/, '$1');

  let repairerInfo;
  if(repairDesciption === "undefined") {
    repairerInfo = ""
  } else if(repairDesciption === 'null' || repairDesciption === null) {
    repairerInfo = ""
  } else {
    repairerInfo = repairDesciption
  }


  const page = {
    pages: [
      {
        link: "/ustalar",
        content: "Ustalar",
      },
      {
        content: name,
      }
    ],
  };

  // const carMakes = data?.data?.position_categories.map((item) => item?.car_makes.map((car) => (
  //   <p className='mobilNone' key={item.id}><AiFillCar />  {car?.car_make_id === 0 ? 'Bütün Markalar' : car?.car_make?.name}</p>
  // )))

  const uniqueCarMakes = Array.from(new Set(data?.data?.position_categories.flatMap((item) => (
    item?.car_makes.map((car) => car?.car_make_id === 0 ? 'Bütün Markalar' : car?.car_make?.name)
  ).filter(Boolean))));

  const carMakes = uniqueCarMakes.map((carMakeName, index) => (
    <p className='mobilNone' key={index}>
      <AiFillCar /> {carMakeName}
    </p>
  ));

  const currentUrl = window.location.href;
  const title = `Avtopro - ${name} | avtopro.az `
  const keywords = "usta, avtomobil təmiri, balans, razval, xodovoy təmiri, mühərrik təmiri, yağlama, çilingər, elektrik, diaqnostika, fara təmiri, dəmirci"
  const description = `${(name ? name : "") + ' - nəqliyyat vasitələrinə aid hər növ ehtiyyat hissələri və aksesuarların təmiri və quraşdırılması | avtopro.az'}`;
  const image = `${url_image + data?.data?.photo ? url_image + data?.data?.photo : data?.data?.logo}`
  console.log(data?.data?.position_categories);

  return (
    <>
      <Meta title={title} keywords={keywords} description={description} image={image} currentUrl={currentUrl} />
      <section className='container__bg'>
        <Menu />
        <Breadcrumb page={page} />
        <section className='service__container container' >
          <div className='service__left'>
            <img className='service__img' src={url_image + data?.data?.photo} alt={name} />
            <h3>{name}</h3>
            <div className='service__info'>
              {/* <p><AiFillStar/> 4.8</p> */}
              {position}
              {carMakes}
              <p>{experince} Təcrübə {data?.data?.experience} il</p>
              <p><Link to={`tel:+994 ${data?.data?.number}`} ><BsFillTelephoneFill /> {data?.data?.number}</Link></p>
              <p className='mobilLocation-100'><Link target="_blank" to={`https://www.google.com/maps/search/${data?.data?.address}`}><HiLocationMarker /> {data?.data?.address}</Link></p>
            </div>
            <div className='service__works service__works__mobil'>
              <h3>Gördüyü işlər </h3>
              {data?.data?.position_categories.map((item, index) => (
                <p key={item.id}>{item?.position_category?.name}  <span>{item?.car_makes?.car_make_id === 0 ? 'Bütün markalar' : item?.car_makes[index]?.car_make?.name}</span> </p>
              ))}
            </div>
            <div className='service__note'>
              <h2>QEYD:</h2>
              <p>{repairerInfo}</p>

            </div>
          </div>
          <div className='service__right'>
            <div className='service__slider'>
              {/* <div className="slideShow">
                    <SlideShow/>         
                </div>  */}
              <div className='service__works'>
                <h3>Gördüyü işlər </h3>
                {data?.data?.position_categories.map((item, index) => (
                  <p key={item.id}>{item?.position_category?.name}  <span>{item?.car_makes?.car_make_id === 0 ? 'Bütün markalar' : item?.car_makes[index]?.car_make?.name}</span> </p>
                ))}
              </div>
            </div>
            <div className="appointment-container-item">
              <h5 className='randevu_title'>Randevu götür</h5>
              <div className="appointment-container">
                <form className="appointment-form" onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label>Ad və soyad</label>
                    <input
                      type="text"
                      value={names}
                      onChange={(e) => setNames(e.target.value)}
                      required
                    />
                    {errors.names && <p style={{ color: 'red' }}>{errors.names}</p>}
                  </div>
                  <div className="form-group">
                    <label>Mobil nömrə</label>
                    <input
                      type="tel"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      required
                    />
                    {errors.phone && <p style={{ color: 'red' }}>{errors.phone}</p>}
                  </div>
                  <div className="form-group">
                    <label>Xidmət növü</label>
                    <select
                      value={service}
                      onChange={(e) => setService(e.target.value)}
                      required
                    >
                      <option value="" selected disabled>Xidməti seç</option>
                      {data?.data?.position_categories.map((item, index) => (
                        <option key={item.id} value={item.id}>
                          {item?.position_category?.name} ({item?.car_makes[index]?.car_make?.name})
                        </option>
                      ))}
                    </select>
                    {errors.service && <p style={{ color: 'red' }}>{errors.service}</p>}
                  </div>
                  <div className="form-group">
                    <label>Əlavə istək</label>
                    <textarea
                      value={notes}
                      onChange={(e) => setNotes(e.target.value)}
                    />
                  </div>
                </form>
                <div className="calendar-container">
                  <div className="calendar">
                    <Calendar
                      onChange={setDate}
                      value={date}
                      tileClassName={tileClassName}
                      tileDisabled={tileDisabled}
                    />
                  </div>
                  <div className="time-slots-sect">
                    <h6 className='date-title'>Boş saatlar</h6>
                    {errors.time && <p style={{ color: 'red' }}>{errors.time}</p>}
                    <div className="time-slots">
                      {['11:00', '11:30', '12:00', '12:30', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00'].map(slot => (
                        <button
                          key={slot}
                          className={`time-slot ${unavailableTimes.includes(slot) ? 'disavailable' : 'available'} ${slot === time ? 'selected' : ''}`}
                          onClick={() => handleTimeSlotClick(slot)}
                          disabled={unavailableTimes.includes(slot)}
                        >
                          {slot}
                        </button>
                      ))}
                    </div>

                  </div>

                </div>
              </div>
              <button type="submit" className="submit-button" onClick={handleSubmit}>Təsdiqlə</button>
            </div>
          </div>


        </section>
      </section>
    </>
  )
}

export default RepairerDetail



