import React from 'react'
import { HiChevronLeft } from "react-icons/hi"
import { useContext } from 'react'
import { AuthContext } from '../../../context/AuthContext'
import CartTabPanel from '../../../components/cartTab'
import classes from "../carts.module.css"
import "./notification.css"
import Menu from '../../../components/ui/menu'
import { Link } from 'react-router-dom'


const MyNotification = () => {

    const { visibleMobil, setVisibleMobil } = useContext(AuthContext)

    const handleLeftClick = () => {
        setVisibleMobil(!visibleMobil)
    }

    return (
        <>
            <Menu />
            <section className={`${classes.carts__container} container notification__container notification__all  `}>
                <CartTabPanel activeTab='mynotification' />
                <div className={visibleMobil ? 'container__tabs' : " activeTabs"}>
                    <div className='notif_header'>
                        <div className='mobil__title__cart'>
                            <span className='left__mobil__icon' onClick={handleLeftClick}><HiChevronLeft fontSize="2rem" /></span>
                            <h3> Bildirişlər </h3>
                            <span></span>
                        </div>
                        <h3 className='desktop__title__cart'> Bildirişlər </h3>
                        <Link to="/cart/notificationsetting" className='notif_setting'></Link>
                    </div>
                    <div className='notif_container'>

                        <div className='notif_items'>
                            <div className='notif_index'>1.</div>
                            <div className='notif_content'>
                                <div className='notif_title'>Lorem ipsum dolor sit amet, </div>
                                <div className='notif_info'>
                                    consectetur adipiscing elit. Morbi volutpat hendrerit aliquet. Nam sit amet nibh massa. Praesent vel sapien vel nibh lacinia varius. Suspendisse lobortis consectetur tincidunt. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere .
                                </div>
                            </div>
                            <div className='notif_date_time'>
                                <div className='notif_date'>28.05.2023</div>
                                <div className='notif_time'>14:25</div>
                            </div>
                            <Link to="/cart/notificationinner" className='notif_read'><span>Daha çox</span></Link>
                        </div>
                        <div className='notif_items active'>
                            <div className='notif_index'>1.</div>
                            <div className='notif_content'>
                                <div className='notif_title'>Lorem ipsum dolor sit amet, </div>
                                <div className='notif_info'>
                                    consectetur adipiscing elit. Morbi volutpat hendrerit aliquet. Nam sit amet nibh massa. Praesent vel sapien vel nibh lacinia varius. Suspendisse lobortis consectetur tincidunt. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere .
                                </div>
                            </div>
                            <div className='notif_date_time'>
                                <div className='notif_date'>28.05.2023</div>
                                <div className='notif_time'>14:25</div>
                            </div>
                            <Link to="/cart/notificationinner" className='notif_read'><span>Daha çox</span></Link>
                        </div>
                        <div className='notif_items'>
                            <div className='notif_index'>1.</div>
                            <div className='notif_content'>
                                <div className='notif_title'>Lorem ipsum dolor sit amet, </div>
                                <div className='notif_info'>
                                    consectetur adipiscing elit. Morbi volutpat hendrerit aliquet. Nam sit amet nibh massa. Praesent vel sapien vel nibh lacinia varius. Suspendisse lobortis consectetur tincidunt. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere .
                                </div>
                            </div>
                            <div className='notif_date_time'>
                                <div className='notif_date'>28.05.2023</div>
                                <div className='notif_time'>14:25</div>
                            </div>
                            <Link to="/cart/notificationinner" className='notif_read'><span>Daha çox</span></Link>
                        </div>
                        <div className='notif_items'>
                            <div className='notif_index'>1.</div>
                            <div className='notif_content'>
                                <div className='notif_title'>Lorem ipsum dolor sit amet, </div>
                                <div className='notif_info'>
                                    consectetur adipiscing elit. Morbi volutpat hendrerit aliquet. Nam sit amet nibh massa. Praesent vel sapien vel nibh lacinia varius. Suspendisse lobortis consectetur tincidunt. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere .
                                </div>
                            </div>
                            <div className='notif_date_time'>
                                <div className='notif_date'>28.05.2023</div>
                                <div className='notif_time'>14:25</div>
                            </div>
                            <Link to="/cart/notificationinner" className='notif_read'><span>Daha çox</span></Link>
                        </div>
                        <div className='notif_items'>
                            <div className='notif_index'>1.</div>
                            <div className='notif_content'>
                                <div className='notif_title'>Lorem ipsum dolor sit amet, </div>
                                <div className='notif_info'>
                                    consectetur adipiscing elit. Morbi volutpat hendrerit aliquet. Nam sit amet nibh massa. Praesent vel sapien vel nibh lacinia varius. Suspendisse lobortis consectetur tincidunt. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere .
                                </div>
                            </div>
                            <div className='notif_date_time'>
                                <div className='notif_date'>28.05.2023</div>
                                <div className='notif_time'>14:25</div>
                            </div>
                            <Link to="/cart/notificationinner" className='notif_read'><span>Daha çox</span></Link>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default MyNotification