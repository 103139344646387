import React, { useState, useEffect, useCallback } from 'react';
import { YMaps, Map, ZoomControl, Placemark, GeolocationControl } from '@pbe/react-yandex-maps';
import classes from "../carts.module.css"
import { Link } from 'react-router-dom';

const YMap = ({ initialCoords, setMapLng, setMapLat, errors, register }) => {
  const [markerCoords, setMarkerCoords] = useState(initialCoords || [40.409264, 49.867092]);
  const [placeName, setPlaceName] = useState('');
  const [mapZoom, setMapZoom] = useState(9);
  const [openSearch, setOpenSearch] = useState(false)
  const [getDataSearch, setGetDataSearch] = useState([])
  const screenSize = window.innerWidth

  useEffect(() => {
    if(initialCoords) {
      setMarkerCoords(initialCoords);
    }
  }, [initialCoords]);

  const handleMarkerDrag = (e) => {
    const draggedCoords = e.get('target').geometry.getCoordinates();
    setMarkerCoords(draggedCoords);
    setMapLng(draggedCoords[1]);
    setMapLat(draggedCoords[0]);
  };


  const handleMapClick = (e) => {
    const clickedCoords = e.get('coords');
    setMarkerCoords(clickedCoords);
    setMapLng(clickedCoords[1]);
    setMapLat(clickedCoords[0]);
  };

  const apiKey = '879a3590-ccee-42bf-bd5d-4d336e4ad116'; // Replace with your Yandex Geocoding API key

  const fetchCoordinates = useCallback(async () => {
    try {
      const query = encodeURIComponent(placeName);
      const apiUrl = `https://geocode-maps.yandex.ru/1.x/?apikey=${apiKey}&geocode=${query}&format=json&lang=az-AZ`;
      const response = await fetch(apiUrl);
      const data = await response.json();
      const pos = data.response.GeoObjectCollection.featureMember[0].GeoObject.Point.pos;
      const [longitude, latitude] = pos.split(' ');
      const result = data?.response?.GeoObjectCollection?.featureMember?.map((item) => item?.GeoObject?.metaDataProperty?.GeocoderMetaData?.text)
      setGetDataSearch(result)
      setMarkerCoords([latitude, longitude]);
      setMapLng(longitude);
      setMapLat(latitude);
      setMapZoom(15);
    } catch(error) {
      console.error('Error:', error);
    }
  }, [apiKey, placeName, setMarkerCoords, setMapLng, setMapLat]);

  useEffect(() => {
    if(placeName.trim() !== '') {
      fetchCoordinates();
    }
  }, [fetchCoordinates, placeName]);


  const handleInputChange = (e) => {
    setPlaceName(e.target.value);
    setOpenSearch(true)
    if(e.target.value.length === 0) {
      setOpenSearch(false)
    }
  };

  const boundingBox = '44.7634,38.3921~50.3703,41.9413';

  const handleClick = (item) => {
    setPlaceName(item);
    setOpenSearch(false)
  }


  return (
    <div >
      <div className={classes.formItemOne} style={{ marginBottom: "2rem", position: "relative" }}>
        <h5>Ünvan</h5>
        <input type="text" placeholder='Şəhər, küçə' value={placeName}  {...register('address')} onChange={handleInputChange} />
        {openSearch && <ul className={'yandexSearch'} style={{ height: getDataSearch?.length >= 0 ? "10rem" : "0" }} >
          {
            getDataSearch.slice(0, 3).map((item, i) => {
              return (
                <li key={i}>
                  <Link onClick={() => handleClick(item)}>
                    {item}
                  </Link>
                </li>
              )
            })
          }
        </ul>
        }
        <p className='dangerText'>{errors}</p>
      </div>

      <YMaps query={{ apikey: '879a3590-ccee-42bf-bd5d-4d336e4ad116', lang: 'az' }}>
        <Map
          onClick={handleMapClick}
          state={{
            center: markerCoords,
            zoom: mapZoom,
            boundedBy: boundingBox
          }}
          width='100%'
          height={screenSize > 600 ? "350px" : "300px"}
        >
          <Placemark
            geometry={markerCoords}
            options={{ draggable: true }}
            onDragEnd={handleMarkerDrag}
          />
          <ZoomControl options={{ float: 'left' }} />
          {/* <FullscreenControl /> */}
          <GeolocationControl />
        </Map>
      </YMaps>
    </div>
  );
};

export default YMap;
