import request from "../helpers/api/request";

export const getBaskets = async () => {
    const response = await request.get(`/baskets`);
    return response.data;
};

export const postProduct = async (data) => {
    const response = await request.post(`/baskets`, data);
    return response.data;
    
};

export const deleteBasketItem = async (id) => {
    const response = await request.delete(`/baskets/${id}`);
    return response.data;
    
};

export const updateBasketItem = async (cart) => {
    const param={count:cart?.count}
    const response = await request.put(`/baskets/${cart.id}`, param );
    return response.data;
    
};

export const productBasket = async (id) => {
    const response = await request.get(`/baskets/check_product/${id}`);
    return response.data;
};



