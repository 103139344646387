import React, { useDeferredValue, useMemo, useState } from "react";
import { FiSearch } from "react-icons/fi";
import Breadcrumb from "../../components/ui/breadcrumb/breadcrumb";
import classes from "./dealers.module.css";
import DealerCard from "./dealer-card";
import Menu from "../../components/ui/menu";
import { useShops } from "../../hooks/query/shop";
import Meta from "../../components/ui/meta";
import { Link } from "react-router-dom";
import { SlArrowLeft } from "react-icons/sl";


const Dealers = () => {
    const { data } = useShops(1);
    const [name, setName] = useState("");

    const deferredName = useDeferredValue(name);

    const handleOnChange = (event) => {
        setName(event.target.value);
    };

    const list = useMemo(() => {
        if(deferredName) {
            return data?.data?.filter((shop) => shop.name.toLowerCase().includes(deferredName.toLowerCase()));
        }

        return data?.data;
    }, [deferredName, data?.data]);


    const page = {
        pages: [
            {
                link: "/dealers",
                content: "Rəsmi Diler",
            },
        ],
    };

    const currentUrl = window.location.href;
    const title = "Avtopro - Rəsmi dilerlər | avtopro.az";
    const keywords = "rəsmi dilerlər, dilerlər, avtomobil, nəqliyyat, nəqliyyat vasitəsi, zapcast, vin, oem, avtopro, aksesuar, motosiklet, təkərlər, əyləc bəndi, yağlar"
    const description = "Rəsmi diler magazalarında nəqliyyat vasitələrinə aid hər növ ehtiyyat hissələri və aksesuarların satışı avtopro.az'da";

    return (
        <>
            <Meta title={title} keywords={keywords} description={description} currentUrl={currentUrl} />
            <Menu />
            <Breadcrumb page={page} none='dnone' />
            <section className={`container`}>
                <Link className={`${classes.lnone}`} to="/"><SlArrowLeft color='#181818' fontSize="1.6rem" /></Link>
                <h1 className={`${classes.head}`} >
                    Rəsmi dillerlər</h1>
                <div className="shop__search">
                    <form>
                        <input type="text" onChange={handleOnChange} placeholder="Mağaza axtar..." />
                        <button>
                            <FiSearch color="#68A834" fontSize="2rem" />
                        </button>
                    </form>
                </div>
                <div className={classes.dealers__cards}>
                    {list?.map((shop, i) => (
                        <DealerCard key={i} shop={shop} />
                    ))}
                </div>
            </section>
        </>
    );
};

export default Dealers;
