import { autoComplete, getSalon, getSalons, getProduct, getProducts, getProductsLates, getProductsCount, productService } from "../../services/productService";
import { useQuery } from "@tanstack/react-query";


const individualParams = { shop_id: 0, random: 10 };
const shopParams = { shop_type: 2, random: 10 };
const officialParams = { shop_type: 1, random: 10 };
// const officialParamsLates = { shop_type: 1, random: 10 };

const latestShopParams = {
    shop_type: 1,
    limit: 10,
    sortBy: "created_at",
    sortOrder: "asc",
};


export const useShopProductsLates = () => {
    return useQuery(["latesSliderData"], () => getProductsLates(latestShopParams), {
        staleTime: 10 * (60 * 1000),
        cacheTime: 10 * (60 * 1000),
    });
};





export const useIndividualProducts = () => {
    return useQuery(["individualSliderData"], () => getProducts(individualParams), {
        staleTime: 10 * (60 * 1000),
        cacheTime: 10 * (60 * 1000),
    });
};

export const useShopProducts = () => {
    const { data, isFetching } = useQuery(["shopSliderData"], () => getProducts(shopParams), {
        staleTime: 10 * (60 * 1000),
        cacheTime: 10 * (60 * 1000),
    });

    return { data, isFetching }
};

export const useOfficialProducts = () => {
    const { data, isFetching } = useQuery(["officialSliderData"], () => getProducts(officialParams), {
        staleTime: 10 * (60 * 1000),
        cacheTime: 10 * (60 * 1000),
    });
    return { data, isFetching }
};

export const useProducts = (params) => {
    const { data, isLoading, error, refetch, isStale, isFetching } = useQuery(
        ["products", params],
        () => getProducts(params),
        {
            staleTime: 10 * (60 * 1000),
            cacheTime: 10 * (60 * 1000),
            keepPreviousData: true,
        }
    );


    return { data, isLoading, error, refetch, isStale, isFetching };
};

export const useSalons = (params) => {
    const { data, isLoading, error, refetch, isStale, isFetching } = useQuery(
        ["products", params],
        () => getSalons(params),
        {
            staleTime: 10 * (60 * 1000),
            cacheTime: 10 * (60 * 1000),
            keepPreviousData: true,
        }
    );


    return { data, isLoading, error, refetch, isStale, isFetching };
};



export const useProduct = (id) => {
    const { data, isError, error, isFetching } = useQuery({
        queryKey: [`products_${id}`],
        queryFn: () => getProduct(id),
        staleTime: 10 * (60 * 1000),
        cacheTime: 10 * (60 * 1000),
    });

    return { data, isError, error, isFetching };
};

export const useSalon = (id) => {
    const { data, isError, error, isFetching } = useQuery({
        queryKey: [`${id}`],
        queryFn: () => getSalon(id),
        staleTime: 10 * (60 * 1000),
        cacheTime: 10 * (60 * 1000),
    });

    return { data, isError, error, isFetching };
};


export const useProductCount = () => {

    const { data, isError, error } = useQuery({
        queryKey: [`products_count`],
        queryFn: () => getProductsCount(),
        staleTime: 10 * (60 * 1000),
        cacheTime: 10 * (60 * 1000),
    });

    return { data, isError, error };
};



export const useSimilarProducts = (params) => {
    const { data, isLoading, error, refetch, isStale, isFetching } = useQuery(
        ["products", params],
        () => getProducts(params),
        {
            staleTime: 10 * (60 * 1000),
            cacheTime: 10 * (60 * 1000),
            keepPreviousData: true,
            enabled: false
        }
    );

    return { data, isLoading, error, refetch, isStale, isFetching };
};
export const useSimilarProductsSalon = (params) => {
    const { data, isLoading, error, refetch, isStale, isFetching } = useQuery(
        ["products", params],
        () => getSalons(params),
        {
            staleTime: 10 * (60 * 1000),
            cacheTime: 10 * (60 * 1000),
            keepPreviousData: true,
            enabled: false
        }
    );

    return { data, isLoading, error, refetch, isStale, isFetching };
};


export const useAutoComplete = (query) => {
    const { data, isLoading, error, refetch, isStale, isFetching } = useQuery(
        ["auto_complete", query],
        () => autoComplete(query),
        {
            staleTime: 10 * (60 * 1000),
            cacheTime: 10 * (60 * 1000),
            enabled: false
        }
    );

    return { data, isLoading, error, refetch, isStale, isFetching };
};
