import { createContext, useEffect } from "react";
import React, { useState } from "react";
import { authUser } from "../services/authService";
import { getWishLists, getWishListsId } from "../services/wishListService";
import { getBaskets } from "../services/basketService";

export const AuthContext = createContext();

const AuthContextProvider = ({ children }) => {

  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem('token') ?? false);
  const [wishListItem, setWishListItem] = useState([]);
  const [wishLists, setWishLists] = useState([])
  const [basketListsCount, setBasketListsCount] = useState([])
  const [visibleMobil, setVisibleMobil] = useState(false)
 
  const getUser = async () => {
    try {
      if (isAuthenticated) {
        const response = await authUser();
        setUser(response.data)
      }
    } catch (e) {

    }
  }

  useEffect(() => {
    getUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])



  const getWishListIds = async () => {
    try {
      if (isAuthenticated) {
        const response = await getWishListsId();
        setWishListItem(response.data)
      }
    } catch (e) {

    }
  }


  useEffect(() => {
    getWishListIds()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])



  const getAllWishLists = async () => {
    try {
      if (isAuthenticated) {
        const response = await getWishLists()
        const data = await response?.data.reverse();
        const newWishItems = data?.map((list) => {
          return {
            category_id: list?.product?.category_id,
            cover: list?.product?.cover,
            created_at: list?.product?.created_at,
            id: list?.product?.id,
            old_price: list?.product?.old_price,
            price: list?.product?.price,
            product_name: list?.product?.product_name,
            shop_id: list?.product?.shop_id,
            shop_type: list?.product?.shop?.shop_type,
            views: list?.product?.views
          }
        })
        setWishLists(newWishItems)
      }else {
        setWishLists([])
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getAllWishLists() 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])


  const getAllBasketsCount = async () => {
    try {
      if (isAuthenticated) {
        const response = await getBaskets()
        const data = await response?.data;
        setBasketListsCount(data)
      }else{
        setBasketListsCount([])
      }
    } catch (error) {
      console.log(error)
    }
  }


  useEffect(() => {
    getAllBasketsCount()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])

  

  return <AuthContext.Provider value={{ user, setUser, setIsAuthenticated, isAuthenticated, getAllWishLists, wishListItem, setWishListItem, wishLists, setWishLists,basketListsCount,setBasketListsCount ,getAllBasketsCount,visibleMobil ,setVisibleMobil}}>{children}</AuthContext.Provider>;
};

export default AuthContextProvider;
