import React from 'react'
import { HiChevronLeft } from "react-icons/hi"
import { useContext } from 'react'
import { AuthContext } from '../../../context/AuthContext'
import CartTabPanel from '../../../components/cartTab'
import classes from "../carts.module.css"
import Menu from '../../../components/ui/menu'


const CashBack = () => {
  
  const { visibleMobil,setVisibleMobil } = useContext(AuthContext)
   
  const handleLeftClick=()=>{
    setVisibleMobil(!visibleMobil)
  }

  return (
    <>
      <Menu />
      <section className={`${classes.carts__container} container `}>
        <CartTabPanel activeTab='cashback' />
        <div className={visibleMobil ? 'container__tabs' : " activeTabs"}>
          <div className='mobil__title__cart'>
            <span className='left__mobil__icon' onClick={handleLeftClick}><HiChevronLeft fontSize="2rem" /></span>
            <h3> Tezliklə </h3>
            <span></span>
          </div>
          <h3 className='desktop__title__cart'> Tezliklə </h3>
          
        </div>
      </section>
    </>
  )
}

export default CashBack