import React from "react";
import { Link, useLocation } from "react-router-dom";
import "../../assets/styles/shop-card.css";

const DealerCard = ({ shop }) => {
    const image_url = "https://s3.eu-central-1.amazonaws.com/uploads.avtopro.general/storage/" + shop.logo;

    let carMake
    if(shop?.car_make_id !== null) {
        carMake = `&car_make_id=${shop?.car_make_id}`
    } else {
        carMake = ""
    }


    const siteURL = "https://avtopro.az"
    const { pathname } = useLocation();
    const pageLink = pathname + "/" + shop.id;

    const postalSchema = {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": `${shop?.name}`,
        "url": `${siteURL + pageLink}`,
        "logo": `${image_url}`,
        "description": `${shop?.name} - mağazada məhsulların satışı avtopro.az'da"`,
        "address": {
            "@type": "PostalAddress",
            "streetAddress": "-",
            "addressLocality": "-",
            "addressRegion": "-",
            "postalCode": "-",
            "addressCountry": "Azerbaijan"
        },
        "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "-",
            "contactType": "customer service"
        }

    };

    return (
        <article className="shopCard dealerCard">
            <Link to={`/dealers/${shop.id}?shop_type=1${carMake}&shop_id=${shop.id}`} className="shop__card__link">
                <img src={image_url} alt={shop.name} />{" "}
            </Link>
            <h3>{shop.name}</h3>
            <p className="shopCard__count">
                <span> {shop.products_count > 0 ? `${shop.products_count} Məhsul` : "Tezliklə"}  </span>
            </p>
            <script type="application/ld+json">
                {JSON.stringify(postalSchema)}
            </script>
        </article>
    );
};

export default DealerCard;
