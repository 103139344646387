// import React, { memo } from "react";
// import Select from "react-select";
// import classes from "../../../components/Tabpanel/tabpanel.module.css";
// import {CustomStyles} from "../../../helpers/contants/s3"

// const MobilBrand = (props) => {
//     const { setBrandIndex}=props

//     const brands=[
//         {
//             value:0,
//             label:'Populyar markalar'
//         },
//         {
//             value:1,
//             label:'Bütün markalar'
//         }
//     ]
//    const handleChange=(e)=>{
//     setBrandIndex(e.value)
//    }



//     return (
//         <>
//             <Select
//                 className={classes.selectItem}
//                 onChange={handleChange}
//                 placeholder={"Markalar"}
//                 options={brands}
//                 styles={CustomStyles}
//             />
//         </>
//     );
// };

// export default memo(MobilBrand);




import React, { memo, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import classes from "./mobiletab.module.css";

const MobilBrand = (props) => {
    const { branddata, brand, setBrandIndex, brandIndex } = props
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isClicked, setIsClicked] = useState(false);

    const handleMobSelectValClick = () => {
        setIsClicked(!isClicked);
    };

    const brandClick = (id, index, countryName) => {
        setCurrentIndex(index);
        setBrandIndex(id);
        setActiveCountry(countryName);

    };

    const brands = branddata || {};
    const sortedCountryNames = Object.keys(brands);
    const [activeCountry, setActiveCountry] = useState(sortedCountryNames[19999991]);
    useEffect(() => {
        if(!activeCountry || activeCountry === undefined) {
            setBrandIndex(19999991);
            setActiveCountry("Populyar markalar");
        }
    }, [activeCountry, brandIndex]);
    
    return (

        <div className={`${classes.mob_select_tab} ${isClicked ? "clicked_tab" : ""}`}>
            <div className={`${classes.mob_select_val} mob_select_val`} onClick={handleMobSelectValClick}>{activeCountry}</div>
            <ul className={`${classes.tabs_mbrand} tabs_mbrand`}>

                {brand && (
                    <li onClick={handleMobSelectValClick}>
                        <Link
                            className={brandIndex === 19999991 ? "activeTab" : ""}
                            onClick={() => brandClick(19999991, 0, "Populyar markalar")}
                        >
                            Populyar markalar
                        </Link>
                    </li>
                )}
                {sortedCountryNames.map((countryName, index) => (
                    // <div key={countryName}>
                    //     <h2>{countryName}</h2>
                    //     <ul>
                    //         {brands[countryName].map((brand) => (
                    //             <li key={brand.id}>{brand.name}</li>
                    //         ))}
                    //     </ul>
                    // </div>

                    <li key={countryName}
                        onClick={() => {
                            handleMobSelectValClick()
                            brandClick(index, index, countryName);
                        }}
                    // onClick={() => brandClick(index, index, countryName)}
                    >
                        <Link
                            className={
                                index === currentIndex && brandIndex !== 19999991
                                    ? "activeTab"
                                    : ""
                            }
                        >
                            {countryName}
                        </Link>
                    </li>
                ))}

            </ul>
        </div>
    );
};

export default memo(MobilBrand);
