import React from 'react'
import Breadcrumb from '../../components/ui/breadcrumb/breadcrumb'
import Menu from '../../components/ui/menu'
import Meta from '../../components/ui/meta';
import classes from "./oils.module.css"
import img from "../../assets/images/oils.png"
import { Link } from 'react-router-dom'
import img1 from "../../assets/images/oil1.png"
import img2 from "../../assets/images/oil2.png"
import img3 from "../../assets/images/oil3.png"
import img4 from "../../assets/images/oil4.png"
import Product from '../../components/product/product'

const oils = [img1, img2, img3, img4]

const Oils = () => {

    const page = {
        pages: [
            {
                link: "/oils",
                content: "Yağlar və mayelər",
            }
        ],
    };

    const currentUrl = window.location.href;
    const title = "Avtopro - Yaglar | avtopro.az";
    const keywords = "yağlar, avtomobil yağlar, balans, razval, xodovoy təmiri, mühərrik təmiri, yağlama, çilingər, elektrik, diaqnostika, fara təmiri, dəmirci"
    const description = "Nəqliyyat vasitələrinə aid hər növ yağların satışı avtopro.az'da";

    return (
        <>
            <Meta title={title} keywords={keywords} description={description} currentUrl={currentUrl} />
            <Breadcrumb page={page} />
            <div className={classes.oilsBanner}>
                <img src={img} alt="Oil banner" />
            </div>
            <section className='container'>
                <h3 className={classes.oilTitle}>Yağlar və mayelər</h3>
                <div className={` ${classes.motorOils}`}>
                    {
                        oils.map((oil, i) => (
                            <Link key={i}>
                                <img src={oil} alt="Maşın Yağları" />
                            </Link>
                        ))
                    }
                </div>
            </section>
            <Product end="end" />
        </>
    )
}

export default Oils