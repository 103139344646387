import React, { memo, useEffect, useState } from 'react'
import classes from "./brand.module.css"
import Tabpanel from '../../../components/Tabpanel/index'
import Boxes from "../../../components/ui/boxes/boxes"
import { Link } from 'react-router-dom'
import { useAllCountryCarMakes, usePopularCountryCarMakes } from '../../../hooks/query/carMake'
import MobilBrand from './mobilBrand'


const Brand = () => {

  const { data: all_car_makes, refetch, isStale } = useAllCountryCarMakes();
  const { data: popular_car_makes } = usePopularCountryCarMakes();

  const [brandIndex, setBrandIndex] = useState(19999991);

  const brandCountry = all_car_makes?.data?.countries || {}


  const sortedCountryNames = Object.keys(brandCountry);
  const [activeCountry, setActiveCountry] = useState(sortedCountryNames[brandIndex]);


  useEffect(() => {
    if(sortedCountryNames.length > 0) {
      setActiveCountry(sortedCountryNames[brandIndex]);
    }
  }, [brandIndex, sortedCountryNames]);


  useEffect(() => {
    if(isStale) {
      refetch();
    }
  }, [brandIndex, refetch, isStale]);

  const img_url_car_make = "https://s3.eu-central-1.amazonaws.com/uploads.avtopro.general/"

  const [isBrandContainerOpen, setIsBrandContainerOpen] = useState(false);



  const handleMoreDropClick = () => {
    console.log("saasddada");
    setIsBrandContainerOpen(!isBrandContainerOpen);
  };

  return (
    <>
      <section className={`container ${classes.brand} ${isBrandContainerOpen ? `${classes.opened}` : ''} `}>
        <h3 className="text-title">Markalar</h3>
        <div className={`${classes.brand_tabs}`}>
          <Tabpanel brand={true} setBrandIndex={setBrandIndex} brandIndex={brandIndex} setActiveCountry={setActiveCountry} activeCountry={activeCountry} branddata={brandCountry} />
        </div>
        <MobilBrand brand={true} setBrandIndex={setBrandIndex} brandIndex={brandIndex} setActiveCountry={setActiveCountry} activeCountry={activeCountry} branddata={brandCountry} />
        <div className={` ${brandIndex !== 19999991 ? classes.allBrand : classes.brand__container} brandsList `}>

          {
            brandIndex === 19999991 && popular_car_makes?.data?.popular?.map((car_make) => (
              <div className={`${classes.br_arw}`}>
                <Boxes
                  key={car_make.id}
                  name={car_make.name}
                  image={img_url_car_make + car_make?.logo}
                  onClickLink={`/products?car_make_id=${car_make.id}`}
                />
              </div>
              // <div className={`${classes.br_col}`} key={car_make.id}>
              //   <Link to={`/products?car_make_id=${car_make.id}`}>{car_make.name}</Link>
              // </div>
            ))
          }
          {/* {
          brandIndex === 1 && brandCountry?.map((item) => (
            <li key={item.id}>
              <Link to={`/products?car_make_id=${item.id}`}>{item.name}</Link>
            </li>
          )) */}




          {brandIndex !== 19999991 && brandCountry[activeCountry]?.map((brand) => (
            <div className={classes.br_col}>
              <li className={`${classes.br_col_in}`} key={brand.id}>
                <Link to={`/products?car_make_id=${brand.id}`}>{brand.name}<span className={`${classes.arw_r_b}`}></span></Link>
              </li>
            </div>
          ))}


          {/* <div className={`${classes.br_col}`}>
          {sortedCountryNames.map((countryName, index) => (
            <div key={countryName}>
              <ul>
              {brandCountry[countryName].map((brand) => (
                <li key={brand.id}>
                  <Link to={`/products?car_make_id=${brand.id}`}>{brand.name}</Link>
                </li>
              ))}
              </ul>
            </div>
          ))}
        </div> */}

          {/* } */}


        </div>

      </section>

      {/* <div className={`${classes.moreDrop} ${isBrandContainerOpen ? `${classes.openedDrop}` : ''}`} onClick={handleMoreDropClick} >
        <span className={`${classes.more_text}`}>Daha çox </span><span className={`${classes.less_text}`}>Daha az </span> <i className="fas fa-angle-down "></i>
      </div> */}
    </>
  )
}

export default memo(Brand)

