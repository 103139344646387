import React, { useState } from 'react'
import classes from "./detail.module.css"
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';

const Detailtable = ({ data }) => {
    const { t } = useTranslation();

    const [count, setCount] = useState(3);

    // const dataCount = data?.products_car_info?.slice(0, count);

    // const handleShowMore = () => {
    //     setCount(data?.products_car_info?.length);
    // };

    if(!data) {
        return <div>Loading...</div>; // Data yüklenene kadar gösterilecek bir içerik
    }

    let fuel_type_val;
    if(data.fuel_type === 0) {
        fuel_type_val = '';
    } else if(data.fuel_type === 1) {
        fuel_type_val = 'Benzin';
    } else if(data.fuel_type === 2) {
        fuel_type_val = 'Dizel';
    } else if(data.fuel_type === 3) {
        fuel_type_val = 'Qaz';
    } else if(data.fuel_type === 4) {
        fuel_type_val = 'elektrik';
    } else if(data.fuel_type === 5) {
        fuel_type_val = 'hibrid';
    } else if(data.fuel_type === 4) {
        fuel_type_val = 'Plugin hibrid';
    }

    let range_val;
    if(data.mileage_type === 0) {
        range_val = 'km';
    } else if(data.mileage_type === 1) {
        range_val = 'mil';
    }

    let transmission_val;
    if(data.transmission === 1) {
        transmission_val = 'Avtomat';
    } else if(data.transmission === 2) {
        transmission_val = 'Variator';
    } else if(data.transmission === 3) {
        transmission_val = 'Mexanik';
    } else if(data.transmission === 4) {
        transmission_val = 'Robot';
    } else if(data.transmission === 5) {
        transmission_val = 'Reduktor';
    }

    let drive_type_val;
    if(data.drive_type === 0) {
        drive_type_val = '';
    } else if(data.drive_type === 1) {
        drive_type_val = 'Ön';
    } else if(data.drive_type === 2) {
        drive_type_val = 'Ön';
    } else if(data.drive_type === 3) {
        drive_type_val = 'Tam';
    }

    let new_val;
    if(data.new === 0) {
        new_val = 'Hamısı';
    } else if(data.new === 1) {
        new_val = 'Yeni';
    } else if(data.new === 2) {
        new_val = 'Sürülmüş';
    }

    let crash_val;
    if(data.crash === 0) {
        crash_val = 'Vuruğu yoxdur';
    } else if(data.crash === 1) {
        crash_val = 'Yalnız qəzalı avtomobillər';
    }

    let colored_val;
    if(data.colored === 0) {
        colored_val = 'rənglənməyib';
    } else if(data.colored === 1) {
        colored_val = '';
    }

    return (
        <>
            {
                // data?.products_car_info.length > 0 ? (
                <>
                    <table className={`${classes.tableDetail} ${classes.tableDetailAutosalon}`}>
                        <tbody>
                            <tr>
                                <td>Şəhər</td>
                                <td>{data.city && data.city.name ? data.city.name : ''}</td>
                            </tr>
                            <tr>
                                <td>Marka</td>
                                <td>{data.make.name}</td>
                            </tr>
                            <tr>
                                <td>Model</td>
                                <td>{data.model.name}</td>
                            </tr>
                            <tr>
                                <td>Buraxılış ili</td>
                                <td>{data.year}</td>
                            </tr>
                            <tr>
                                <td>Ban növü</td>
                                <td>{data.body_type.name}</td>
                            </tr>
                            <tr>
                                <td>Rəng</td>
                                <td>{data.color.name}</td>
                            </tr>
                            <tr>
                                <td>Mühərrik</td>
                                <td>{data.engine_volume} L / {data.horse_power} a.g / {fuel_type_val}</td>
                            </tr>
                            <tr>
                                <td>Yürüş</td>
                                <td>{data.mileage} {range_val}</td>
                            </tr>
                            <tr>
                                <td>Sürətlər qutusu</td>
                                <td>{transmission_val}</td>
                            </tr>
                            <tr>
                                <td>Ötürücü</td>
                                <td>{drive_type_val}</td>
                            </tr>
                            <tr>
                                <td>Yeni</td>
                                <td>{new_val}</td>
                            </tr>
                            <tr>
                                <td>Vəziyyəti</td>
                                <td>{crash_val}, {colored_val}</td>
                            </tr>

                            {/* {dataCount.map((item) => (
                                <tr key={item?.id}>
                                    <td><Link to={`/products/?car_make_id=${item.car_make_id}`}>{item?.car_make?.name ?? "Daxil Edilməyib"}</Link></td>
                                    <td><Link to={`/products/?car_make_id=${item.car_make_id}&&car_model_id=${item.car_model_id}`}>{item?.car_model?.name ?? "Daxil Edilməyib"}</Link></td>
                                    <td><Link to={`/products/?car_make_id=${item.car_make_id}&&car_model_id=${item.car_model_id}&&car_generation_id=${item.car_generation_id}`}>{item?.car_generation?.name ?? "Daxil Edilməyib"}</Link></td>
                                </tr>
                            ))} */}

                        </tbody>
                    </table>
                    {/* <div className={classes.mobilTable}>
                        <h4>Aşağıdakı avtomobillərə uyğundur  </h4>
                        <ul>
                            {
                                dataCount.map((item) => (
                                    <li key={item?.id}>
                                        <Link to={`/products/?car_make_id=${item.car_make_id}`}>{item?.car_make?.name ?? "Daxil Edilməyib"}</Link>
                                        <Link to={`/products/?car_make_id=${item.car_make_id}&&car_model_id=${item.car_model_id}`}>{item?.car_model?.name ?? "Daxil Edilməyib"} /</Link>
                                        <Link to={`/products/?car_make_id=${item.car_make_id}&&car_model_id=${item.car_model_id}&&car_generation_id=${item.car_generation_id}`}>{item?.car_generation?.name ?? "Daxil Edilməyib"}</Link>
                                    </li>
                                ))
                            }
                        </ul>
                    </div> */}
                    {/* <p onClick={handleShowMore} className={classes.seeMore}>
                        {count < data?.products_car_info?.length ? <Link>Daha  çox </Link> : ""}
                    </p> */}

                </>
                // ) : ""
            }

        </>
    )
}

export default Detailtable