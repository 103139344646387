import React, { useState } from 'react'
import classes from "../ui/mobilfilter/mobil-filter.module.css"
import Select from 'react-select';
import { CustomStyles } from '../../helpers/contants/s3';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useWheelDiameters, useWheelHeight, useWheelWidths } from '../../hooks/query/wheelHeight';


const MobilWheelfilter = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [wheelHeightId, setwheelHeightId] = useState(searchParams.get("height_id") ?? 0);
  const [wheelDiameterId, setwheelDiameterId] = useState(searchParams.get("diameter_id") ?? 0);
  const [wheelWidthId, setwheelWidthId] = useState(searchParams.get("width_id") ?? 0);
  const categoryId = Number(searchParams.get("category_id"))
  const [showFilter, setShowFilter] = useState(categoryId ? categoryId : 45)

  const { data: wheelHeightData } = useWheelHeight()
  const { data: wheelDiameters } = useWheelDiameters()
  const { data: wheelWidths } = useWheelWidths()

  const wheelHeight = wheelHeightData?.data?.map((item) => ({
    value: item.id,
    label: item.value,
  }));

  const wheelDiameter = wheelDiameters?.data?.map((item) => ({
    value: item.id,
    label: item.value,
  }));

  const wheelWidth = wheelWidths?.data?.map((item) => ({
    value: item.id,
    label: item.value,
  }));

  const handleWheelHeight = (e) => {
    setwheelHeightId(e.value)
  }

  const handleWheelDiameter = (e) => {
    setwheelDiameterId(e.value)
  }

  const handleWheelWidth = (e) => {
    setwheelWidthId(e.value)
  }

  const handleSubmitWheelInfo = (e) => {
    e.preventDefault();
    const queryParams = Object.fromEntries(searchParams.entries());

    queryParams.height_id = wheelHeightId;
    queryParams.diameter_id = wheelDiameterId;
    queryParams.width_id = wheelWidthId;
    delete queryParams.product_number;

    const updatedSearchParams = new URLSearchParams(queryParams);
    setSearchParams(updatedSearchParams);

    if (location.pathname === "/") {
      navigate(`/products?${updatedSearchParams.toString()}`);
    } else {
      navigate(`${location.pathname}?${updatedSearchParams.toString()}`);
    }

  };



  const handleFilter = () => {
    // setShowFilter(true)
    // setShowSearch(false)
    setShowFilter(45)
    const queryParams = Object.fromEntries(searchParams.entries());
    queryParams.category_id = 45
    const updatedSearchParams = new URLSearchParams(queryParams);
    setSearchParams(updatedSearchParams);
  }

  const handleSearch = () => {
    // setShowFilter(false)
    // setShowSearch(true)
    setShowFilter(44)
    const queryParams = Object.fromEntries(searchParams.entries());
    queryParams.category_id = 44
    const updatedSearchParams = new URLSearchParams(queryParams);
    setSearchParams(updatedSearchParams);
  }

  return (
    <section className={`${classes.mobilFilter} `}>
      <div className='container'>
        <div className="filterFlex">
          <button onClick={handleFilter} style={{ cursor: "pointer" }} className={showFilter===45 ? "activeA" : ""}>Təkər</button>
          <button onClick={handleSearch} style={{ cursor: "pointer" }} className={showFilter===44 ? "activeA" : ""}>Disk</button>
        </div>
        {
          showFilter===45 &&
          <form className={classes.commonFilter} onClick={handleSubmitWheelInfo}>
            <Select
              className={classes.selectItem}
              placeholder={"En"}
              onChange={handleWheelWidth}
              value={wheelWidth?.find((option) => option.value === wheelWidthId)}
              styles={CustomStyles}
              options={wheelWidth}
            />
            <Select
              className={classes.selectItem}
              placeholder={"Hündürlük"}
              styles={CustomStyles}
              options={wheelHeight}
              onChange={handleWheelHeight}
              value={wheelHeight?.find((option) => option.value === wheelHeightId)}
            />
            <Select
              className={classes.selectItem}
              placeholder={"Radius"}
              onChange={handleWheelDiameter}
              value={wheelDiameter?.find((option) => option.value === wheelDiameterId)}
              options={wheelDiameter}
              styles={CustomStyles}
            />
            <button className={`${classes.btn__filter__search} btn btn__primary`}>Axtar</button>
          </form>
        }
        {
          showFilter===44 &&
          <form className={classes.commonFilter}>
            <Select
              className={classes.selectItem}
              placeholder={"Radius"}
              onChange={handleWheelDiameter}
              value={wheelDiameter?.find((option) => option.value === wheelDiameterId)}
              options={wheelDiameter}
              styles={CustomStyles}
            />
            <button className={`${classes.btn__filter__search} btn btn__primary`}>Axtar</button>
          </form>
        }

      </div>
    </section>
  )
}

export default MobilWheelfilter


