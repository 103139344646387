import React, { useState } from 'react'
import classes from "./detail.module.css"
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';

const Detailtable = ({ data }) => {
    const { t } = useTranslation();

    const [count, setCount] = useState(3);

    const dataCount = data?.products_car_info?.slice(0, count);

    const handleShowMore = () => {
        setCount(data?.products_car_info?.length);
    };


    return (
        <>
            {
                data?.products_car_info.length > 0 ? (
                    <>
                        <table className={classes.tableDetail}>
                            <thead>
                                <tr>
                                    <th>{t('marka')}</th>
                                    <th>{t('model')}</th>
                                    <th>{t("generation")}</th>
                                    {/* <th>{t("seria")}</th>
                                    <th>{t('motor')}</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {dataCount.map((item) => (
                                    <tr key={item?.id}>
                                        <td><Link to={`/products/?car_make_id=${item.car_make_id}`}>{item?.car_make?.name ?? "Daxil Edilməyib"}</Link></td>
                                        <td><Link to={`/products/?car_make_id=${item.car_make_id}&&car_model_id=${item.car_model_id}`}>{item?.car_model?.name ?? "Daxil Edilməyib"}</Link></td>
                                        <td><Link to={`/products/?car_make_id=${item.car_make_id}&&car_model_id=${item.car_model_id}&&car_generation_id=${item.car_generation_id}`}>{item?.car_generation?.name ?? "Daxil Edilməyib"}</Link></td>
                                        {/* <td>{item?.car_serie?.name ?? "Daxil Edilməyib"}</td>
                                        <td>{item?.car_trim?.name ?? "Daxil Edilməyib"}</td> */}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className={classes.mobilTable}>
                            <h4>Aşağıdakı avtomobillərə uyğundur  </h4>
                            <ul>
                                {
                                    dataCount.map((item) => (
                                        <li key={item?.id}>
                                            <Link to={`/products/?car_make_id=${item.car_make_id}`}>{item?.car_make?.name ?? "Daxil Edilməyib"}</Link>
                                            <Link to={`/products/?car_make_id=${item.car_make_id}&&car_model_id=${item.car_model_id}`}>{item?.car_model?.name ?? "Daxil Edilməyib"} /</Link>
                                            <Link to={`/products/?car_make_id=${item.car_make_id}&&car_model_id=${item.car_model_id}&&car_generation_id=${item.car_generation_id}`}>{item?.car_generation?.name ?? "Daxil Edilməyib"}</Link>        
                                            {/* <span>{item?.car_serie?.name ?? "Daxil Edilməyib"} / </span>
                                            <span>{item?.car_trim?.name ?? "Daxil Edilməyib"}</span> */}
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                        <p onClick={handleShowMore} className={classes.seeMore}>
                            {count < data?.products_car_info?.length ? <Link>Daha  çox </Link> : ""}                     
                        </p>

                    </>
                ) : ""
            }

        </>
    )
}

export default Detailtable