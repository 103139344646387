import React from 'react'
import { HiChevronLeft } from "react-icons/hi"
import { useContext } from 'react'
import { AuthContext } from '../../../context/AuthContext'
import CartTabPanel from '../../../components/cartTab'
import classes from "../carts.module.css"
import "./notification.css"
import Menu from '../../../components/ui/menu'
import { Link } from 'react-router-dom'


const NotificationSetting = () => {

    const { visibleMobil, setVisibleMobil } = useContext(AuthContext)

    const handleLeftClick = () => {
        setVisibleMobil(!visibleMobil)
    }

    return (
        <>
            <Menu />
            <section className={`${classes.carts__container} container notification__container notification__inner `}>
                <CartTabPanel activeTab='mynotification' />
                <div className={visibleMobil ? 'container__tabs' : " activeTabs"}>
                    <div className='notif_header'>
                        <div className='mobil__title__cart'>
                            <span className='left__mobil__icon' onClick={handleLeftClick}><HiChevronLeft fontSize="2rem" /></span>
                            <h3> Bildiriş ayarları </h3>
                            <span></span>
                        </div>
                        <h3 className='desktop__title__cart'> Bildiriş ayarları </h3>
                        {/* <Link to="" className='notif_setting'></Link> */}
                    </div>
                    <div className='notif_container'>

                        <div className='notif_set_item'>
                            <div className='notf_set_title'>Kampaniya bildirişləri</div>
                            <label class="switch_item">
                                <input type="checkbox" />
                                <span class="switch_circle"></span>
                            </label>
                        </div>
                        <div className='notif_set_item'>
                            <div className='notf_set_title'>Stok bildirişləri</div>
                            <label class="switch_item">
                                <input type="checkbox" checked />
                                <span class="switch_circle"></span>
                            </label>
                        </div>
                        <div className='notif_set_item'>
                            <div className='notf_set_title'>Kuryer bildirişləri</div>
                            <label class="switch_item">
                                <input type="checkbox" checked />
                                <span class="switch_circle"></span>
                            </label>
                        </div>
                        <div className='notif_set_item'>
                            <div className='notf_set_title'>E-mail bildirişləri</div>
                            <label class="switch_item">
                                <input type="checkbox" checked />
                                <span class="switch_circle"></span>
                            </label>
                        </div>
                        <div className='notif_set_item'>
                            <div className='notf_set_title'>Dəstək bildirişləri</div>
                            <label class="switch_item">
                                <input type="checkbox" />
                                <span class="switch_circle"></span>
                            </label>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default NotificationSetting