import classes from "./slide.module.css";
import Slider from "react-slick";
import Card from "../../../components/ui/card/card";
import { Link } from "react-router-dom";
import "../../../assets/styles/slider.css";
import { memo } from "react";
import SkeletonPost from "../../../components/skletons/SkeletonPost";


const ProductSlider = ({ link, data, title, slide_speed, slide_scrol = 1, isFetching }) => {
    const screenSize = window.innerWidth
    settings.speed = slide_speed;
    settings.slidesToScroll = slide_scrol;




    // const sliderSettings = {
    //     className: "center",
    //     infinite: true,
    //     centerPadding: "14px",
    //     slidesToShow: 6,
    //     swipeToSlide: true,
    //     autoplay: true,
    //     autoplaySpeed: slide_speed,
    //     centerMode: false,
    //     responsive: [
    //         {
    //             breakpoint: 1320,
    //             settings: {
    //                 slidesToShow: 5,
    //                 // slidesToScroll: 1,
    //                 infinite: true,
    //             },
    //         },
    //         {
    //             breakpoint: 1200,
    //             settings: {
    //                 slidesToShow: 4,
    //                 // slidesToScroll: 1,
    //             },
    //         },
    //         {
    //             breakpoint: 912,
    //             settings: {
    //                 slidesToShow: 3,
    //                 // slidesToScroll: 1,
    //             },
    //         },
    //         {
    //             breakpoint: 650,
    //             settings: {
    //                 slidesToShow: 2,
    //                 // slidesToScroll: 1,
    //             },
    //         },
    //         {
    //             breakpoint: 300,
    //             settings: {
    //                 slidesToShow: 2,
    //                 // slidesToScroll: 4,
    //             },
    //         },
    //     ],
    //     afterChange: function (index) {
    //         console.log(
    //             `${index + 1}`
    //         );
    //     }
    // };

    // const settings = {
    //     infinite: true,
    //     speed: slide_speed,
    //     slidesToShow: 6,
    //     slidesToScroll: slide_scrol,
    //     swipeToSlide: true,
    //     autoplay: true,
    //     autoplaySpeed: 3000,
    //     centerMode: false,

    //     afterChange: function (index) {
    //         console.log(
    //             `${index + 1}`
    //         );
    //     },
    //     responsive: [
    //         {
    //             breakpoint: 1320,
    //             settings: {
    //                 slidesToShow: 5,
    //                 // slidesToScroll: 1,
    //                 infinite: true,
    //             },
    //         },
    //         {
    //             breakpoint: 1200,
    //             settings: {
    //                 slidesToShow: 4,
    //                 // slidesToScroll: 1,
    //             },
    //         },
    //         {
    //             breakpoint: 912,
    //             settings: {
    //                 slidesToShow: 3,
    //                 // slidesToScroll: 1,
    //             },
    //         },
    //         {
    //             breakpoint: 650,
    //             settings: {
    //                 slidesToShow: 2,
    //                 // slidesToScroll: 1,
    //             },
    //         },
    //         {
    //             breakpoint: 300,
    //             settings: {
    //                 slidesToShow: 2,
    //                 // slidesToScroll: 4,
    //             },
    //         },
    //     ],
    // };



    return (
        <div className={classes.sliderOfficial}>
            <div className="slider__title">
                <h4>{title}</h4>
                <Link to={link}>Hamısına bax</Link>
            </div>
            <div className="sklet__slider">
                {isFetching && [...Array(screenSize > 600 ? 6 : 2).keys()].map(i => {
                    return <SkeletonPost key={i} />
                })}
            </div>
            <Slider {...settings}>
                {data?.data?.map((card) => (
                    <Card key={card.id} card={card} />
                ))}
            </Slider>
        </div>
    );
};

export default memo(ProductSlider);


var settings = {
    infinite: true,
    speed: 3000,
    slidesToShow: 6,
    slidesToScroll: 1,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 3000,
    centerMode: false,
    responsive: [
        {
            breakpoint: 1320,
            settings: {
                slidesToShow: 5,
                // slidesToScroll: 1,
                infinite: true,
            },
        },
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 912,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 650,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 300,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 4,
            },
        },
    ],
};

// var settings = {
//     // dots: false,
//     infinite: true,
//     speed: 1000,
//     slidesToShow: 6,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 3000,
//     // initialSlide: 0,
//     centerMode: false,
//     responsive: [
//         {
//             breakpoint: 1320,
//             settings: {
//                 slidesToShow: 5,
//                 slidesToScroll: 1,
//                 infinite: true,
//                 // dots: true
//             },
//         },
//         {
//             breakpoint: 1200,
//             settings: {
//                 slidesToShow: 4,
//                 slidesToScroll: 1,
//             },
//         },
//         {
//             breakpoint: 912,
//             settings: {
//                 slidesToShow: 3,
//                 slidesToScroll: 1,
//             },
//         },
//         {
//             breakpoint: 650,
//             settings: {
//                 slidesToShow: 2,
//                 slidesToScroll: 1,
//             },
//         },
//         {
//             breakpoint: 300,
//             settings: {
//                 slidesToShow: 2,
//                 slidesToScroll: 4,
//             },
//         },
//     ],
// };


