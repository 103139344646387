import React, { useContext, useState, useEffect } from "react";
import Modal from "../../modals/Modal";
import "./register.css";
import "../carts/privateinfo/privateinfo.css";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { register as registerService } from "../../services/authService";
import { AuthContext } from "../../context/AuthContext";
import Menu from "../../components/ui/menu/index";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import Login from "./login";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import InputMask from 'react-input-mask';

const Register = () => {
    const { setUser, setIsAuthenticated } = useContext(AuthContext);
    const navigate = useNavigate();
    const [userName, setUserName] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessages, setErrorMessages] = useState("");
    const [loading, setLoading] = useState(false);
    const [showScs, setShowScs] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
    const [login, setLogin] = useState(false);
    const [showLoginModal, setShowLoginModal] = useState(false);

    const screenSize = window.innerWidth;

    const {
        register,
        handleSubmit,
        reset,
        // formState: {},
    } = useForm({});

    const onSubmit = async (data) => {
        console.log(data)
        data.phone = data.phone.replaceAll(' ', '').replaceAll('-', '').replaceAll('(', '').replaceAll(')', '');
        console.log(data)
        setSuccessMessage("");
        setErrorMessages("");
        setLoading(true);

        try {
            const response = await registerService(data);
            showScs(true);
            setSuccessMessage("Qeydiyyat uğurla başa çatdı");
            localStorage.setItem("token", response?.data?.access_token);
            localStorage.setItem("userData", JSON.stringify(response?.data?.user));
            setUser(response?.data?.user);
            setIsAuthenticated(true);
            navigate("/");
            reset();



        } catch(error) {
            if(error?.response?.status === 422) {
                setErrorMessages(error?.response?.data?.errors ?? {});
            }
        } finally {
            // showScs(false);
            setLoading(false);
            setSuccessMessage("");
        }
    };


    const hansleSuccessGooglelogin = async (data) => {

        try {
            const response = await axios.post("https://api.avtopro.az/api/social-auth/callback/google", data);

            localStorage.setItem("token", response?.data?.data?.access_token);
            localStorage.setItem("userData", JSON.stringify(response?.data?.data?.user));
            setUser(response?.data?.data?.user);
            setUserName(response?.data?.data?.user);
            setIsAuthenticated(true);
            navigate("/");
        } catch(error) {
            // Handle error
        }
    };




    const userDataLast = localStorage.getItem('userDataLast');
    // console.log(userDataLast)
    // console.log("userDataLast");
    // if(userDataLast !== null || userDataLast !== undefined || userDataLast !== "") {
    //     console.log(userDataLast);
    //     console.log("userDataLast222"); setShowLoginModal(true);
    // }

    useEffect(() => {
        if(userDataLast == null || userDataLast == undefined || userDataLast == "") {
        } else {
            setShowLoginModal(true);
        }
    }, [userDataLast]);


    // GOOGLE_CLIENT_ID='18246125331-0nf2h76b0ivv0cj5oofnosvs9a9ra4gh.apps.googleusercontent.com'
    // GOOGLE_CLIENT_SECRET=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
    // GOOGLE_REDIRECT=https://avtopro.az/callback/google

    const clientId = "18246125331-0nf2h76b0ivv0cj5oofnosvs9a9ra4gh.apps.googleusercontent.com";
    const closeModal = () => {
        setShowScs(false);
    };
    const openModalLogin = () => {
        setShowLoginModal(true);
    };


    // useEffect(() => {
    //     if(login) {
    //         setShowLoginModal(false);
    //     }
    // }, [login]);

    return (
        <>
            {/* <Menu /> */}

            <div className="sign_up_page">
                <div className="register_bg"></div>
                <section className="container">
                    <div className="register__container ">
                        <div className="update_register_form">

                            <form onSubmit={handleSubmit(onSubmit)}>
                                <h3>Qeydiyyat</h3>
                                <div className="privateForm">
                                    <span>Adınız*</span>
                                    <input type="text" placeholder="Adınız" {...register("name")} />
                                    <p className="errorMessage">{errorMessages?.name}</p>
                                </div>
                                <div className="privateForm">
                                    <span>Soyad*</span>
                                    <input type="text" placeholder="Soyad" {...register("surname")} />
                                    <p className="errorMessage">{errorMessages?.surname}</p>
                                </div>
                                <div className="privateForm">
                                    <span>İstifadəçi adı*</span>
                                    <input type="text" placeholder="İstifadəçi adı" {...register("username")} />
                                    <p className="errorMessage">{errorMessages?.username}</p>
                                </div>
                                <div className="privateForm">
                                    <span>E-mail</span>
                                    <input type="text" placeholder="E-mail" {...register("email")} />
                                    <p className="errorMessage">{errorMessages?.email}</p>
                                </div>
                                <div className="privateForm">
                                    <span>Şifrə*</span>
                                    <input
                                        type={!showPassword ? "password" : "text"}
                                        placeholder="********"
                                        {...register("password")}
                                    />
                                    <strong className="eyeIcon ">
                                        {!showPassword ? (
                                            <AiFillEyeInvisible onClick={() => setShowPassword(!showPassword)} />
                                        ) : (
                                            <AiFillEye onClick={() => setShowPassword(!showPassword)} />
                                        )}
                                    </strong>
                                    {errorMessages !== "" && <p className="errorMessage">{errorMessages?.password}</p>}
                                </div>
                                <div className="privateForm">
                                    <span>Təkrar şifrə*</span>
                                    <input
                                        type={!showPasswordConfirm ? "password" : "text"}
                                        placeholder="********"
                                        {...register("password_confirmation")}
                                    />
                                    <strong className="eyeIcon ">
                                        {!showPasswordConfirm ? (
                                            <AiFillEyeInvisible
                                                onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
                                            />
                                        ) : (
                                            <AiFillEye onClick={() => setShowPasswordConfirm(!showPasswordConfirm)} />
                                        )}
                                    </strong>
                                    <p className="errorMessage">{errorMessages?.password_confirmation}</p>
                                </div>
                                <div className="privateForm">
                                    <span>Telefon*</span>
                                    <InputMask mask="(999) 999-99-99" placeholder="(050) 333-xx-xx" {...register('phone')} />
                                    <p className="errorMessage">{errorMessages?.phone}</p>
                                </div>

                                <button type="submit" className="btn btn__primary ">
                                    {loading ? (
                                        <>
                                            Göndərilir &nbsp; <i className="fas fa-circle-notch fa-spin"></i>
                                        </>
                                    ) : (
                                        "Qeydiyat ol"
                                    )}
                                </button>
                            </form>

                            <div className="google_login">
                                <GoogleOAuthProvider clientId={clientId} >
                                    <GoogleLogin
                                        onSuccess={hansleSuccessGooglelogin}
                                        onError={() => {
                                            console.log("Login Failed");
                                        }}
                                    />
                                </GoogleOAuthProvider>
                            </div>
                            <div className="sign_items">
                                <div className="sign_itm_button">
                                    {/* <Link onClick={() => setLogin(!login)} className="sign_in_btn">{!login ? "Qeydiyyat" : "Hesaba daxil ol"}</Link> */}
                                    <div className="sign_in_btn" onClick={openModalLogin} >Daxil ol</div>
                                </div>
                                <div className="sign_itm_button">
                                    <Link to="/forgot-password" style={{ "textDecoration": "none" }} className="forgot_btn">Şifrəni unutmusunuz?</Link>
                                </div>
                            </div>

                        </div>
                    </div>

                </section>
                <ToastContainer position="bottom-right" />



                <Modal isOpen={showScs} onClose={closeModal} width={500} height={screenSize > 600 ? 65 : 50}>

                    <div className="open__form__modal register_succes_content">
                        <div>
                            <div className="register_tick"></div>
                            <p className="succes_msg_val">{successMessage}</p>
                            <p className="regst_id_val">Sizin istifadəçi adınız: {userName}</p>
                            {/* <p className="regst_id_val">Sizin İD nömrəniz: {userName}</p> */}
                            <Link to="/" className="back_home_link">Ana səhifəyə qayıt</Link>
                        </div>
                    </div>

                </Modal>

                <Login showLoginModal={showLoginModal} setShowLoginModal={setShowLoginModal} />
            </div>
        </>
    );
};

export default Register;
