import React, { useEffect, useState } from 'react'
import Card from '../../../components/ui/card/card'
import "./wishlist.css"
import { HiChevronLeft } from "react-icons/hi"
import { useContext } from 'react'
import { AuthContext } from '../../../context/AuthContext'
import CartTabPanel from '../../../components/cartTab'
import classes from "../carts.module.css"
import Menu from '../../../components/ui/menu'
import Meta from "../../../components/ui/meta";


const WishList = () => {

  const { wishLists, visibleMobil, setVisibleMobil, getAllWishLists } = useContext(AuthContext)

  const handleLeftClick = () => {
    setVisibleMobil(!visibleMobil)
  }

  useEffect(() => {
    getAllWishLists()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const [shopType, setShopType] = useState("");

  const currentUrl = window.location.href;
  const title = 'Avtopro - Seçilmiş məhsullar'
  const keywords = "seçilmiş məhsullar, avtomobil, nəqliyyat, nəqliyyat vasitəsi, zapcast, vin, oem, avtopro, aksesuar, ağır texnika, motosiklet, təkərlər, yağlar"
  const description = "Nəqliyyat vasitələrinə aid hər növ ehtiyyat hissələri və aksesuarların satışı avtopro.az'da";

  return (
    <>
      <Meta title={title} keywords={keywords} description={description} currentUrl={currentUrl} />
      <Menu />
      <section className={`${classes.carts__container} container `}>
        <CartTabPanel activeTab='wishlist' />
        <div className={visibleMobil ? 'container__tabs' : " activeTabs"}>
          <div className='mobil__title__cart'>
            <span className='left__mobil__icon' onClick={handleLeftClick}><HiChevronLeft fontSize="2rem" /></span>
            <h3> Seçilmiş məhsullar</h3>
            <span></span>
          </div>
          <div className='wishlist__title'>
            <h3> Seçilmiş məhsullar</h3>
          </div>
          <div className="wishlist__tabs">
            <button
              className={shopType === "" ? 'active' : ''}
              onClick={() => setShopType("")}
            >
              Bütün seçilmişlər
            </button>
            <button
              className={shopType === 3 ? 'active' : ''}
              onClick={() => setShopType(3)}
            >
              Avtosalon
            </button>
            <p><strong>{wishLists.length}</strong> nəticə tapıldı</p>
          </div>
          <div className="wishlist__cards">
            {wishLists
              .filter((card) => shopType === "" || card.shop_type === shopType) // Eğer shopType boşsa, tüm kartlar gösterilecek
              .map((card) => (
                <Card key={card.id} card={card} shop_type={card.shop_type} />
              ))}
          </div>

        </div>
      </section>
    </>
  )
}

export default WishList