import React from 'react'
import Slider from "react-slick";
import default_image_url from '../../assets/images/default_product.jpeg';

const DetailSliderMobil = ({ data, size }) => {

    const image_url = `https://s3.eu-central-1.amazonaws.com/uploads.avtopro.general/`

    const img_default = default_image_url;
    const imgDetailAlt = data?.product_name;

    return (
        <section className={`detailSlider detailSliderMobil`}>
            <>
                {!(data?.images?.length > 0) && (
                    <span className='detailDefaultImg'>
                        <img src={img_default} alt='Default detail image' />
                    </span>
                )}
                <Slider {...settings} className="mainSlider ">
                    {
                        data?.images?.map((item) => (
                            <div className='oneImg' key={item.id}>
                                <img src={image_url + item?.directory + "/" + item?.file_name} alt={imgDetailAlt} />
                            </div>
                        ))
                    }
                </Slider>
            </>

        </section>
    )
}

export default DetailSliderMobil

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
};

