import React from 'react'
import "./productvideo.css"

const VideoPopup = ({ isOpen, onClose, children }) => {
  return (
    <div className={`modal ${isOpen ? 'is-open' : ''}`}>
        <div className="modal-overlay" onClick={onClose}></div>
        <div className="modal-content2">
        {children}
        <button className="modal-close-video" onClick={onClose}>X</button>
        </div>
  </div>
  )
}

export default VideoPopup