import React, { memo, useState } from 'react'
import classes from "./menu.module.css"
import { Link } from 'react-router-dom'
import { useContext } from "react";
import { MenuContext } from "../../../context/MenuContext";

// import p1 from "../../../assets/images/p1.png"
// import o1 from "../../../assets/images/o1.png"
// import m1 from "../../../assets/images/m1.png"
// import s1 from "../../../assets/images/s1.png"
// import t1 from "../../../assets/images/t1.png"
// import u1 from "../../../assets/images/u1.png"
// import d1 from "../../../assets/images/d1.png"

import shop from "../../../assets/icons/shops.svg"
import dealers from "../../../assets/icons/dealers.svg"
import cardealers from "../../../assets/icons/cardealers.svg"
import licenseplate from "../../../assets/icons/licenseplate.svg"
import repairers from "../../../assets/icons/repairers.svg"
import specialorder from "../../../assets/icons/specialorder.svg"
import services from "../../../assets/icons/services.svg"


import { AiOutlineClose } from 'react-icons/ai'
import { useTranslation } from "react-i18next";
import { Youtube } from '../../../assets/icons/Icons';
import { Lang } from "../../../assets/icons/Icons"
import { CgProfile } from "react-icons/cg"
import { ModalOrder } from '../../../pages/home/service/modalorder';
import { ModalNav } from '../../../layouts/navbar/modalNavbar/modalNav';
import { AuthContext } from '../../../context/AuthContext';

const Menu = () => {
    const { setUser, setIsAuthenticated } = useContext(AuthContext)

    const { t } = useTranslation();
    const { active, setActive } = useContext(MenuContext);
    const [isModalOpenOrder, setIsModalOpenOrder] = useState(false);
    const [isModalOpenNav, setIsModalOpenNav] = useState(false);
    const [dropitem, setDropitem] = useState(false);

    const openModal = () => {
        setIsModalOpenNav(true);
        setActive(!active)
    };
    const openModalOrder = () => {
        setIsModalOpenOrder(true);
        setActive(!active)
    };

    const handleDropclick = () => {
        setDropitem(!dropitem);
    };

    const handleLogout = () => {
        setIsAuthenticated(false)
        setUser(null)
        localStorage.removeItem('token');
        localStorage.removeItem('userData');
        // setVisibleRegister(false);
    }


    return (
        <>
            <div className={classes.menu__container}>
                <ul className={active ? `container ${classes.nav__active} ${classes.menu}` : `container ${classes.menu}`}>
                    <button id={classes.closeMenu} onClick={() => setActive(!active)}><AiOutlineClose /></button>

                    {/* Mobile menu start */}
                    {/* <li className='mobil__menu__hidden'>
                        <Link to="/tezlikle" className='hoverColor ' onClick={() => setActive(!active)}><img src={p1} alt="Fərdi elanlar" /> Fərdi elanlar</Link>
                    </li> */}

                    <li className='demo__menu__hidden'>
                        <Link to="/register" className='hoverColor ' onClick={() => setActive(!active)}>
                            <span className={`${classes.menu_icns} ${classes.profile}`}></span>Profil</Link>
                    </li>
                    <li className='demo__menu__hidden'>
                        <Link to="/cart/wishlist" className='hoverColor ' onClick={() => setActive(!active)}>
                            <span className={`${classes.menu_icns} ${classes.favorites}`}></span>Seçilmişlər</Link>
                    </li>
                    <li className='demo__menu__hidden mobil_line_top'>
                        <Link to="/cart/mybalance" className='hoverColor ' onClick={() => setActive(!active)}>
                            <span className={`${classes.menu_icns} ${classes.balance}`}></span>Balans</Link>
                    </li>
                    <li className='demo__menu__hidden'>
                        <Link to="/products" className='hoverColor ' onClick={() => setActive(!active)}>
                            <span className={`${classes.menu_icns} ${classes.mcategory}`}></span>Kateqoriyalar</Link>
                    </li>
                    <li className='demo__menu__hidden'>
                        <Link to="/shops" className='hoverColor ' onClick={() => setActive(!active)}>
                            <span className={`${classes.menu_icns} ${classes.mshop}`}></span>{t("shops")}</Link>
                    </li>
                    <li className='demo__menu__hidden'>
                        <Link to="/dealers" className='hoverColor ' onClick={() => setActive(!active)}>
                            <span className={`${classes.menu_icns} ${classes.mdealers}`}></span>Rəsmi dilerlər</Link>
                    </li>
                    <li className='demo__menu__hidden'>
                        <Link to="/licenseplate" className='hoverColor' onClick={() => setActive(!active)}>
                            <span className={`${classes.menu_icns} ${classes.mcardealers}`}></span>Qeydiyyat nişanı</Link>
                    </li>
                    <li className={`${classes.dropclick} demo__menu__hidden ${dropitem ? classes.droped_menu : ''}`} onClick={handleDropclick}>
                        <Link className='hoverColor' >
                            <span className={`${classes.menu_icns} ${classes.mservices}`}></span>Xidmətlər</Link>
                    </li>
                    <div className={`${classes.dropmenu} ${dropitem ? classes.droped_menu : ''}`}>
                        <li className='demo__menu__hidden'>
                            <Link to="/ustalar" className='hoverColor' onClick={() => setActive(!active)}>
                                <span className={`${classes.menu_icns} ${classes.mlicenseplate}`}></span>Ustalar</Link>
                        </li>
                        <li className='demo__menu__hidden'>
                            <Link to="/services" className='hoverColor' onClick={() => setActive(!active)}>
                                <span className={`${classes.menu_icns} `}></span>Servislər</Link>
                        </li>
                        <li className='demo__menu__hidden'>
                            <Link to="" className='hoverColor' onClick={openModalOrder}>
                                <span className={`${classes.menu_icns} `}></span>Xüsusi sifariş et</Link>
                        </li>
                        <li className='mobil__menu__hidden demo__menu__hidden'>
                            <Link to="/" className='hoverColor ' onClick={() => setActive(!active)}>
                                <span className={`${classes.menu_icns} ${classes.mspecialorder}`}></span>Xüsusi sifariş</Link>
                        </li>
                        <li className='mobil__menu__hidden demo__menu__hidden'>
                            <Link to="/" className='hoverColor ' onClick={() => setActive(!active)}>
                                <span className={`${classes.menu_icns} ${classes.mspecialorder}`}></span>Sığorta kalkulyator</Link>
                        </li>
                        <li className='demo__menu__hidden'>
                            <Link to="" onClick={openModal}>
                                <span className={`${classes.menu_icns} ${classes.mspecialorder}`}></span>Online mağaza yarat</Link>
                        </li>
                    </div>

                    <li className='demo__menu__hidden'>
                        <Link to="/cardealers" className='hoverColor' onClick={() => setActive(!active)}>
                            <span className={`${classes.menu_icns} ${classes.mrepairers}`}></span>Avtosalonlar</Link>
                    </li>
                    <li className='demo__menu__hidden'>
                        <Link to="/tezlikle" className='hoverColor' onClick={() => setActive(!active)}>
                            <span className={`${classes.menu_icns} ${classes.mtruck}`}></span>Ağır texnika</Link>
                    </li>
                    <li className='demo__menu__hidden mobil_line_top'>
                        <Link to="/video" onClick={() => setActive(!active)}>
                            <span className={`${classes.menu_icns} ${classes.myoutube}`}></span>Youtube HUB </Link>
                    </li>
                    <li className='demo__menu__hidden'>
                        <Link><span className={`${classes.menu_icns} ${classes.mlanguage}`}></span>Dil <span className={classes.lang_name}>AZE</span></Link>
                    </li>
                    <li className='demo__menu__hidden'>
                        <Link to="/gomap" onClick={() => setActive(!active)}>
                            <span className={`${classes.menu_icns} ${classes.mgomap}`}></span>Go map</Link>
                    </li>
                    <li className='demo__menu__hidden mobil_line_top' onClick={() => setActive(!active)}>
                        <Link to="/register" onClick={handleLogout}>
                            <span className={`${classes.menu_icns} ${classes.mexit}`}></span>Çıxış</Link>
                    </li>
                    <li className='demo__menu__hidden'>
                        <Link to="tel:+994557380013" >
                            <span className={`${classes.menu_icns} ${classes.mcontact}`}></span>Bizimlə əlaqə</Link>
                    </li>
                    {/* Mobile menu end */}


                    {/* Desktop menu start */}
                    <li className='mobil__menu__hidden'>
                        <Link to="/shops" className='hoverColor ' onClick={() => setActive(!active)}>
                            <span className={`${classes.menu_icns} ${classes.shop}`}></span> {t("shops")}</Link>
                    </li>
                    <li className='mobil__menu__hidden'>
                        <Link to="/dealers" className='hoverColor ' onClick={() => setActive(!active)}>
                            <span className={`${classes.menu_icns} ${classes.dealers}`}></span>Rəsmi dilerlər</Link>
                    </li>
                    <li className='mobil__menu__hidden'>
                        <Link to="/cardealers" className='hoverColor' onClick={() => setActive(!active)}>
                            <span className={`${classes.menu_icns} ${classes.cardealers}`}></span>Avtosalonlar</Link>
                    </li>
                    <li className='mobil__menu__hidden'>
                        <Link to="/licenseplate" className='hoverColor' onClick={() => setActive(!active)}>
                            <span className={`${classes.menu_icns} ${classes.licenseplate}`}></span>Qeydiyyat nişanı</Link>
                    </li>
                    <li className='mobil__menu__hidden'>
                        <Link to="/ustalar" className='hoverColor' onClick={() => setActive(!active)}>
                            <span className={`${classes.menu_icns} ${classes.repairers}`}></span>Ustalar</Link>
                    </li>
                    <li className='mobil__menu__hidden'>
                        <Link to="" className='hoverColor' onClick={openModalOrder}>
                            <span className={`${classes.menu_icns} ${classes.specialorder}`}></span>Xüsusi sifariş et</Link>
                    </li>
                    <li className='mobil__menu__hidden'>
                        <Link to="/services" className='hoverColor' onClick={() => setActive(!active)}>
                            <span className={`${classes.menu_icns} ${classes.services}`}></span>Servislər</Link>
                    </li>

                    {/* Desktop menu end */}

                </ul>
            </div>
            {isModalOpenOrder && <ModalOrder isModalOpenOrder={isModalOpenOrder} setIsModalOpenOrder={setIsModalOpenOrder} />}
            {isModalOpenNav && <ModalNav isModalOpenNav={isModalOpenNav} setIsModalOpenNav={setIsModalOpenNav} />}

        </>
    )
}

export default memo(Menu);


