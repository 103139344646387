import React, { useEffect, useState } from 'react'
import { useChildCategories } from '../../hooks/query/categories';
import { useSearchParams, useLocation, useNavigate } from 'react-router-dom';
import Boxes from '../ui/boxes/boxes';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';


const ParentCategory = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [showCategory, setShowCategory] = useState(true);

    const [catgName, setCatgName] = useState('')

    const params = {
        category_id: searchParams.get("category_id") ?? 0
    };

    if(searchParams.get('shop_id')) params["shop_id"] = searchParams.get('shop_id');
    if(searchParams.get('shop_type')) params["shop_type"] = searchParams.get('shop_type');
    if(searchParams.get('car_make_id')) params['car_make_id'] = searchParams.get('car_make_id')
    if(searchParams.get("car_model_id")) params['car_model_id'] = searchParams.get("car_model_id")
    if(searchParams.get("car_generation_id")) params['car_generation_id'] = searchParams.get("car_generation_id")


    const {
        data: categoriesData,
        refetch: categoriesRefetch,
        isStale: categoriesIsStale,
    } = useChildCategories(searchParams.get("category_id") ?? 0, params);

    useEffect(() => {
        if(categoriesIsStale) {
            categoriesRefetch();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate, searchParams]);


    const img_url = "https://s3.eu-central-1.amazonaws.com/uploads.avtopro.general/storage/";


    // const handleBoxCategoryName = (name) => {
    //     const currentCatgName = localStorage.getItem('catgName');
    //     localStorage.setItem('catgName', name);
    //     setCatgName(name);
    // };


    return (
        <section className={`container brand__container`}>
            <h3 onClick={() => setShowCategory(!showCategory)}>
                Kateqoriyalar {showCategory ? <FiChevronUp /> : <FiChevronDown />}{" "}
            </h3>
            {showCategory && (
                <div className={"shop__category"}>
                    {categoriesData?.data?.map((item) => {
                        const queryParams = Object.fromEntries(searchParams.entries());
                        queryParams.category_id = item.id;

                        if(searchParams.get('shop_id')) {
                            queryParams.shop_id = searchParams.get('shop_id');;
                        }

                        const updatedSearchParams = new URLSearchParams(queryParams);

                        return (<Boxes
                            key={item.id}
                            name={item?.category_name}
                            image={img_url + item?.icon}
                            shopbox={"shopbox"}
                            onClickLink={`${location.pathname}?${updatedSearchParams.toString()}`}
                            productCount={item?.products_count}
                            // onClick={() => handleBoxCategoryName(item?.category_name)}
                        />
                        )
                    })}
                </div>
            )}
        </section>
    )
}

export default ParentCategory