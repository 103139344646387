export const S3_LINK = 'https://s3.eu-central-1.amazonaws.com/uploads.avtopro.general/';

export const CustomStyles = {
  control: (provided, state) => ({
    ...provided,
    className: 'custom-single-value',
    borderColor: state.isFocused ? '#68A834' : '#CCCCCC',
    boxShadow: state.isFocused ? '0 0 0 1px #68A834' : null,
    '&:hover': {
      borderColor: state.isFocused ? '#68A834' : '#CCCCCC',
    },

  }),
};