import React, { memo, useState } from 'react'
import { GrYoutube } from 'react-icons/gr'
import { Link } from 'react-router-dom'
import classes from "../../../assets/styles/shopInfo.module.css"
import { IoLogoFacebook } from "react-icons/io"
import { FaInstagramSquare, FaWhatsappSquare } from "react-icons/fa";
import { BsTelephoneFill } from "react-icons/bs"
import { TiLocation } from 'react-icons/ti'
import { TbClockHour4, TbWorld } from 'react-icons/tb'
import {
  ShopSocialFb, ShopSocialInsta, ShopSocialLinkedn, ShopSocialWp, ShopTel, ShopLoc, ShopWeb, ShopWork
} from '../../../assets/icons/Icons';

const Shopinfo = ({ data, website, workTime }) => {

  let websiteLink
  if(website != null) {
    websiteLink = `https://${website}`
  } else {
    websiteLink = ''
  }
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <section className={`container ${classes.shop__container}`}>
      {/* <h3>{data?.data?.name} </h3> */}
      <div className={`${classes.shop__info} ${isExpanded ? classes.show_adrs : ''}`}>
        <div className={classes.item} >
          <div className={classes.item__left}>
            <Link className={classes.subscribe} to="/video"> <GrYoutube color="#FE0000" fontSize="2.2rem" /> Abunə ol</Link>
            <div className={classes.icons}>
              <Link to={`${data?.data?.shop_information[0]?.facebook ?? "https://www.facebook.com"}`} target='_blank'> {ShopSocialFb}</Link>
              <Link to={`${data?.data?.shop_information[0]?.instagram ?? "https://www.instagram.com/"}`} target='_blank'> {ShopSocialInsta}</Link>
              <Link to={`${data?.data?.shop_information[0]?.linkedn ?? "https://www.linkedin.com/"}`} target='_blank'> {ShopSocialLinkedn}</Link>
              <Link to={`https://api.whatsapp.com/send?phone=${data?.data?.shop_information[0]?.phone1?.replace(/\D/g, '')}`} target='_blank'> {ShopSocialWp}</Link>
            </div>
          </div>
          <div className={`${classes.item__right}`}>
            <p>
              <Link to={`tel:+994${data?.data?.shop_information[0]?.phone1.replace(/^0/, '')}`}>
                <BsTelephoneFill /> {data?.data?.shop_information[0]?.phone1} </Link>
            </p>
            {data?.data?.shop_information[0]?.phone2 &&
              <p>
                <Link to={`tel:+994${data?.data?.shop_information[0]?.phone2.replace(/^0/, '')}`}>
                  <BsTelephoneFill />{data?.data?.shop_information[0]?.phone2.replace(/^0/, '')}
                </Link>
              </p>}
            {/* <p><Link > {ShopLoc}{data?.data?.shop_information[0]?.address}</Link></p> */}
            <div className={classes.hide_items}>
              {data?.data?.shop_information?.map((shop, index) => (
                <p key={index}>
                  <Link>{ShopLoc}{shop?.address}</Link>
                </p>
              ))}
            </div>
          </div>
        </div>
        <div className={`${classes.lineBorder}`}></div>
        <div className={`${classes.item} ${classes.item2}`}>
          <div className={classes.item__left}>
            <p><Link to={websiteLink}  >{ShopWeb} {website ? website : "-----"}</Link></p>
            {/* <p><Link>{ShopWork}  {workTime ? workTime : "-----"}</Link></p> */}

            <div className={classes.hide_items}>
              {data?.data?.shop_information?.map((shop, index) => (
                <p key={index}>
                  <Link>{ShopWork}  {shop?.work_time}</Link>
                </p>
              ))}
            </div>

          </div>
          {/* <div className={classes.item__right}>
            <Link className={classes.service}>Servis xidməti <BsArrowRight /></Link>
          </div> */}
        </div>
      </div>

      <div className={classes.more_text}>
        <span onClick={handleToggle} >Digər ünvanları gör</span>
      </div>

    </section>
  )
}

export default memo(Shopinfo)
