import { useQuery } from "@tanstack/react-query";
import { getWheelDiameters, getWheelHeights, getWheelWidths } from "../../services/wheelService";


export const useWheelHeight = (params) => {
    const { data, isError, error } = useQuery({
        queryKey: ["wheel_height", params],
        queryFn: () => getWheelHeights(params),
        staleTime: 10 * (60 * 1000),
        cacheTime: 10 * (60 * 1000),
    });

    return { data, isError, error };
}


export const useWheelDiameters = (params) => {
    const { data, isError, error } = useQuery({
        queryKey: ["wheel_diameters", params],
        queryFn: () => getWheelDiameters(params),
        staleTime: 10 * (60 * 1000),
        cacheTime: 10 * (60 * 1000),
    });

    return { data, isError, error };
}


export const useWheelWidths = (params) => {
    const { data, isError, error } = useQuery({
        queryKey: ["wheel_widths", params],
        queryFn: () => getWheelWidths(params),
        staleTime: 10 * (60 * 1000),
        cacheTime: 10 * (60 * 1000),
    });

    return { data, isError, error };
}