import * as yup from "yup";

export const schema = yup.object().shape({
    name:yup.string().required('Götürəcək şəxsin adını qeyd edin'),
    phone:yup.string()
    .required('Əlaqə nömrəsini qeyd edin')
    .matches(/^[0-9]+$/, "Yalnız rəqəm olmalı")
    .min(10, '10 simvol olmalı')
    .max(10, ' 10 simvol olmalı'),
    email:yup.string().required('Email qeyd edin'),
    address:yup.string().required('Ünvanı qeyd edin '),
    weight:yup.string().required('Çəkini qeyd edin'),
    delivery_instructions:yup.string(),
});
