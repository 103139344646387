import React, { useState, useRef } from 'react';
import Slider from 'react-slick';
import "../../assets/styles/slider.css";
import default_image_url from '../../assets/images/default_product.jpeg';

export default function DetailSlider({ data }) {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const image_url = `https://s3.eu-central-1.amazonaws.com/uploads.avtopro.general/`;
  const containerRef = useRef(null);
  const img_default = default_image_url;
  const imgDetailAlt = data?.product_name;

  const handleMouseMove = (e) => {
    const { left, top } = containerRef.current.getBoundingClientRect();
    const width = 260;
    const height = 200;
    const x = (e.clientX - left) / width;
    const y = (e.clientY - top) / height;

    const zoomImgs = containerRef.current.querySelectorAll('.zoom-img');
    zoomImgs.forEach((zoomImg) => {
      zoomImg.style.transformOrigin = `${x * 100 - 20}% ${y * 100 - 10}%`;
      zoomImg.style.transform = 'scale(2)';
    });
  };

  return (
    <div className="detailSlider" ref={containerRef} onMouseMove={handleMouseMove}>
      <>
        <span className='detailDefaultImg'>
          <img src={img_default} alt='Default detail image' />
        </span>
        <Slider asNavFor={nav2} ref={(slider1) => setNav1(slider1)} className="mainSlider ">

          {data?.images?.map((item) => (
            <div className='oneImg' key={item.id}>
              <div className="zoom-img-container">
                <img
                  src={image_url + item?.directory + "/" + item?.file_name}
                  alt={imgDetailAlt}
                  className="normal-img"
                />
                <img
                  src={image_url + item?.directory + "/" + item?.file_name}
                  alt={imgDetailAlt}
                  className="zoom-img"
                />
              </div>
            </div>
          ))}


        </Slider>
      </>

      <Slider
        asNavFor={nav1}
        ref={(slider2) => setNav2(slider2)}
        slidesToShow={data?.images?.length}
        swipeToSlide={true}
        focusOnSelect={true}
        className="smallImages"
      >
        {data?.images?.map((item) => (
          <div className='smallImg' key={item.id}>
            <img src={image_url + item?.directory + "/" + item?.file_name} alt={imgDetailAlt} />
          </div>
        ))}
      </Slider>
    </div>
  );
}
