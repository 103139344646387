import { useQuery } from "@tanstack/react-query";
import { getCarType, getCarSalonType } from "../../services/carTypeService";

export const useAllCarTypes = (params) => {
    // const params = { order: "name", direction: "asc", id_car_default: 1 }
    // console.log(params);
    const { data, isError, error, refetch } = useQuery({
        queryKey: ["car_types"],
        queryFn: () => getCarType(params),
        staleTime: 10 * (60 * 1000),
        cacheTime: 10 * (60 * 1000)
    });

    return { data, isError, error, refetch };
};
export const useAllCarSalonTypes = (params) => {
    // const params = { order: "name", direction: "asc", id_car_default: 1 }
    // console.log(params);
    const { data, isError, error, refetch } = useQuery({
        queryKey: ["car_types"],
        queryFn: () => getCarType(params),
        staleTime: 10 * (60 * 1000),
        cacheTime: 10 * (60 * 1000)
    });

    return { data, isError, error, refetch };
};

export const usePopularCarType = () => {
    const params = { popular: 1, direction: "asc" }
    const { data, isError, error } = useQuery({
        queryKey: ["popular_car_types"],
        queryFn: () => getCarType(params),
        staleTime: 10 * (60 * 1000),
        cacheTime: 10 * (60 * 1000),
    });

    return { data, isError, error };
};

export const useCartype = (params) => {
    const { data, isError, error } = useQuery({
        queryKey: ["car_type", params],
        queryFn: () => getCarType(params),
        staleTime: 10 * (60 * 1000),
        cacheTime: 10 * (60 * 1000),
    });

    return { data, isError, error };
}