import request from "../helpers/api/request";

export const categoriesService = async (query_params = {}) => {
    const params = new URLSearchParams(query_params);
    const queryString = "?" + params.toString();
    const response = await request.get(`/categories${queryString}`);

    return response.data;
};



export const getCategory = async (slug) => {
    const response = await request.get(`/category/${slug}`);
    return response.data;
} 