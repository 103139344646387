import React from "react";
import { useOfficialProducts, useShopProducts, useShopProductsLates } from "../../../hooks/query/products";
import { memo } from "react";
import ProductSlider from "./ProductSlider"
import { Link } from "react-router-dom";


const SliderCard = () => {

    // const { data, shopSliderDataLates } = getLatestProducts("2024-06-04");
    const { data: shopSliderDataLates, isFetching: shopFecthingLates } = useShopProductsLates();
    const { data: shopSliderData, isFetching: shopFecthing } = useShopProducts();
    const { data: officialSliderData, isFetching: officialFetching } = useOfficialProducts();

    return (
        <section className={`container`}>
            <ProductSlider link={`/products?shop_type=1`} data={shopSliderDataLates} isFetching={shopFecthingLates} slide_speed={2100} title="SON ELANLAR" />
            <div className="more_sect">
                <Link to={`/products?shop_type=1`} className="moreItems">DAHA ÇOX</Link>
            </div>
            <ProductSlider link={`/products?shop_type=1`} data={officialSliderData} isFetching={officialFetching} slide_speed={1800} title="RƏSMİ DİLERLƏR" />
            <ProductSlider link={`/products?shop_type=2`} data={shopSliderData} isFetching={shopFecthing} slide_speed={2300} title="MAĞAZALAR" />
        </section>
    );
};

export default memo(SliderCard);
