import React from "react";
import styles from "../../pages/carnumber/carnumber.module.css";
import { Link } from "react-router-dom";
import { svgFlagIcon } from "../../assets/icons/Icons";
import updated_img_url from "../../assets/images/icontitle.png";

const CarCard = ({ card, handleRelatedProductClick }) => {

  const letterNumber = `${card?.first_letter}${card?.second_letter}`
  const dateTimeString = card?.created_at;

  const date = new Date(dateTimeString);

  const formattedDate = date.toLocaleString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  });
  const year = formattedDate.split('/')[2];
  const month = formattedDate.split('/')[0];
  const day = formattedDate.split('/')[1];
  // const hour = formattedDate.split(',')[1];

  let currency = '';
  if(card?.currency === 1) {
    currency = '₼'
  } else if(card?.currency === 2) {
    currency = '$'
  } else if(card?.currency === 3) {
    currency = 'EURO'
  }

  const productSchema = {
    "@context": "https://schema.org",
    "@type": "Product",
    "description": `${card?.region?.region_number}-${letterNumber}-${card?.number}  - məhsulun satışı avtopro.az'da`,
    "name": `${card?.region?.region_number}-${letterNumber}-${card?.number}`,
    "image": `${updated_img_url}`,
    "offers": {
      "@type": "Offer",
      "availability": "1",
      "price": `${card?.price}`,
      "priceCurrency": "AZN",
    }
  };

  return (
    <>
      <article className={`${styles.card__items}`}>
        <Link to={`/licenseplate/${card?.id}`} onClick={handleRelatedProductClick} className={styles.card__head} >
          <p >
            {svgFlagIcon}
            <span>
              AZ
            </span>
          </p>
          <h3>{card?.region?.region_number}-{letterNumber}-{card?.number}</h3>
        </Link>

        <div className={`${styles.card__body}`}>
          <p>{card?.author_name}</p>
          <p>
            {day}/
            {month}/
            {year}
          </p>
          <h4>{card?.price} {currency}</h4>
        </div>

        <script type="application/ld+json">
          {JSON.stringify(productSchema)}
        </script>
      </article>

    </>

  );
};

export default CarCard;
