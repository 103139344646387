import request from "../helpers/api/request";
import browserHistory from "../history/browserHistory";

export const productService = (id) => {
    return request.get("/products").then((res) => res.data);
}


export const getSalons = async (query_params = {}) => {
    const params = new URLSearchParams(query_params);
    const queryString = "?" + params.toString();
    const response = await request.get(`/car/cars${queryString}`);

    // console.log(query_params);
    // console.log(response);
    // console.log("response product");
    return response.data;
}

export const getSalon = async (id) => {
    try {
        const response = await request.get(`/car/cars?id=${id}`);
        // console.log(response);
        // console.log("responseresponseresponse");
        return response.data;
    } catch(error) {
        browserHistory.push('/not-found')
    }
};



export const getProducts = async (query_params = {}) => {
    const params = new URLSearchParams(query_params);
    const queryString = "?" + params.toString();
    const response = await request.get(`/products${queryString}`);

    // console.log(response);
    // console.log("getProductsresponse");
    return response.data;
}

export const getProductsLates = async (query_params = {}) => {
    const params = new URLSearchParams(query_params);
    const queryString = "?" + params.toString();
    const response = await request.get(`/products${queryString}`);

    // console.log(response);
    return response.data;
};



export const getProduct = async (id) => {
    try {
        const response = await request.get(`/products/${id}`);
        return response.data;
    } catch(error) {
        browserHistory.push('/not-found')
    }
};

export const getProductsCount = async () => {
    const response = await request.get(`/products_count`);
    return response.data;
};


export const autoComplete = async (query) => {
    const response = await request.get(`/auto_complete?search=${query}`)
    return response.data;
}

export const calculateCourierPrice = async (data) => {
    const response = await request.post(`/account/calculate_delivery_price`, data)

    return response.data;
}