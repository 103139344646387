import React, { useRef, useEffect, useState } from 'react'
import Breadcrumb from '../../../components/ui/breadcrumb/breadcrumb'
import "./repairer-register.css"
import Select from 'react-select';
import Menu from '../../../components/ui/menu/index';
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from './yup';
import axios from "axios";
import { baseURL } from "../../../helpers/api/request";
import { repairers as repairService } from "../../../services/repairerService";
import { useCities } from '../../../hooks/query/cities';
import { CustomStyles } from '../../../helpers/contants/s3';
import { usePopularCarMakes } from '../../../hooks/query/carMake';
import { usePositionCategory } from '../../../hooks/query/repairers';


const RegisterRepair = () => {

    const [visibleSelect, setVisibleSelect] = useState(false)
    const [categorySelected, setCategorySelected] = useState(false)
    const [carMakesSelected, setCarMakesSelected] = useState(false)
    const [visibleIndex, setVisibleIndex] = useState(null);
    const [clickCount, setClickCount] = useState(0);
    const [selectedOptions, setSelectedOptions] = useState();
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessages, setErrorMessages] = useState('');
    const [loading, setLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const { data: cities } = useCities()
    const { data: popularCarMakes } = usePopularCarMakes()
    const { data: positionCategory } = usePositionCategory()
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            minHeight: '45px',
            maxHeight: state.isFocused ? 'inherit' : '45px',
            overflow: 'auto',
            marginTop: '0.6rem',


        })
    };



    const fileInputRef = useRef(null);

    const handleFileSelect = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);

        console.log(file);

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setSelectedFile(reader.result);
        };
    };

    const handleSelect = (data) => {
        setSelectedOptions(data)
    }


    const citySelect = cities?.data?.map((city) => {
        return {
            value: city.id,
            label: city.name
        }
    })

    useEffect(() => {
        if(popularCarMakes?.data) {
            setLoading(false);
        }
    }, [popularCarMakes]);

    const carMakes = Array.isArray(popularCarMakes?.data) ? popularCarMakes.data.map((car) => {
        return {
            value: car.id,
            label: car.name
        };
    }) : [];

    // const carMakes = popularCarMakes?.data?.map((car) => {
    //     return {
    //         value: car.id,
    //         label: car.name
    //     }
    // })

    const category = positionCategory?.data?.map((category) => {
        return {
            value: category.id,
            label: category.name
        }
    })

    const category_count = (category && category.length) ? category.length - 1 : 0;

    const handleShowNextRow = () => {
        setClickCount((prevCount) => {
            // Eğer prevCount 11 ise artırmadan aynı değeri döndür
            if(prevCount == category_count) {
                return prevCount;
            }

            console.log(prevCount + "--prevCount");
            // Eğer prevCount 11 değilse, bir ekleyerek döndür
            return prevCount + 1;

        });
        console.log(clickCount + "--clickCount");
    };
    const handleHideItemClick = (index) => {
        setClickCount((prevCount) => {
            // Eğer prevCount 11 ise artırmadan aynı değeri döndür
            // if(prevCount === 0) {
            //     return prevCount;
            // }

            // console.log(prevCount + "--p inner");

            // Eğer prevCount 11 değilse, bir ekleyerek döndür
            return prevCount - 1;
            // console.log(prevCount + "--inner 222");
        });
        // Tıklanan elemanın bulunduğu parent div'e erişim sağla
        // const parentDiv = document.querySelector(`.repair_append .row_rep:nth-child(${index})`);

        // // Eğer parent div bulunursa, class'ını değiştir (ekle/çıkar)
        // if(parentDiv) {
        //     parentDiv.classList.remove('visible_rep');
        //     parentDiv.classList.add('hidden_rep');

        //     const visibleRepElements = document.querySelectorAll('.repair_append .row_rep.visible_rep');

        //     console.log(visibleRepElements.length);
        //     setClickCount(visibleRepElements.length);
        // }
    };
    // const {
    //     register,
    //     handleSubmit,
    //     control,
    //     // formState: { errors },
    // } = useForm({});
    const {
        register,
        handleSubmit,
        control,
        watch,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (data) => {
        if(categorySelected == true && carMakesSelected == false) {
            console.log("form stop");
        } else {
            let responseError = null;

            try {
                const response = await axios.post(`${baseURL}/repairers`, data, {
                    headers: { "Content-Type": "multipart/form-data" },
                });

                // Yanıt başarılıysa
                console.log("form yess");
                console.log(data, "dt");
                setSuccessMessage("Qeydiyyat uğurla başa çatdı.");

                // Yanıtın içindeki verileri kullanabilirsiniz
                // Örneğin: const responseData = response?.data;
                // if(response?.status === 422) {
                //     console.log("dddddddddd");
                //     setErrorMessages('Göndərilmə zamanı xəta baş verdi');
                // }


            } catch(error) {
                responseError = error;
                if(error?.response?.status === 422) {
                    setErrorMessages('Göndərilmə zamanı xəta baş verdi...');
                }
            } finally {
                // İşlemler burada tamamlanır
                // setLoading(false);
                // setSuccessMessage("");
                // setErrorMessages("");
                setTimeout(() => {
                    setSuccessMessage("");
                    setErrorMessages("");
                }, 3000);
            }

        }



    }

    const page = {
        pages: [
            {
                link: "/ustalar",
                content: "Ustalar",
            },
            {
                link: "/ustalar/qeydiyyat",
                content: "Qeydiyyat",
            }
        ],
    };

    return (
        <>
            <Menu />
            <Breadcrumb page={page} />
            <section className='container container-repairer'>
                <h3 className='rpTodoTitle'>Usta qeydiyyatı</h3>
                <form className='register-repairer' onSubmit={handleSubmit(onSubmit)}>

                    <div className="left-register">
                        <div className="privateForm">
                            <span>Ad</span>
                            <input type="text" placeholder='Ad' {...register("name")} />
                            <p>{errors.name?.message}</p>
                        </div>
                        <div className="privateForm">
                            <span>Mobil</span>
                            <input type="text" placeholder='Mobil'  {...register("number")} />
                            <p>{errors.number?.message}</p>
                        </div>
                        <div className="privateForm">
                            <span>Soyad</span>
                            <input type="text" placeholder='Soyad' {...register("surname")} />
                            <p>{errors.surname?.message}</p>
                        </div>
                        <div className="privateForm">
                            <span>Ünvan</span>
                            <input type="text" placeholder='Ünvan' {...register("address")} />
                            <p>{errors.address?.message}</p>
                        </div>
                        <div className="repairerSelect">
                            <span>Şəhər</span>
                            <Controller
                                name="city_id"
                                className="selectRepairer"
                                control={control}
                                render={({ field: { onChange } }) => (
                                    <Select
                                        options={citySelect}
                                        styles={customStyles}
                                        onChange={(selectedOption) => {
                                            onChange(selectedOption.value);
                                        }}
                                    />
                                )}
                            />
                            <p>{errors.city_id?.message}</p>
                        </div>
                        <div className="privateForm">
                            <span>Təcrübə</span>
                            <input type="number" placeholder='Təcrübə' {...register("experience")} />
                            <p>{errors.experience?.message}</p>
                        </div>

                        <div className='repairTodoSect'>
                            <div className='repairAddTodoHead'>
                                <h3 className='rpTodoTitle'>Gördüyü işlər</h3>
                                <span className='repairAddTodoItem' onClick={() => handleShowNextRow(0)}>
                                    <button className='btn btn__primary add__select add_todo' >
                                        {/* {visibleSelect ? "+" : "+"} */}
                                        +
                                    </button>
                                    <span>Yeni kateqoriya əlavə et</span>
                                </span>
                            </div>
                            <div className='repairers__work'>
                                <div className='row_rep'>
                                    <div>
                                        <Controller
                                            name="position_category_id[0]"
                                            control={control}
                                            render={({ field: { onChange, value } }) => (
                                                <Select
                                                    placeholder={'Kateqoriyanı seç '}
                                                    options={category}
                                                    isSearchable={true}
                                                    isMulti={false}
                                                    styles={customStyles}
                                                    onChange={(selectedOption) => {
                                                        onChange(selectedOption);
                                                        if(selectedOption) {
                                                            // Seçilen kategori varsa, ilgili div elementine "hello" yazdır
                                                            document.getElementById('error_item').innerText = 'Uygun olaraq marka seçməlisiniz';

                                                            // İlgili değişkeni true yap
                                                            setCategorySelected(true);
                                                        } else {
                                                            // Kategori seçimi iptal edildiyse değişkeni false yap
                                                            setCategorySelected(false);
                                                        }
                                                    }}
                                                />
                                            )}
                                        />
                                        {/* {errors.position_category_id && (
                                            <p style={{ color: "red" }}>{errors.position_category_id?.message}</p>
                                        )} */}
                                    </div>
                                    <div>
                                        <Controller
                                            name="car_makes[0]"
                                            control={control}
                                            render={({ field: { onChange, value } }) => (
                                                <Select
                                                    placeholder={'Markanı seç '}
                                                    options={carMakes}
                                                    isSearchable={true}
                                                    isMulti
                                                    styles={customStyles}
                                                    isDisabled={!watch(`position_category_id[0]`)}
                                                    onChange={(selectedOption) => {
                                                        onChange(selectedOption);
                                                        if(selectedOption) {
                                                            // İlgili değişkeni true yap
                                                            setCarMakesSelected(true);
                                                        } else {
                                                            // CarMakes seçimi iptal edildiyse değişkeni false yap
                                                            setCarMakesSelected(false);
                                                        }
                                                    }}
                                                />
                                            )}
                                        />
                                        <p style={{ color: "red" }} id={'error_item'} hidden={carMakesSelected}></p>
                                    </div>
                                </div>
                            </div>
                            <div className='repairers__work repair_append'>
                                {[...Array(category_count)].map((_, index) => (
                                    <div className={`row_rep hidden_rep ${index < clickCount ? 'visible_rep' : ''}`} key={index + 1}>
                                        <div>
                                            <Controller
                                                name={`position_category_id[${index + 1}]`}
                                                control={control}
                                                render={({ field: { onChange, value } }) => (
                                                    <Select
                                                        placeholder={'Kateqoriyanı seç '}
                                                        options={category}
                                                        isSearchable={true}
                                                        isMulti={false}
                                                        styles={customStyles}
                                                        onChange={(selectedOption) => {
                                                            onChange(selectedOption);
                                                            // Seçilen kategoriye göre kontrol ve işlemleri burada yapabilirsiniz
                                                            if(selectedOption) {
                                                                // Seçilen kategori varsa, ilgili div elementine "hello" yazdır
                                                                document.getElementById(`error_item_${index + 1}`).innerText = 'Uygun olaraq marka seçməlisiniz';

                                                                // İlgili değişkeni true yap
                                                                setCategorySelected(true);
                                                            } else {
                                                                // Kategori seçimi iptal edildiyse değişkeni false yap
                                                                setCategorySelected(false);
                                                            }
                                                        }}
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div>
                                            <Controller
                                                name={`car_makes[${index + 1}]`}
                                                control={control}
                                                render={({ field: { onChange, value } }) => (
                                                    <Select
                                                        placeholder={'Markanı seç '}
                                                        options={carMakes}
                                                        isSearchable={true}
                                                        styles={customStyles}
                                                        isMulti
                                                        isDisabled={!watch(`position_category_id[${index + 1}]`)}
                                                        onChange={(selectedOption) => {
                                                            onChange(selectedOption);
                                                            // Seçilen carMakes'e göre kontrol ve işlemleri burada yapabilirsiniz
                                                            if(selectedOption) {
                                                                // İlgili değişkeni true yap
                                                                setCarMakesSelected(true);
                                                            } else {
                                                                // CarMakes seçimi iptal edildiyse değişkeni false yap
                                                                setCarMakesSelected(false);
                                                            }
                                                        }}
                                                    />
                                                )}
                                            />
                                            <p style={{ color: "red" }} id={`error_item_${index + 1}`} hidden={carMakesSelected}></p>

                                        </div>
                                        <span
                                            className='hide_item_rep'
                                            key={index + 1}
                                            onClick={() => handleHideItemClick(index + 1)}
                                        ></span>

                                    </div>
                                ))}

                            </div>
                        </div>

                    </div>
                    <div className="right-images right_img_sect">
                        <div className="right-images">
                            <div className='image_sect_upl'>
                                <div>
                                    <span>Şəkil yüklə </span>
                                    <div className='profilImg'>

                                        <label
                                            className={selectedFile !== null ? "succesImg form__img" : "form__img"}
                                            ref={fileInputRef}
                                            onChange={handleFileSelect}
                                        >
                                            {selectedFile == null ? "Profil şəkili" : "Şəkil yükləndi"}
                                            <input type="file" {...register("photo")} />
                                        </label>
                                        <p>{errors.photo?.message}</p>

                                    </div>
                                </div>
                            </div>

                            <div className='commnet_sect'>

                                <div className='privateForm registerTextarea'>
                                    <span>Qeyd</span>
                                    <textarea rows={3} {...register("description")} />
                                    <p>{errors.description?.message}</p>
                                </div>
                            </div>


                            {/* <button className='btn btn__primary add__select' onClick={() => setVisibleSelect(!visibleSelect)}>
                                {visibleSelect ? "-" : "+"}
                            </button> */}
                            {/* <button type='subnit' className='btn btn__primary '>Qeydiiyat ol</button> */}

                        </div>
                    </div>

                    <div className='rpRegisterBtn'>
                        {/* <p>MESSAGE: {successMessage}</p>
                        <p>ERROR: {errorMessages}</p> */}
                        <div className='msg_part'>
                            {successMessage && <h4 className='succes_msg'> {successMessage}</h4>}
                            {errorMessages && <p className='error_msg'> {errorMessages}</p>}
                        </div>
                        <button type="submit" className='btn btn__primary ' onClick={handleSubmit(onSubmit)}>Qeydiyyatdan keç</button>
                    </div>

                </form>

            </section>
        </>
    )
}

export default RegisterRepair

// Array of all options
const optionList = [
    { value: "red", label: "Red" },
    { value: "green", label: "Green" },
    { value: "yellow", label: "Yellow" },
    { value: "blue", label: "Blue" },
    { value: "white", label: "White" }
];