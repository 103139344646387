import React, { memo } from "react";
import Select from "react-select";
import classes from "./tabpanel.module.css";
import { CustomStyles } from "../../helpers/contants/s3";


const MobilCategory = (props) => {

    const { setCategoryId, data } = props;
    const categories=data?.data?.map((item)=>({value:item.id,label:item.category_name}))

    const handleChange = (e) => {
        setCategoryId(e.value);
    };

    return (
        <>
            <Select
                className={`${classes.selectItem} ${classes.selectItemMobil}`}
                onChange={handleChange}
                placeholder={"Kateqoriya"}
                options={categories}
                styles={CustomStyles}
            />
        </>
    );
};

export default memo(MobilCategory);

