import React, { useEffect, useState } from "react";
import Select from "react-select";
import Menu from "../../components/ui/menu";
import Meta from "../../components/ui/meta";
import Breadcrumb from "../../components/ui/breadcrumb/breadcrumb";
import mark from "../../assets/images/mask_group.jpg";
import styles from "./carnumber.module.css";
import classes from "../../components/ui/filter/filter.module.css";
import CarCard from "../../components/carcard/carcard";
import { SlArrowLeft } from "react-icons/sl";
import { useLocation, useNavigate, Link, useSearchParams } from "react-router-dom";
import { getRegisterNumber } from "../../services/registerNumberService"
import { useInfiniteQuery } from "@tanstack/react-query";
import { useCitiesNumber } from "../../hooks/query/cities";
import { CustomStyles } from "../../helpers/contants/s3";
import SkeletonPost from "../../components/skletons/SkeletonPost";



const CarNumber = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { data: cities } = useCitiesNumber()
  const [value, setValue] = useState(null);
  const [city, setCity] = useState(null);
  const [letter1, setLetter1] = useState(null);
  const [letter2, setLetter2] = useState(null);
  const [searchParams] = useSearchParams();
  const [orderSelectValue, setOrderSelectValue] = useState(null);


  const handleChange = (e) => {
    const newValue = e.target.value.replace(/\D/g, '').slice(0, 3);
    setValue(newValue);
  };


  const params = {
    paginate: 30,
    region_number_id: city?.value,
    first_letter: letter1?.label,
    second_letter: letter2?.label,
    number: value
  }

  if(searchParams.get("order")) params["order"] = searchParams.get("order");
  if(searchParams.get("direction")) params["direction"] = searchParams.get("direction");

  const filteredParams = Object.fromEntries(
    Object.entries(params).filter(([_, value]) => value != null)
  );


  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
  } = useInfiniteQuery(
    ['register-number', params],
    ({ pageParam = 1 }) => getRegisterNumber({ ...filteredParams, page: pageParam }),
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages.length + 1
        return lastPage?.data?.length !== 0 ? nextPage : undefined
      },
    }
  )


  useEffect(() => {
    let fetching = false;

    const handleScroll = async (event) => {
      const { scrollHeight, scrollTop, clientHeight } = event.target.scrollingElement;

      if(!fetching && scrollHeight - scrollTop <= clientHeight * 1.2 && hasNextPage) {
        fetching = true;
        const newData = await fetchNextPage(); // This calls the API to fetch the next page.

        const fetchDataTrue = newData?.data?.pages?.map((item) => item?.data?.data)
        const fetchData = fetchDataTrue?.find((item) => item?.length < 30)
        if(fetchData) {
          document.removeEventListener('scroll', handleScroll);
        }

        fetching = false;
      }
    };

    document.addEventListener('scroll', handleScroll);
    return () => document.removeEventListener('scroll', handleScroll);
  }, [fetchNextPage, hasNextPage]);


  const citySelect = cities?.data?.map((city) => {
    return {
      value: city.id,
      label: city.region_number + " - " + city.name,
    }
  })


  const dataProducts = data?.pages?.map((item) => item?.data);
  const totalProduct = dataProducts?.map((item) => item?.total)[0]


  const letter = [];

  for(let i = 65; i <= 90; i++) {
    const label = String.fromCharCode(i);
    letter.push({ value: i - 64, label });
  }


  const handleRefisterNumber = (e) => {
    e.preventDefault()
  }


  const handleOrder = (e) => {
    const queryParams = Object.fromEntries(searchParams.entries());

    switch(e.value) {
      case 1:
        queryParams.order = "id";
        queryParams.direction = "desc";
        setOrderSelectValue(1);
        break;
      case 2:
        queryParams.order = "price";
        queryParams.direction = "desc";
        setOrderSelectValue(2);
        break;
      case 3:
        queryParams.order = "price";
        queryParams.direction = "asc";
        setOrderSelectValue(3);
        break;
      default:
        queryParams.order = "id";
        queryParams.direction = "desc";
        break;
    }

    const updatedSearchParams = new URLSearchParams(queryParams);
    navigate(`${location.pathname}?${updatedSearchParams.toString()}`);

  }


  const handleRelatedProductClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  const page = {
    pages: [
      {
        link: "/licenseplate",
        content: "Qeydiyyat Nişanları",
      },
    ],
  };

  // const title = "Avtopro - Qeydiyyat Nişanları";

  const currentUrl = window.location.href;
  const title = "Avtopro - Qeydiyyat Nişanları | avtopro.az";
  const keywords = "qeydiyyat nişanları, avtomobil nomresi, masin nomresi, avtomobil, nəqliyyat, nəqliyyat vasitəsi, zapcast, avtopro, aksesuar, motosiklet"
  const description = "Nəqliyyat vasitələrinə aid qeydiyyat nişanlarının satışı avtopro.az'da";

  return (
    <>
      <Meta title={title} keywords={keywords} description={description} currentUrl={currentUrl} />
      <Menu />
      <Breadcrumb page={page} none="dnone" />

      <section>
        <article className={`${styles.main__img}`}>
          <Link className={`${styles.lnone}`} to="/">
            <SlArrowLeft color="#fff" fontSize="2rem" />
          </Link>
          <img src={mark} alt="QEYDİYYAT NİŞANI" />
          <h5>QEYDİYYAT NİŞANI</h5>
        </article>

        <form onSubmit={handleRefisterNumber}
          className={` ${classes.filter__container} ${styles.car__filter__container} container `} >
          <Select
            className={`${classes.selectItem} ${styles.selectfirst} `}
            value={city}
            onChange={(selectedOption) => setCity(selectedOption)}
            options={citySelect}
            placeholder="Bütün şəhərlər"
            styles={CustomStyles}
          />
          <Select
            className={`${classes.selectItem} ${styles.car__selectItem} `}
            value={letter1}
            onChange={(selectedOption) => setLetter1(selectedOption)}
            options={letter}
            placeholder="-"
          />
          <Select
            className={`${classes.selectItem} ${styles.car__selectItem} `}
            value={letter2}
            onChange={(selectedOption) => setLetter2(selectedOption)}
            options={letter}
            placeholder="-"
          />
          <input
            className={`${classes.selectItem} ${styles.car__selectItem} `}
            type="text"
            value={value}
            onChange={handleChange}
            maxLength={3}
          />
          {/* <button className={classes.btn__filter__search}>
            <FiSearch fontSize="2rem" />
          </button> */}
        </form>

        {/* <div className={`${styles.common_card} container`}>
          <h2>Premium elanlar</h2>
          <div  className={`${styles.cards}`}>
          {data?.pages?.map((page) =>
                    page?.data?.data?.map((card) => (
                        <CarCard key={card.id} card={card} />
                    ))
                )}
          </div>
      

        </div> */}

        <div className={`${styles.common_card} container`}>
          {!isFetching && <div className={`${styles.select__class}`}>
            <p>
              <strong>{totalProduct}</strong> nəticə tapıldı
            </p>
            <Select
              className={`${classes.selectItem} ${styles.selectItem2} `}
              placeholder={"Çeşidləmək"}
              options={sortRegister}
              onChange={handleOrder}
              value={sortRegister.find(item => item.value === orderSelectValue)}
              styles={CustomStyles}
            />
          </div>}

          <div className={`${styles.cards__sklet}`}>
            {isFetching && [...Array(30).keys()].map(i => {
              return <SkeletonPost key={i} />
            })}
          </div>

          <div className={`${styles.cards}`}>
            {data?.pages?.map((page) =>
              page?.data?.data?.map((card) => (
                <CarCard key={card.id} card={card} handleRelatedProductClick={handleRelatedProductClick} />
              ))
            )}
          </div>

          <div className={`${styles.cards__sklet}`}>
            {isFetching && [...Array(12).keys()].map(i => {
              return <SkeletonPost key={i} />
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default CarNumber;


const sortRegister = [
  { value: 1, label: "Son məhsullar" },
  { value: 2, label: "Əvvəlcə bahalı" },
  { value: 3, label: "Əvvəlcə ucuz" },
];