import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../components/ui/breadcrumb/breadcrumb";
import Menu from "../../../components/ui/menu";
import Meta from "../../../components/ui/meta";
import styles from './footer_about.module.css'
import { footer_Helps } from '../../../assets/icons/Icons';
import { SlArrowLeft } from "react-icons/sl";
import { Link } from "react-router-dom";
import {
    faq_icon1,
    faq_icon2,
} from "../../../assets/icons/Icons";

const FooterHelp = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const page = {
        pages: [
            {
                link: "/footer_help_center",
                content: "Tez-tez verilən suallar",
            },
        ],
    };

    const left_part = [
        {
            id: 1,
            title: "Voluptate exercitationuis ullamco cillum dolor exercitationuis ullamco cillum dolor",
            description: "Xercitation incididunt aliquip deserunt reprehenderit elit laborum. Nulla Lorem mollit  duis ullamco cillum dolor. Voluptateullamco cillum dolor. Voluptate exercitation incididunt aliquip deserunt reprehenderit elit laborum. Nulla Lorem mollit cupidatat irure."

        },
        {
            id: 2,
            title: "Voluptate exercitationuis ullamco cillum dolor",
            description: "Xercitation incididunt aliquip deserunt reprehenderit elit laborum. Nulla Lorem mollit  duis ullamco cillum dolor. Voluptate exercitationuis ullamco cillum dolor. Voluptate exercitation incididunt aliquip deserunt reprehenderit elit laborum. N"

        },
        {
            id: 3,
            title: "Voluptate exercitationuis ullamco cillum dolor",
            description: "Xercitation incididunt aliquip deserunt reprehenderit elit laborum. Nulla Lorem mollit  duis ullamco cillum dolor."

        },
        {
            id: 4,
            title: "Voluptate exercitationuis ullamco cillum dolor",
            description: "Xercitation incididunt aliquip deserunt reprehenderit elit laborum. Nulla Lorem mollit  duis ullamco cillum dolor. Voluptate"

        },
        {
            id: 5,
            title: "Voluptate exercitationuis ullamco cillum dolor",
            description: "Xercitation incididunt aliquip deserunt reprehenderit elit laborum. Nulla Lorem mollit  duis ullamco cillum dolor."

        },
    ]

    const [activeIndex, setActiveIndex] = useState(null);

    const handleClick = (index) => {
        setActiveIndex(index === activeIndex ? null : index); // Aynı item'a tıklanınca aktifliği kaldırır
    };

    const questions = [
        { title: 'Sifarişimi necə izləyə bilərəm?', content: 'Sifarişinizi izləmək üçün <b>PROFİL &#x203A; SIFARİŞLƏRİM &#x203A; BÜTÜN SİFARİŞLƏR</b>' },
        { title: 'What kind of auto parts are available on the site?', content: 'What kind of auto parts are available on the site?' },
        { title: 'How do I search for the specific auto part that I need on the site?', content: 'How do I search for the specific auto part that I need on the site?' },
        // Diğer öğeleri de ekleyebilirsiniz
    ];
    const title = "Avtopro - Tez-tez verilən suallar | avtopro.az";
    const keywords = "faq, help, tez-tez verilən suallar, balans, razval, xodovoy təmiri, mühərrik təmiri, yağlama, çilingər, elektrik, diaqnostika, fara təmiri, dəmirci"
    const description = "Avtopro ehtiyat hissələrinin satışını edən veb saytdır. Sayt Mağazalar, Rəsmi dilerlər, Qeydiyyat nişanı, Ağır texnika, Ustalar, Xariçdən sifariş, Servislər bölmələrindən ibarətdir. Kataloqumuzda 35 mindən çox məhsul mövcuddur. Bunlar Ağır texnika və Avtomobil üçün ehtiyat hissələridir";

    return (
        <>
            <Meta title={title} keywords={keywords} description={description} />
            <Menu />
            <Breadcrumb page={page} none='dnone' />
            <section className="container">
                <Link className={`${styles.lnone}`} to="/"><SlArrowLeft color='#181818' fontSize="2rem" /></Link>
                <h1 className={`${styles.head} ${styles.greenhead}`} >
                    Tez-tez verilən suallar
                </h1>

                <div className={`${styles.comman_wrapper} ${styles.comman_wrapper_revers}`}>
                    <div className={`${styles.left}`}>
                        <div className={`${styles.part1}`}>
                            <p>{faq_icon1}</p>
                            <h3>Şəxsi məlumatlar </h3>
                            <p>
                                Fiziki və ya hüquqi şəxsə ("məlumat subyekti") aid hər hansı bir məlumat nəzərdə tutulur.
                            </p>
                        </div>
                        <div className={`${styles.part1}`}>
                            <p>{faq_icon2}</p>
                            <h3>Saytın istifadəçisi </h3>
                            <p>
                                İnternet vasitəsilə Sayta giriş əldə edən və Elektron-mağazadan istifadə edən şəxs.
                            </p>
                        </div>
                    </div>
                    <div className={`${styles.right__part}`}>
                        <div className={`${styles.collapse_container}`}>
                            {questions.map((item, index) => (
                                <div
                                    key={index}
                                    className={`${styles.collapse_item} ${activeIndex === index ? styles.active : ''}`}
                                    onClick={() => handleClick(index)}
                                >
                                    <div className={styles.collapse_item__title}><h3>{item.title}</h3></div>
                                    <div className={styles.collapse_item__content} dangerouslySetInnerHTML={{ __html: item.content }} />
                                </div>
                            ))}
                        </div>
                    </div>


                    {/* <div>
                        <h5 className={`${styles.often}`}>Tez-tez verilən suallar</h5>
                        {
                            left_part.map((item) => {
                                return (
                                    <div className={`${styles.right__part}`} key={item.id}>
                                        <h3>
                                            {`${item.id}. ${item.title}`}
                                        </h3>
                                        <p>{item.description}</p>
                                    </div>

                                )
                            })
                        }
                    </div>
                    <div className="right">
                        <p>{footer_Helps}</p>
                    </div> */}

                </div>

            </section>

            {/* <form className={`${styles.contact_form}`}>
                <h3 className={`${styles.often}`}>Əlaqə</h3>
                <div className={`${styles.form_item}`}>
                    <input type="text" placeholder="Ad soyad" />
                </div>
                <div className={`${styles.form_item}`}>
                    <input type="phone" placeholder="Telefon" />
                </div>
                <div className={`${styles.form_item}`}>
                    <textarea rows='5' cols='50' placeholder='Mesaj' />
                </div>
                <button type="submit" className={` btn btn__primary ${styles.send_btn}`}>Göndər</button>
            </form> */}
        </>
    )
}

export default FooterHelp