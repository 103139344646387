import React from "react";
import classes from "./home.module.css";
import Menu from "../../components/ui/menu/index";
import Category from "../../components/category";
import e1 from "../../assets/images/e1.png";
import e2 from "../../assets/images/e2.png";
import e31 from "../../assets/images/e31.png";
import e4 from "../../assets/images/e4.png";
import Brand from "./brand/brand";
import Video from "./video/video";
import Service from "./service/service";
import Partner from "./partner/partner";
import Filter from "../../components/ui/filter/filter";
import Mobilfilter from "../../components/ui/mobilfilter/mobilfilter";
import { Link } from "react-router-dom";
import Meta from "../../components/ui/meta";
import BannerSlider from "../../components/bannerslider/index";
import SliderCard from "./slider/index"

const Home = () => {

    const currentUrl = window.location.href;
    const title = 'Avtopro - Ehtiyat hissələri və aksesuarların satışı'
    const keywords = "Ehtiyyat hissələri, avtomobil, nəqliyyat, nəqliyyat vasitəsi, zapcast, vin, oem, avtopro, aksesuar, ağır texnika, motosiklet, təkərlər, əyləc bəndi, yağlar"
    const description = "Nəqliyyat vasitələrinə aid hər növ ehtiyyat hissələri və aksesuarların satışı avtopro.az'da";

    return (
        <>
            <Meta title={title} keywords={keywords} description={description} currentUrl={currentUrl} />
            <header className={`${classes.header} headerban`}>
                <BannerSlider mobilNone={"mobilNone"} />
                <Filter home={"homePosition"} />
            </header>
            <Menu />
            <Mobilfilter />
            <div style={{ background: "#F3F3F3", paddingBottom: "2rem" }}>
                <SliderCard />
            </div>
            <Category />

            <section className={`container ${classes.everyCar}`}>
                <h3 className="text-title">Ən vacib ehtiyat hissələri</h3>
                <div className={classes.items}>
                    <Link to={`/products?category_id=156`} className={classes.item}>
                        <img src={e2} alt="Əyləc bəndləri" />
                        <h4>Əyləc bəndləri</h4>
                    </Link>
                    <Link to={`/products?category_id=70`} className={classes.item}>
                        <img src={e4} alt="Yağlar və mayelər" />
                        <h4>Yağlar və mayelər </h4>
                    </Link>
                    <Link to={`/products?category_id=45`} className={classes.item}>
                        <img src={e1} style={{ width: "4rem" }} alt="Təkərlər" />
                        <h4>Təkərlər</h4>
                    </Link>
                    <Link to={`/products?category_id=256`} className={classes.item}>
                        <img src={e31} alt="Alışdırma şamları" />
                        <h4>Alışdırma şamları</h4>
                    </Link>
                </div>
            </section>

            <BannerSlider />
            <Brand />
            <Service />
            <Video />
            <BannerSlider />
            <Partner />
        </>
    );

};

export default Home;
