import request from "../helpers/api/request";

export const register = async (data) => {
    const response = await request.post(`/auth/register`, data);
    return response.data;
};


export const login = async (data) => {
    const response = await request.post(`/auth/login`, data);
    return response.data;
};


export const authUser = async (data) => {
    const response = await request.post(`/auth/me`, data);
    return response.data;
};

export const updateMe = async (data) => {
    const response = await request.put(`/update_me`, data);
    return response.data;
};

export const updatePassword = async (data) => {
    const response = await request.put(`/update_password`, data);
    return response.data;
};

export const sendPasswordResetCode = async (data) => {
    const response = await request.post(`/password/reset/send_code`, data);

    return response.data;
};

export const passwordCodeVerification = async (data) => {
    const response = await request.post(`/password/reset/verify`, data);

    return response.data;
}

export const resetPassword = async (data) => {
    const response = await request.post(`/password/reset`, data);

    return response.data;
}
