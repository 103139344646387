import { useQuery } from "@tanstack/react-query";
import { getCarGenerations, getCarSalonGenerations } from "../../services/carGenerationService";

export const useCarGenerations = (params) => {
    const { data, isError, error, isStale, refetch } = useQuery({
        queryKey: ["car_generations", params],
        queryFn: () => getCarGenerations(params),
        staleTime: 10 * (60 * 1000),
        cacheTime: 10 * (60 * 1000),
        enabled: false,
    });

    return { data, isError, error, isStale, refetch };
};

export const useCarSalonGenerations = (params) => {
    const { data, isError, error, isStale, refetch } = useQuery({
        queryKey: ["car_generations", params],
        queryFn: () => getCarSalonGenerations(params),
        staleTime: 10 * (60 * 1000),
        cacheTime: 10 * (60 * 1000),
        enabled: false,
    });

    return { data, isError, error, isStale, refetch };
};
