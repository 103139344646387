import request from "../helpers/api/request";

export const getCarEngines = async (query_params = {}) => {
    const params = new URLSearchParams(query_params);
    const queryString = "?" + params.toString();
    const response = await request.get(`/car/body-types${queryString}`);
    console.log(response);
    console.log("response generation");

    return response.data;
};
