import React from 'react'
import { svgCheck } from '../../assets/icons/Icons'
import "./order.css"
import Meta from '../../components/ui/meta';

const PaymentSuccess = () => {

  const currentUrl = window.location.href;
  const title = "Avtopro - Ödəniş uğurlu | avtopro.az";
  const keywords = "ödəniş, avtomobil, balans, razval, xodovoy təmiri, mühərrik təmiri, yağlama, çilingər, elektrik, diaqnostika, fara təmiri, dəmirci"
  const description = "Nəqliyyat vasitələrinə aid hər növ ehtiyyat hissələri və aksesuarların satışı avtopro.az'da";

  return (
    <>
      <Meta title={title} keywords={keywords} description={description} currentUrl={currentUrl} />
      <section className={`container order__container`}>
        <div className='order__item'>
          <p>{svgCheck}</p>
          <h3>Ödəniş uğurlu oldu</h3>

        </div>
      </section>
    </>
  )
}

export default PaymentSuccess