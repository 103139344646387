import React, { memo } from 'react'
import "./video.css"
import { useYoutube } from '../../../hooks/query/youtube'
import { Link } from 'react-router-dom'

const Video = () => {

  const { data } = useYoutube(5)

  const regex = /\/shorts\/([a-zA-Z0-9_-]+)\??/;
  const shortUrlRegex = /youtu.be\/([a-zA-Z0-9_-]+)\??/i;
  const longUrlRegex = /youtube.com\/((?:embed)|(?:watch))((?:\?v=)|(?:\/))([a-zA-Z0-9_-]+)/i;


  return (
    <section className={`container video__container__home mobilVideo `}>
      <h3 className='text-title'>Youtube HUB</h3>
      <div className={` video__home`}>
        {
          data?.data?.map((item, i) => {
            let videoId = ''
            if (item?.url?.match(regex)) {
              videoId = item?.url?.match(regex)[1];
            } else if (item?.url?.match(shortUrlRegex)) {
              videoId = item?.url?.match(shortUrlRegex)[1];
            } else if (item?.url?.match(longUrlRegex)) {
              videoId = item?.url?.match(longUrlRegex)[3];
            }
            return (
              <div className={`itemVideo${i + 1}`} key={item.id}>
                <iframe src={videoId ? `https://www.youtube.com/embed/${videoId}` : item?.url} title='video'
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen></iframe>
              </div>
            );
          })
        }

      </div>
      <div className="more">
        <Link to={`/video`} > Hamısını gör</Link>
      </div>

    </section>
  )
}

export default memo(Video)