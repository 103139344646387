import React, { useEffect } from "react";
import classes from "./product.module.css";
import Card from "../../components/ui/card/card";
import Select from "react-select";
import { CustomStyles } from "../../helpers/contants/s3";
import SkeletonPost from "../skletons/SkeletonPost";

const Product = (props) => {
    const {
        end,
        handleOrder,
        isFetching,
        orderSelectValue,
        data,
        fetchNextPage,
        hasNextPage,
        shopId
    } = props;


    useEffect(() => {
        let fetching = false;

        const handleScroll = async (event) => {
            const { scrollHeight, scrollTop, clientHeight } = event.target.scrollingElement;

            if(!fetching && scrollHeight - scrollTop <= clientHeight * 1.2 && hasNextPage) {
                fetching = true;
                const newData = await fetchNextPage(); // This calls the API to fetch the next page.

                const fetchDataTrue = newData?.data?.pages?.map((item) => item?.data?.data)
                const fetchData = fetchDataTrue?.find((item) => item?.length < 30)
                if(fetchData) {
                    document.removeEventListener('scroll', handleScroll);
                }

                fetching = false;
            }
        };

        document.addEventListener('scroll', handleScroll);
        return () => document.removeEventListener('scroll', handleScroll);
    }, [fetchNextPage, hasNextPage]);


    const dataProducts = data?.pages?.map((item) => item?.data);
    const totalProduct = dataProducts?.map((item) => item?.total)[0]

    const countData = data?.pages?.map((item) => item?.data?.data?.length)[0]

    const marka = [
        { value: 1, label: "Son məhsullar" },
        { value: 2, label: "Əvvəlcə bahalı" },
        { value: 3, label: "Əvvəlcə ucuz" },
        { value: 4, label: "Mağazalara görə" },
        { value: 5, label: "Dilerlərə görə" }
    ];

    const shopMarka = [
        { value: 1, label: "Son məhsullar" },
        { value: 2, label: "Əvvəlcə bahalı" },
        { value: 3, label: "Əvvəlcə ucuz" },
    ];

    let markaSort;
    if(shopId) {
        markaSort = shopMarka
    } else {
        markaSort = marka
    }

    return (
        <section className={`container ${classes.products}`}>

            <div className={classes.products__list}>
                <p>
                    <strong>{totalProduct} </strong> nəticə tapıldı
                </p>
                {
                    handleOrder &&
                    <Select
                        className={classes.selectItem}
                        placeholder={"Çeşidləmək"}
                        options={markaSort}
                        onChange={handleOrder}
                        value={markaSort.find(item => item.value === orderSelectValue)}
                        styles={CustomStyles}
                    />
                }

            </div>

            {!end && <h3>Endirimli məhsullar</h3>}

            <div className={classes.products__container}>
                {isFetching && [...Array(6).keys()].map(i => {
                    return <SkeletonPost key={i} />
                })}
            </div>

            <div className={classes.products__container} >
                {data?.pages?.map((page) =>
                    page?.data?.data?.map((card, i) => (
                        <Card key={card.id} card={card} />
                    ))
                )}
            </div>

            <div className={classes.products__container}>
                {isFetching && [...Array(countData).keys()].map(i => {
                    return <SkeletonPost key={i} />
                })}
            </div>

        </section>
    );
};

export default Product;


