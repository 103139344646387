import request from "../helpers/api/request";

export const getCarMakes = async (query_params = {}) => {
    const params = new URLSearchParams(query_params);
    const queryString = "?" + params.toString();
    const response = await request.get(`/car_makes${queryString}`);

    console.log(response);
    console.log("data makee");
    return response.data;
};

export const getCountryCarMakes = async (query_params = {}) => {
    const params = new URLSearchParams(query_params);
    const queryString = "?" + params.toString();
    const response = await request.get(`/car_makes_country${queryString}`);

    console.log(response.data);
    console.log("getCountryCarMakes");
    return response.data;
};

export const getSalonMakes = async (query_params = {}) => {
    const params = new URLSearchParams(query_params);
    const queryString = "?" + params.toString();
    const response = await request.get(`/car/cars${queryString}`);

    console.log(response);
    console.log("data makee");
    return response.data;
};


