import React, { useEffect } from 'react'
import { useSimilarProducts } from '../../hooks/query/products'
import classes from "./similar.module.css"
import Card from '../../components/ui/card/card';
import SkeletonPost from '../../components/skletons/SkeletonPost';

const SimilarProduct = (props) => {
    const { category_id, product_id } = props;
    const { data: products, refetch, isFetching, isStale } = useSimilarProducts({
        limit: 12,
        category_id: category_id,
        difference_id:product_id
    });

    useEffect(() => {
        if (category_id && isStale && product_id) refetch()
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [category_id, product_id])

    return (
        <section className={`container ${classes.products}`}>
            <h3>Oxşar  məhsullar</h3>
            <div className={classes.products__container}>
            {isFetching  &&   [...Array(12).keys()].map(i => {
                        return <SkeletonPost key={i} />
            })}
                {products?.data?.map((card) => <Card key={card.id} card={card} />)}
            </div>
        </section>
    )
}

export default SimilarProduct