import { useQuery } from "@tanstack/react-query";
import { getCarEngines } from "../../services/carEngineService";

export const useCarSalonEngines = (params) => {
    const { data, isError, error, isStale, refetch } = useQuery({
        queryKey: ["car_generations", params],
        queryFn: () => getCarEngines(params),
        staleTime: 10 * (60 * 1000),
        cacheTime: 10 * (60 * 1000),
        enabled: false,
    });

    return { data, isError, error, isStale, refetch };
};
