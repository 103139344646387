import React, { useEffect } from "react";
import Breadcrumb from "../../../components/ui/breadcrumb/breadcrumb";
import Menu from "../../../components/ui/menu";
import Meta from "../../../components/ui/meta";
import styles from "./footer_about.module.css";
import {
  terms1_Footer,
  terms2_Footer,
  terms_Form,
} from "../../../assets/icons/Icons";
import { SlArrowLeft } from "react-icons/sl";
import { Link } from "react-router-dom";

const FooterTerms = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const page = {
    pages: [
      {
        link: "/footer_terms",
        content: "Şərtlər və qaydalar",
      },
    ],
  };

  const title = "Avtopro - Şərtlər və qaydalar | avtopro.az";
  const keywords = "şərtlər və qaydalar, terms and conditions, balans, razval, xodovoy təmiri, mühərrik təmiri, yağlama, çilingər, elektrik, diaqnostika, fara təmiri, dəmirci"
  const description = "Avtopro ehtiyat hissələrinin satışını edən veb saytdır. Sayt Mağazalar, Rəsmi dilerlər, Qeydiyyat nişanı, Ağır texnika, Ustalar, Xariçdən sifariş, Servislər bölmələrindən ibarətdir. Kataloqumuzda 35 mindən çox məhsul mövcuddur. Bunlar Ağır texnika və Avtomobil üçün ehtiyat hissələridir";

  return (
    <>
      <Meta title={title} keywords={keywords} description={description} />
      <Menu />
      <Breadcrumb page={page} none="dnone" />
      <section className="container">
        <Link className={`${styles.lnone}`} to="/">
          <SlArrowLeft color="#181818" fontSize="2rem" />
        </Link>
        <h1 className={`${styles.head} ${styles.headcolor}`}>
          {/* {terms_Form} */}
          Şərtlər və qaydalar
        </h1>
        <div className={`${styles.comman_wrapper}`}>
          <div className={`${styles.left}`}>
            <div className={`${styles.part1}`}>
              <p>{terms1_Footer}</p>
              <h3>Müqavilə</h3>
              <p>
                Onlayn alış ilə bağlı Alıcı ilə Satıcı arasında elektron formada bağlanılan və əsas şərtləri burada göstərilən müqavilə;
              </p>
            </div>
            <div className={`${styles.part1}`}>
              <p>{terms2_Footer}</p>
              <h3>Sayt və ya Portal  </h3>
              <p>
                Məhsulların onlayn alış-veriş məkanı olan, Autoparts Pro-ya məxsus “www.avtopro.az” internet səhifəsi və onun tərkibi;
              </p>
            </div>
          </div>

          <div className={`${styles.right__part}`}>
            <h3>Giriş</h3>
            <p>
              Bu şərtlər və qaydalar ("Şərtlər və Qaydalar"), “<a href="https://avtopro.az/">www.avtopro.az</a>” veb-saytında yerləşən, onlayn formada məhsulların satışı və alışı üçün yaradılmış “<a href="https://avtopro.az/">AVTOPRO.AZ</a>” e-mağaza platformasının istifadə və alış qaydalarını müəyyənləşdirir.Sayta giriş etməklə aşağıdakıları təsdiq edirsiniz:
            </p>

            <ul className={`${styles.term_list}`}>
              <li>Bu Şərtlər və Qaydalar ilə tam həcmdə tanış olduğunuzu;</li>
              <li>
                Bu Şərtlər və Qaydaları, habelə məhsulun alınmasına dair müqavilənin predmetini və şərtlərini tam həcmdə anladığınızı; və
              </li>
              <li>
                Müqavilənin bağlanması və icra edilməsi ilə bağlı hərəkətlərin
                əhəmiyyətini və nəticələrini tam dərk etdiyinizi.
              </li>
            </ul>
            <br />
            <h3>
              <span>1. </span>ƏSAS ANLAYIŞLAR
            </h3>
            <p>
              1.1. Bu Şərtlər və Qaydalarda aşağıdakı əsas anlayışlardan istifadə olunur:
              <br /><br />
              1.1.1. <strong> Müqavilə</strong> – Onlayn alış ilə bağlı Alıcı ilə Satıcı arasında elektron formada bağlanılan və əsas şərtləri burada göstərilən müqavilə;
              <br /><br />
              1.1.2. <strong>Alıcı </strong>  – Saytın verdiyi texniki imkanlar vasitəsi ilə ödəniş edərək Saytda qeyd olunmuş məhsulları sifariş edən, həmçinin bu Müqavilənin şərtləri ilə razılaşan fiziki və ya hüquqi şəxs;
              <br /><br />
              1.1.3. <strong> Satıcı </strong> – Saytda öz məhsullarını satan sahibkar (hüquqi şəxs və ya fərdi sahibkar);
              <br /><br />
              1.1.4. <strong>Autoparts Pro</strong> – məhsulların satışı məqsədilə özünə aid elektron mağazada satışı təşkil edən hüquqi şəxs (“Autoparts Pro” MMC);
              <br /><br />
              1.1.5. <strong> Sayt və ya Portal</strong> – məhsulların onlayn alış-veriş məkanı olan, Autoparts Pro-ya məxsus “<a href="https://avtopro.az/">www.avtopro.az</a>” internet səhifəsi və onun tərkibi;
              <br /><br />
              1.1.6. <strong> Məhsullar </strong> – Saytda yerləşdirilmiş hər hansı mallar, təchizatlar, materiallar;
              <br /><br />
              1.1.7. <strong> Sifariş </strong> – Alıcının müəyyən etdiyi ünvana çatdırılması məqsədilə Saytdan məhsulların alınması məqsədilə Saytda mövcud olan müvafiq forumun doldurularaq müvafiq məhsulla bağlı təqdim olunan ofertanı (satış təklifini) qəbul (“aksept”) etdiyini bəyan və təsdiq etməsi.
            </p>

            <br />
            <h3>
              <span>2. </span>ƏSAS ŞƏRTLƏR
            </h3>
            <p>

              2.1. Şərtlər və Qaydaların predmetini Alıcı tərəfindən şəxsi və digər məqsədlər üçün Saytda əks olunan Məhsulların alınması təşkil edir. Bu razılaşma şərtləri Saytda cari vaxtda satışda olan bütün Məhsullara şamil olunur.
              <br /><br />
              2.2.  Saytdan Məhsul sifariş edən Alıcı bu razılaşmanın şərtləri ilə tam razılaşdığını bəyan edir.
              <br /><br />
              2.3. Saytda satılan məhsullar, həmçinin satış şərtləri Azərbaycan Respublikasının qüvvədə olan Mülki Məcəlləsinin 408-ci maddəsinə əsasən ümumi oferta hesab olunur.
              <br /><br />
              2.4. Satıcı və ya Platforma alış həyata keçirilənədək alış şərtlərini heç kimə xəbərdarlıq etmədən dəyişdirə bilər.
              <br /><br />
              2.5. Alıcı sifariş etməklə Satıcının öz öhdəliklərinin icrasını 3-cü şəxslərə tapşırmasına etiraz etmir və Satıcı Sifariş ilə bağlı 3-cü şəxslərin bu razılaşmadan kənara çıxan hərəkətlərinə görə məsuliyyət daşıyır.
              <br /><br />
              2.6. Sifarişin nəticəsi olaraq  Alıcı ilə Satıcı arasında müqavilə bağlanmış hesab olunur və hər iki tərəfin bu müqavilədən kənar və (ya) qanunsuz hərəkətlərdən irəli gələn zərər və deliktlərə görə Autoparts Pro məsuliyyət daşımır.
              <br /><br />
              2.7. Saytda texniki-profilaktik işlərin aparıldığı günlərdən və fors-major hallardan başqa, alıcı günün, ayın, ilin istənilən vaxtı Saytın xidmətlərindən istifadə edə bilər. Platformanın iradəsindən kənar səbəblərdən dolayı Saytın fəaliyyətinin müəyyən müddətdə dayanması, habelə Platformanın və Satıcının bu Şərtlər və Qaydalardan irəli gələn öhdəliklərinin icrasının qeyri-mümkünlüyü səbəbindən Alıcıya zərər dəyərsə, bu halda Platforma və ya Satıcı hər hansı məsuliyyət daşımır.
              <br /><br />
              2.8. Saytda satış üçün nəzərdə tutulmuş Məhsulların foto-şəkilləri illüstrativ təyinatlı olmaqla alınmış Məhsulla 100% eyni görsənməyə bilər. Məlumatlarda əhəmiyyətli çatışmazlıq olduğu təqdirdə, Alıcı “<a href="mailto:info@avtopro.az">info@avtopro.az</a>” ünvanına yazılı müraciət göndərə bilər.
              <br /><br />
              2.9. Saytda görünməsinə baxmayaraq, əgər sifariş olunmuş Məhsul anbarda yoxdursa, Satıcı bu barədə ən qısa vaxt ərzində Alıcıya xəbər verməklə sifarişi ləğv edir. Sifarişin ləğvi nəticəsində Alıcıya hər hansı real zərər dəyərsə, buna görə Autoparts Pro məsuliyyət daşımır.
              <br /><br />
              2.10. Alıcı tərəfindən yanlış məlumat verilməsi nəticəsində sifarişin icra edilməməsinə görə Satıcı və Autoparts Pro məsuliyyət daşımır.
              <br /><br />
              2.11. Çatdırılma və ya digər problemlər ilə bağlı Alıcı Sayt administrasiyasına öz şikayətlərini elektron qaydada “<a href="mailto:info@avtopro.az">info@avtopro.az</a>” ünvanına təqdim edə bilər.
              <br /><br />
              2.12. Alıcının sifarişi ləğv edilə bilər: <br />
              - Əgər Alıcı 4 dəfə ard-arda hər hansı Məhsulu almaqdan əsassız surətdə imtina edirsə;  <br /> - Əgər Alıcı tərəfindən qeyri-qanuni əməliyyatların həyata keçirilməsinə şüphə varsa, habelə qeyri-etik davranış nümayiş elətdirirsə.
              <br /><br />
              Qeyd olunan hərəkətlər istehlakçılara münasibətdə ayrı-seçkilik kimi deyil, Satıcının və Platformanın maddi itkisinin və işgüzar nüfuzuna zərərin qarşısının alınması kimi başa düşülməlidir.
            </p>
            <br />
            <h3>
              <span>3. </span>ÇATDIRILMA
            </h3>
            <p>

              3.1. Məhsulların çatdırılması yalnız Azərbaycan Respublikasının dövlət sərhədləri ilə məhdudlaşır. Azərbaycan Respublikasından kənara çatdırılma (Məhsulun ixracı) həyata keçirilmir.
              <br /><br />
              3.2. Məhsulların Alıcıya çatdırılması Elektron Mağaza  tərəfindən həyata keçirilmir və buna görə hər hansı məsuliyyət daşımır. Məhsulların çatdırılması bir başa Satıcı ilə Alıcı arasında razılaşdırılaraq mağazada təhvil verilir və ya Satıcı tərəfindən çatdırılrır.
              <br /><br />

              3.3.  Satıcının öhdəliklərinə Məhsulun istismarı və xüsusiyyətləri üzrə konsultasiya, onun quraşdırılması və tənzimlənməsi daxil deyil.
              <br /><br />

              3.4. Sifariş şəxsən Alıcıya və ya qeyd etdiyi ünvanda olan istənilən 3-cü şəxsə təhvil verilir. Sifariş, Satıcının mağazasında Məhsul verildiyi və ya Alıcının təyin etdiyi ünvanda özünə və ya təyin etdiyi şəxsə Məhsulu çatdırılmasını təsdiq edən müşayiət sənədlərində imzanın atıldığı anda Alıcıya çatdırılmış hesab olunur.
              <br /><br />
              3.5.  Çatdırılmanı həyata keçirən əməkdaş Məhsulu verərkən, Alıcının şəxsiyyətini təsdiq edən sənədi və Sifarişin eyniləşdirməsi ilə bağlı məlumatı Alıcıdan tələb etmək hüququna malikdir.

              <br /><br />
              3.6. Göndərilmiş Məhsulu qəbul edən Alıcı və ya üçüncü şəxs Məhsulu kuryerdən qəbul edərkən yerində Məhsula baxış keçirməlidir. Məhsulun uyğunluğunu yoxlamaq yalnız Sifarişi çatdırmış və ya vermiş şəxsin iştirakı ilə həyata keçirilir. Baxış keçirdikdən sonra Alıcı və ya üçüncü şəxs Məhsulun çatdırılmasını təsdiq edən sənədi imzalayır və bununla da Məhsula dair hər hansı iddia olmadığını bəyan edir.

              <br /><br />
              3.7. Məhsulu təhvil aldıqdan sonra dəymiş hər hansı zərərə görə Alıcı məsuliyyət daşıyır.
              <br /><br />

              3.8. Alıcıya təqdim olunan Məhsullar üzərində qanunvericiliklə müəyyən olunmuş bütün texniki və s. zəruri sənədlər verilir.
            </p>
            <br />
            <h3>
              <span> 4. </span>ÖDƏNİŞ
            </h3>
            <p>
              4.1. Saytda satılan Məhsulların qiyməti vergilər daxil olmaqla Azərbaycan manatı ilə göstərilir.
              <br /><br />
              4.2. Ödəniş “Debit/Credit Card” (Visa, MasterCard) vasitələri ilə həyata keçirilir.
              <br /><br />
              4.3. Alıcı tərəfindən seçilmiş ödəniş formasından asılı olmayaraq, Məhsulların dəyəri 100% (yüz faiz) həcmində avans ödənişlə həyata keçirilir. Məhsulun dəyərinin hissə-hissə ödənilməsi qadağandır.
              <br /><br />
              4.4. Alış o vaxt baş tutmuş sayılır ki, Alıcı Məhsulun dəyərini bu Şərtlər və Qaydalarda göstərilən formada tam həcmdə ödəmiş olsun.
              <br /><br />
              4.5. Saytda Məhsulun qiyməti Satıcı tərəfindən birtərəfli qaydada dəyişdirilə bilər.
              <br /><br />
              4.6. Satıcı birtərəfli qaydada öz Məhsullarına endirim tətbiq edə bilər. Eyni və ya bənzər Məhsulu satan digər Satıcının da həmin endirimi və ya eyni həcmdə endirimi tətbiq etmək öhdəliyi yoxdur.
              <br /><br />
              4.7. Bank kartı vasitəsilə onlayn ödəniş edilərkən bank xidməti göstərən şirkət əməliyyatın dələduzluq və digər qeyri-qanuni məqsədlərlə həyata keçirildiyini müəyyən edərsə, ödənişi ləğv edə bilər. Bu cür hallardan yaranan mübahisələrə görə Satıcı və Portal məsuliyyət daşımır.
              <br /><br />
              4.8. Saytdan istifadə zamanı hər hansı Məhsulu almaq barədə qərar verdikdə, Alıcı müəyyən əməliyyatları etməklə ödəmə səhifələrinə yönləndirilir. Həmin ödəmə səhifələri Platforma ilə tərəfdaşlıq edən müxtəlif maliyyə qurumlarına və banklara məxsus ola bilər. Daxil etdiyiniz bank (kart) məlumatlarının təhlükəsizliyinə görə Platforma heç bir məsuliyyət daşımır. Həmin məlumatlar Platformanın serverlərində saxlanılmır. Alıcı Platformanın müəyyən xidmətlərin operativliyini təmin etmək məqsədilə yalnız öz iradəsi daxilində və öz arzusuna görə, “Banklar haqqında” Azərbaycan Respublikasının Qanununun 41-ci maddəsinin (“bank sirri”) tələblərini gözləmək şərtilə, bank hesabına dair məlumatları Platformaya təqdim edə bilər. Belə olan halda Platforma həmin məlumatları qorumağa və yaymamağa görə cavabdehlik daşıyır.

            </p>
            <br />
            <h3>
              <span>5. </span>GERİ QAYTARMA
            </h3>
            <p>

              5.1. Məhsulların geri qaytarılması şərtləri “İstehlakçıların hüquqlarının müdafiəsi haqqında” qanunla tənzimlənir.
              <br /><br />
              5.2. Əgər Satıcının təqsiri səbəbindən olmayan hallarda Məhsul qaytarılırsa, qaytarılma ilə bağlı xərclər Alıcıdan tutulur.
              <br /><br />
              5.3. Məhsulun təyinatına uyğun olmayan formada istifadə nəticəsində Alıcıya vurulmuş zərərə görə Satıcı məsuliyyət daşımır.
              <br /><br />
              5.4. Məhsullara dair zəmanət müddəti Məhsulun istehsalçısı tərəfindən müəyyən olunur.
              <br /><br />
              5.5. Bu Şərtlər və Qaydalarda göstərilən müddəalara baxmayaraq, Məhsul bu Şərtlər və Qaydalarda göstərilən formada çatdırıldıqdan sonra Platforma Məhsulun geri qaytarılması ilə bağlı hər hansı öhdəlik və məsuliyyət daşımır.
              <br /><br />
              5.6. Məhsulu geri qaytarmaq istəyən və ya Sifarişi ləğv etmək istəyən Alıcı qeyd olunan tələblə bilavasitə olaraq Satıcıya müraciət etməlidir və buna görə Platforma məsuliyyət daşımır.
              <br /><br />
              5.7. Alıcının Məhsula görə ödədiyi pul vəsaitlərinin geri qaytarılması tələbinə Satıcı tərəfindən baxılır.
            </p>
            <br />
            <h3>
              <span>6. </span>DİGƏR ŞƏRTLƏR
            </h3>
            <p>

              6.1. Bu Şərtlər və Qaydalardan irəli gələn və ya onunla, o cümlədən onun təfsiri, icrası, xitamı və etibarsızlığı ilə bağlı yaranan bütün mübahisələr və ya iddialar, ilk növbədə tərəflər arasında dostcasına danışıqlar yolu ilə həll edilir. Əgər bu cür mübahisə tərəflər arasında həll edilməzsə, bu halda həmin mübahisə Azərbaycan Respublikasının müvafiq məhkəmələri tərəfindən həll edilir.
              <br /><br />
              6.2. Hər hansı problemlə Alıcı üzləşdikdə elektron formada “<a href="mailto:info@avtopro.az">info@avtopro.az</a>” elektron poçt ünvanına müraciət edə bilər. Müraciətlərə ən qısa vaxt ərzində baxılır.
              <br /><br />
              6.3. Autoparts Pro bu Şərtlər və Qaydalarda göstərilən hallardan əlavə, aşağıda sadalanan hallardan istənilən biri baş verdikdə, xidmətlərin göstərilməsi çərçivəsində öhdəliklərin icra edilməməsinə və ya lazımı qaydada icra edilməməsinə görə məsuliyyət daşımır:
              <br /><br />
              6.3.1. Bunun səbəbi Alıcının bu Şərtlər və Qaydalara riayət etməməsi olduğu halda;
              <br /><br />
              6.3.2. Sifariş çatdırıldığı gün, yaxud Alıcı ilə (qəbul edənlə) razılaşdırılmış başqa müddət ərzində, Alıcı (onun qeyd etdiyi qəbul edən şəxs) Sifarişin çatdırıldığı ünvanda olmadığı və ya olmasına baxmayaraq Məhsulu qəbul etmədiyi halda;
              <br /><br />
              6.3.3. Sifarişin detalları və çatdırılma vaxtının müzakirə olunması üçün Alıcı ilə (qəbul edənlə) Sifarişin rəsmiləşdirilməsi zamanı qeyd olunan əlaqə məlumatları vasitəsilə əlaqə qurmaq mümkün olmadığı halda;
              <br /><br />
              6.3.4. Bunun səbəbi qarşısı alınmaz hallar (Fors-major hallar): təbii fəlakətlər, hərəkət yollarının fəaliyyətinin dayandırılması, kəskin hava şəraiti, qar yığınları, müharibələr, daşqınlar, yanğınlar, zəlzələlər və digər təbii fəlakətlər və həmin halların toxunduğu tərəfin real təsir göstərə bilmədiyi və əvvəlcədən proqnozlaşdıra bilmədiyi istənilən digər hallar olduğu halda;
              <br /><br />
              6.3.5. Alıcı Məhsulun çatdırılması üzrə müəyyən üsullar üçün qeyd olunan çatdırılma qaydasına və şərtlərinə uyğun olaraq əldə edilməsi üçün zəruri digər hərəkətlər etmədiyi halda.
              <br /><br />
              6.4. Alıcı təsdiq edir ki, onun fəaliyyət qabiliyyəti var və, o, Azərbaycan Respublikasının qanunvericiliyi ilə bu Şərtlər və Qaydalarda nəzərdə tutulan sövdələşmələrin yerinə yetirilməsi üçün zəruri olan yaşa çatıb.
              <br /><br />
              6.5 Platforma Alıcının bu Şərtlər və Qaydaları pozduğunu aşkar etdiyi halda, Alıcının rəsmiləşdirdiyi sifarişləri imtina etmək hüququnu özündə saxlayır. Bu zaman Platforma Alıcının hərəkətlərinin qeyri- dürüst və/ və ya bu Şərtlər və Qaydaları pozan hərəkət kimi qəbul edilməsi üçün üsul və əsasları sərbəst şəkildə müəyyən edir və qeyd olunan məlumatları üçüncü şəxslərə açıqlamamaq hüququndadır.
              <br /><br />
              6.6. Alıcı Saytda keçidi verilmiş hər hansı başqa saytın istifadəsi ilə əlaqədar yaranan və ya yaranması iddia olunan zərər və ya ziyana görə birbaşa və ya dolayısı ilə Platformanın məsuliyyət və ya cavabdehlik daşımayacağını qəbul edir və bununla razılaşır.
              <br /><br />
              6.7. Platforma Alıcının Saytdan və ya Saytda verilmiş keçidə daxil olmaqla digər saytdan istifadə etməsi nəticəsində (habelə hər hansı materialı yükləməyi nəticəsində) Alıcının kompüter avadanlıqlarına, mobil cihazlarına və bu qəbildən olan bütün digər avadanlıqlarına sızmış viruslar və ya texnoloji cəhətdən digər zərərli materiallar tərəfindən dəyən hər hansı zərər və ya ziyana görə məsuliyyət daşımır.
              <br /><br />
              6.8. Platforma Alıcılara onların hüquq bərabərliyi, iradə sərbəstliyi, əmlak müstəqilliyi, mülkiyyətin toxunulmazlığı, müqavilə azadlığı, sahibkarlıq fəaliyyətinin maneəsiz həyata keçirilməsi, azad və ədalətli rəqabət, malların, xidmətlərin və maliyyə vəsaitlərinin sərbəst hərəkəti, hüquqların məhkəmədə müdafiəsinə təminat verilməsi prinsiplərini rəhbər tutmaqla, “Elektron ticarət haqqında” Azərbaycan Respublikasının Qanununun tələblərinə uyğun olaraq elektron ticarəti təşkil edir.
              <br /><br />
              6.9. Bu Saytdan istifadə ilə əlaqədar yaranan münasibətlərə Azərbaycan Respublikasının qanunvericiliyi tətbiq olunur.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default FooterTerms;
