import React, { useContext, useState, useEffect } from "react";
import "./register.css";
import Modal from "../../modals/Modal";
// import "../carts/privateinfo/privateinfo.css"
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../../services/authService";
import { AuthContext } from "../../context/AuthContext";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import axios from "axios";



const Login = ({ showLoginModal, setShowLoginModal }) => {
    const { setUser, setIsAuthenticated } = useContext(AuthContext);
    const [userName, setUserName] = useState("");
    const navigate = useNavigate();
    const [errorMessages, setErrorMessages] = useState("");
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [incorrectPassword, setIncorrectPassword] = useState(false);

    // const [showLoginModal, setShowLoginModal] = useState(true);
    const screenSize = window.innerWidth;


    const {
        register,
        handleSubmit,
        reset,
        // formState: {},
    } = useForm({});

    const onSubmit = async (data) => {
        setErrorMessages("");
        setLoading(true);
        setIncorrectPassword(false);

        try {
            const response = await login(data);
            localStorage.setItem("token", response?.data?.access_token);
            const myObjStr = JSON.stringify(response?.data?.user);
            localStorage.setItem("userData", myObjStr);
            localStorage.setItem("userDataLast", myObjStr);

            setUser(response?.data?.user);
            setIsAuthenticated(true);
            setShowLoginModal(false);
            // navigate("");
            // reset();
        } catch(error) {
            if(error?.response?.status === 422) {
                setErrorMessages(error?.response?.data?.errors ?? {});
            } else if(error?.response?.status === 401) {
                setIncorrectPassword(true);
            }
        } finally {
            setLoading(false);
        }
    };
    const hansleSuccessGooglelogin = async (data) => {

        try {
            const response = await axios.post("https://api.avtopro.az/api/social-auth/callback/google", data);

            localStorage.setItem("token", response?.data?.data?.access_token);
            localStorage.setItem("userData", JSON.stringify(response?.data?.data?.user));
            localStorage.setItem("userDataLast", JSON.stringify(response?.data?.data?.user));
            setUser(response?.data?.data?.user);
            setUserName(response?.data?.data?.user);
            setIsAuthenticated(true);
            setShowLoginModal(false);
            // navigate("");
        } catch(error) {
            // Handle error
        }
    };

    const clientId = "18246125331-0nf2h76b0ivv0cj5oofnosvs9a9ra4gh.apps.googleusercontent.com";
    const closeModal = () => {
        setShowLoginModal(false);
    };
    useEffect(() => {
        if(!loading) {
            setShowLoginModal(false);
        }
    }, [loading]);


    return (
        <>
            <div className="login_moda_mobile">
                <Modal isOpen={showLoginModal} onClose={closeModal} width={500} height={screenSize > 600 ? 55 : 75}>

                    <div className="open__form__modal form_login_modal">
                        <div className="register_succes_content">
                            <div className="update_register_form update_login_form">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <h3>Hesaba daxil ol</h3>
                                    <div className="privateForm">
                                        <span>E-mail</span>
                                        <input type="text" placeholder="E-mail" {...register("email")} />
                                        <p className="errorMessage">{errorMessages?.email}</p>
                                    </div>
                                    <div className="privateForm">
                                        <span>Şifrə*</span>
                                        <input
                                            type={!showPassword ? "password" : "text"}
                                            placeholder="********"
                                            {...register("password")}
                                        />
                                        {!showPassword ? (
                                            <AiFillEyeInvisible style={eye} onClick={() => setShowPassword(!showPassword)} />
                                        ) : (
                                            <AiFillEye style={eye} onClick={() => setShowPassword(!showPassword)} />
                                        )}
                                        {/* <p className='errorMessage'>{errorMessages?.password}</p> */}
                                        {incorrectPassword ? <p className="errorMessage"> Giriş məlumatları səhvdir </p> : ""}
                                    </div>
                                    <button type="submit" className="btn btn__primary " >
                                        {loading ? (
                                            <>
                                                Göndərilir &nbsp; <i className="fas fa-circle-notch fa-spin"></i>
                                            </>
                                        ) : (
                                            "Daxil ol"
                                        )}
                                    </button>

                                    <div className="google_login">
                                        <GoogleOAuthProvider clientId={clientId} >
                                            <GoogleLogin
                                                onSuccess={hansleSuccessGooglelogin}
                                                onError={() => {
                                                    console.log("Login Failed");
                                                }}
                                            />
                                        </GoogleOAuthProvider>
                                    </div>
                                    <div className="sign_items">
                                        <div className="sign_itm_button">
                                            {/* <Link onClick={() => setLogin(!login)} className="sign_in_btn">{!login ? "Qeydiyyat" : "Hesaba daxil ol"}</Link> */}
                                            <Link to="/register" className="sign_in_btn" onClick={closeModal}>Qeydiyyat</Link>
                                        </div>
                                        <div className="sign_itm_button">
                                            <Link to="/forgot-password" onClick={closeModal} style={{ "textDecoration": "none" }} className="forgot_btn">Şifrəni unutmusunuz?</Link>
                                        </div>
                                    </div>

                                </form>

                            </div>
                        </div>
                    </div>

                </Modal>
            </div>

        </>
    );
};

export default Login;

const eye = {
    position: "absolute",
    right: "1.5rem",
    top: "55%",
    cursor: "pointer",
    color: "#8E8E8E",
};
