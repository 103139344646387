import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import classes from "./mobil-nav.module.css"
import { AiFillHome } from "react-icons/ai"
import { IoStorefrontSharp } from "react-icons/io5"
import { FaCarAlt, FaTag, FaUser } from "react-icons/fa"
import { TbAwardFilled } from "react-icons/tb"
import { ModalNav } from '../modalNavbar/modalNav'
import { ModalNavSalon } from '../modalNavbarSalon/modalNavSalon'
import { ModalNavMini } from '../modalNavbarMini/modalNavMini'

import Modal from "../../../modals/Modal";

const MobilNavbar = () => {
  const [isModalOpenNavMini, setIsModalOpenNavMini] = useState(false);
  const [isModalOpenNav, setIsModalOpenNav] = useState(false);
  const [isModalOpenNavSalon, setIsModalOpenNavSalon] = useState(false);
  const screenSize = window.innerWidth;
  const openModalMini = () => {
    setIsModalOpenNavMini(true);
  };
  const openModal = () => {
    setIsModalOpenNav(true);
    setIsModalOpenNavMini(false);
  };
  const openModalSalon = () => {
    setIsModalOpenNavSalon(true);
    setIsModalOpenNavMini(false);
  };

  const closeModalMini = () => {
    setIsModalOpenNavMini(false);
  };


  const [index, setIndex] = useState(1);
  const [isVisible, setIsVisible] = useState(true);
  const [prevScrollY, setPrevScrollY] = useState(0);

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    setIsVisible(prevScrollY > currentScrollY);
    setPrevScrollY(currentScrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevScrollY]);



  const mobilNav = [
    {
      id: 1,
      url: "/",
      icon: <AiFillHome fontSize="2.5rem" />,
      text: 'Ana səhifə'
    },
    {
      id: 2,
      url: "/shops",
      icon: <IoStorefrontSharp fontSize="2.5rem" />,
      text: 'Mağaza'
    },
    {
      id: 3,
      url: null,
      icon: <button onClick={openModalMini}>+</button>,
      text: 'Yeni elan',
      class: "ads"
    },
    {
      id: 4,
      url: "/licenseplate",
      icon: <FaCarAlt fontSize="2.5rem" />,
      text: 'Qeydiyyat Nişanı'
    },
    {
      id: 5,
      url: "/register",
      icon: <FaUser fontSize="2.5rem" />,
      text: 'Profil'
    }
  ]

  return (
    <>
      {isVisible && <section className={classes.mobilNav} >
        <ul className='container'>
          {
            mobilNav.map((item) => (
              <li key={item.id}>
                <Link to={item.url} onClick={() => setIndex(item.id)} className={`${item.id === index ? classes.activeNav : ""} ${item.id === 3 && classes.ads}`}>
                  {item.icon}
                  <span> {item.text}</span>
                </Link>
              </li>
            ))
          }

        </ul>


        <Modal isOpen={isModalOpenNavMini} onClose={closeModalMini} width={500} height={screenSize > 600 ? 85 : 40}>
          <div className={`open__form__modal modal_mini`}>
            <h3>Seçim et</h3>
            <div className="form__item btn_fr">
              <button className='btn btn__primary btn__nav' onClick={openModalSalon}>Avtosalon aç</button>
            </div>
            <div className="form__item">
              <button className='btn btn__primary btn__nav' onClick={openModal}>Mağaza aç</button>
            </div>

          </div>
        </Modal>


        <ModalNav isModalOpenNav={isModalOpenNav} setIsModalOpenNav={setIsModalOpenNav} />
        <ModalNavSalon isModalOpenNavSalon={isModalOpenNavSalon} setIsModalOpenNavSalon={setIsModalOpenNavSalon} />
      </section>}
    </>
  )
}

export default MobilNavbar

