import React, { useState } from "react";
import Modal from "../../../modals/Modal";
import "../../../assets/styles/form.css";
import styles from './modalorder.module.css'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./yup";
import axios from "axios";
import { baseURL } from "../../../helpers/api/request";
import InputMask from "react-input-mask";


export const ModalOrder = ({ isModalOpenOrder, setIsModalOpenOrder }) => {

    const screenSize = window.innerWidth
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (data) => {
        setSuccessMessage('');
        setLoading(true);
        data.phone = data.phone.replaceAll(' ', '').replaceAll('-', '').replaceAll('(', '').replaceAll(')', '');
        try {
            const response = await axios.post(`${baseURL}/abroad_orders`, data, {
                headers: { "Content-Type": "multipart/form-data" },
            })
            if(response?.status===200){
                setSuccessMessage('Sifariş qəbul olundu');
            }
            

        } catch (error) {
            setSuccessMessage(' Göndərilmə zamanı xəta baş verdi');
        }
        finally {
            setLoading(false)
            setTimeout(() => {
                reset()
                setIsModalOpenOrder(false)
                setSuccessMessage('');
            }, 3000);
        }
    }

    const closeModal = () => {
        setIsModalOpenOrder(false);
    };


    

    return (
        <Modal isOpen={isModalOpenOrder} onClose={closeModal} width={500} height={screenSize > 600 ? 85 : 95}>

            <form onSubmit={handleSubmit(onSubmit)} className={`open__form__modal ${styles.form__modal}`}>
                <h3>Sifariş et</h3>
                <h5>Məhsul detalları</h5>
                <div className="form__item">
                    <span>Avtomobil</span>
                    <input type="text" {...register("type")} />
                    <p>{errors.type?.message}</p>
                </div>
                <div className="form__item">
                    <span>Marka</span>
                    <input type="text" {...register("make")} />
                    <p>{errors.make?.message}</p>
                </div>
                <div className="form__item">
                    <span>Model</span>
                    <input type="text" {...register("model")} />
                    <p>{errors.model?.message}</p>
                </div>
                <div className="form__item">
                    <span>İstehsal tarixi</span>
                    <input type='text' minLength={4} maxLength={4} {...register("year")} />
                    <p>{errors.year?.message}</p>
                </div>
                <div className="form__item">
                    <span>Vİn kod</span>
                    <input type="text"  {...register('vin_code')} />
                    <p>{errors.vin_code?.message}</p>
                </div>
                <div className="form__item">
                    <span>Ehtiyyat hissəsinin adı</span>
                    <input type="text"  {...register('product_name')} />
                    <p>{errors.product_name?.message}</p>
                </div>
                {/* <div className="form__item">
                    <span>Məhsulun kodu</span>
                    <input type="text" {...register("product_code")} />
                    <p>{errors.product_code?.message}</p>
                </div> */}
                <div className={`form__item ${styles.textarea_item}`}>
                    <span>Məhsulun təsviri</span>
                    <textarea rows="3" cols="20" {...register("description")} />
                    <p>{errors.description?.message}</p>
                </div>


                <div className={`form__item ${styles.person__form}`}>
                    <h5>Sifariş edən şəxsin məlumatları </h5>
                    <div className="form__item">
                        <span>Ad, Soyad</span>
                        <input type="text"  {...register('fullname')} />
                        <p>{errors.fullname?.message}</p>
                    </div>
                    <div className="form__item">
                        <span>Email</span>
                        <input type="email" {...register('email')} />
                        <p>{errors.email?.message}</p>
                    </div>
                    <div className="form__item">
                        <span>Telefon</span>
                        <InputMask mask="(999) 999-99-99" placeholder="(050) 333-xx-xx" {...register('phone')} />
                        <p>{errors.phone?.message}</p>
                    </div>

                </div>
                {successMessage && <h4 className="successText"> {successMessage}</h4>}
                <div className={styles.btnFlex_end}>
                    <button type="submit" className={`btn btn__primary ${styles.send__btn}`} >
                        {loading ? (<>Göndərilir &nbsp;	 <i className="fas fa-circle-notch fa-spin"></i></>) : "Göndər"}
                    </button>
                </div>
            </form>
        </Modal>
    );
};





