import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import CountUp from 'react-countup';
import { useAutoComplete, useProductCount } from '../../hooks/query/products';
import classes from "./footer.module.css"
import logo from "../../assets/images/foot-log.png"
import { FaFacebook, FaLinkedinIn, FaTiktok } from "react-icons/fa"
import { BsInstagram } from "react-icons/bs"

const Footer = () => {
    const { data } = useProductCount()
    const formatNumber = useCallback((value) => {
        const digits = value.toString().split("");
        const formattedDigits = digits.map((digit, index) => (
            `<span style="background:linear-gradient(180.77deg, #434242 24.2%, #8A8A8A 151.61%); ">${digit}</span>`
        ));

        return formattedDigits;
    }, []);
    return (
        <footer>
            <section className={`container ${classes.foot}`}>
                <div className={`${classes.footIcon_logo}`}>
                    <Link to="/" className={`${classes.footlogo}`}> <img src={logo} alt="Logo" /></Link>
                    <div className={`${classes.footIcon} ${classes.mobil__foot__icon}`}>
                        <p>Bizi izləyin: </p>
                        <ul >
                            <Link to="https://www.facebook.com/avtopro.az" target='_blank'> <FaFacebook /></Link>
                            <Link to="https://www.instagram.com/avtopro_az/" target='_blank'> <BsInstagram /> </Link>
                            <Link to="https://www.linkedin.com/company/avtoproaz/" target='_blank'> <FaLinkedinIn /></Link>
                            <Link to=" http://www.tiktok.com/@avtopro.az" target='_blank'> <FaTiktok /></Link>

                        </ul>
                    </div>
                </div>
                <div className={classes.countUp}>
                    <CountUp
                        start={0}
                        end={data?.data?.count}
                        separator=""
                        duration={3}
                        formattingFn={formatNumber}
                    />
                </div>
                <div className={classes.socialIcons}>
                    <p><Link to="tel:+994557380013" ><span>Əlaqə nömrəsi:</span> +994 55 738 00 13</Link></p>
                    <p><Link to="mailto: info@avtopro.az" ><span>E-poçt:</span> info@avtopro.az</Link></p>
                    <div className={classes.footIcon}>
                        <p>Bizi izləyin:</p>
                        <ul >
                            <Link to="https://www.facebook.com/avtopro.az" target='_blank'> <FaFacebook /></Link>
                            <Link to="https://www.instagram.com/avtopro_az/" target='_blank'> <BsInstagram /> </Link>
                            <Link to="https://www.linkedin.com/company/avtoproaz/" target='_blank'> <FaLinkedinIn /></Link>
                            <Link to="http://www.tiktok.com/@avtopro.az" target='_blank'> <FaTiktok /></Link>
                        </ul>
                    </div>
                </div>
            </section>
            <section className={classes.copyright}>
                <div className={`container ${classes.copyright__container}`}>
                    <p>Avtopro.az ©2023 Avtopro.az,  All Rights Reserved.</p>
                    <ul >
                        <li>
                            <Link to='/footer_privacy'>Məxfilik siyasəti</Link>
                        </li>
                        <li style={{ textAlign: "right" }}>
                            <Link to='/footer_terms'>Şərtlər və qaydalar</Link>
                        </li>

                        <li >
                            <Link to='/footer_about' >Haqqımızda</Link>
                        </li>
                        <li style={{ textAlign: "right" }}>
                            <Link to='/footer_help_center' > Tez-tez verilən suallar</Link>
                        </li>
                    </ul>
                </div>
            </section>
        </footer>
    )
}

export default Footer