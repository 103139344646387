import React from 'react'
import classes from "./partner.module.css"
import p1 from "../../../assets/images/partners/ppUniversalgroup.png"
import p2 from "../../../assets/images/partners/ppMercedes.png"
import p3 from "../../../assets/images/partners/ppKapital.png"
import p4 from "../../../assets/images/partners/ppPremiumbank.png"
import p5 from "../../../assets/images/partners/ppPorsche.png"
import p6 from "../../../assets/images/partners/pp6.png"
import p7 from "../../../assets/images/partners/ppToyota.png"
import p8 from "../../../assets/images/partners/ppSkoda.png"
import p9 from "../../../assets/images/partners/pp9.png"

import p10 from "../../../assets/images/partners/ppAbarth.svg"
import p11 from "../../../assets/images/partners/ppAlfaRomeo.svg"
import p12 from "../../../assets/images/partners/ppFiat.svg"
import p13 from "../../../assets/images/partners/ppHonda.svg"
import p14 from "../../../assets/images/partners/ppSubaru.svg"
import p15 from "../../../assets/images/partners/ppValvoline.png"
import p16 from "../../../assets/images/partners/ppDodge.png"
import p17 from "../../../assets/images/partners/ppMazda.png"
import p18 from "../../../assets/images/partners/ppChryaler.png"
import p19 from "../../../assets/images/partners/ppAvtodetal.png"

import Slider from "react-slick";

const partners = [p1, p2, p3, p4, p5, p6, p7, p8, p9, p10, p11, p12, p13, p14, p15, p16, p17, p18, p19];



const Partner = () => {
  return (
    <section className={`container ${classes.partner__container}`}>
      <h3 className='text-title'>Partnyorlar</h3>
      {/* <div className={` ${classes.partner}`}>
            {
                partners.map((item,i)=>(
                    <div key={i} className={classes.partnerImg}>
                     <img src={item} alt=''/>
                    </div>
                ))
            }
        </div> */}
      <Slider {...settings}>
        {
          partners.map((item, i) => (
            <div key={i} className={classes.partnerImg}>
              <img src={item} alt="Partnyorlar" />
            </div>
          ))
        }
      </Slider>
    </section>
  )
}

export default Partner


var settings = {
  // dots: true,
  infinite: true,
  swipeToSlide: true,
  autoplay: true,
  speed: 1800,
  autoplaySpeed: 2200,
  slidesToShow: 6,
  slidesToScroll: 1,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
        infinite: true,
      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: true,
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      }
    },

  ]
};