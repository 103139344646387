import React, { memo, useState } from 'react'
import { GrYoutube } from 'react-icons/gr'
import { Link } from 'react-router-dom'
import classes from "../../../assets/styles/shopInfo.module.css"
import adclasses from "./shopInfoUpdate.css"
import { IoLogoFacebook } from "react-icons/io"
import { FaInstagramSquare, FaWhatsappSquare } from "react-icons/fa";
import { BsTelephoneFill } from "react-icons/bs"
import { TiLocation } from 'react-icons/ti'
import { TbClockHour4, TbWorld } from 'react-icons/tb'
import {
  ShopSocialFb, ShopSocialInsta, ShopSocialLinkedn, ShopSocialWp, ShopTel, ShopLoc, ShopWeb, ShopWork
} from '../../../assets/icons/Icons';

const Shopinfo = ({ data, website, workTime }) => {
  const image_url = 'https://s3.eu-central-1.amazonaws.com/uploads.avtopro.general/storage/' + data?.data?.logo;
  let websiteLink
  if(website != null) {
    websiteLink = `https://${website}`
  } else {
    websiteLink = ''
  }
  const [isExpanded, setIsExpanded] = useState(false);
  const [isShowabout, setIsShowabout] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };
  const handleToggleAbout = () => {
    setIsShowabout(!isShowabout);
  };
  return (
    <section className={`container ${classes.shop__container} ${classes.autosalon_container}`}>


      {isShowabout ? (
        <div className={`${classes.desc_row}`}>
          <div className={classes.item__left}>
            <div className={`${classes.auto_logo}`}>
              <h3>{data?.data?.name} </h3>
              <div className={`${classes.auto_logo_crop}`}>
                <img src={image_url} alt={data?.data?.name} />
              </div>
            </div>
            {/* <Link className={classes.subscribe} to="/video"> <GrYoutube color="#FE0000" fontSize="2.2rem" /> Abunə ol</Link>
            <div className={classes.icons}>
              <Link to={`${data?.data?.shop_information[0]?.facebook ?? "https://www.facebook.com"}`} target='_blank'> {ShopSocialFb}</Link>
              <Link to={`${data?.data?.shop_information[0]?.instagram ?? "https://www.instagram.com/"}`} target='_blank'> {ShopSocialInsta}</Link>
              <Link to={`${data?.data?.shop_information[0]?.linkedn ?? "https://www.linkedin.com/"}`} target='_blank'> {ShopSocialLinkedn}</Link>
              <Link to={`https://api.whatsapp.com/send?phone=${data?.data?.shop_information[0]?.phone1?.replace(/\D/g, '')}`} target='_blank'> {ShopSocialWp}</Link>
            </div> */}
          </div>

          <div className={`${classes.desc_flex}`}>

            <div className={`${classes.show_about}`} onClick={handleToggleAbout}>
              <span className={classes.back_content}></span>
            </div>
            <div className={`${classes.description_content} container showDesktopFilter`}>
              {/* <h3 className={classes.shop_name_hd}>{data?.data?.name}</h3> */}
              <div
                className={`${classes.desc_info} ${isExpanded ? classes.expanded : ''}`}
                dangerouslySetInnerHTML={{ __html: data?.data?.information ? `${data?.data?.information}` : "" }}
              ></div>
              <div className={`${classes.more_text_cnt} ${isExpanded ? classes.clicked : ''}`}>
                <span onClick={handleToggle}>Daha çox</span>
              </div>
            </div>

          </div>
        </div>
      ) : (
        <div className={`${classes.shop__info} ${isExpanded ? classes.show_adrs : ''}`}>

          <div className={classes.item} >
            <div className={classes.item__left}>
              <div className={`${classes.auto_logo}`}>
                <h3>{data?.data?.name} </h3>
                <div className={`${classes.auto_logo_crop}`}>
                  <img src={image_url} alt={data?.data?.name} />
                </div>
              </div>
              {/* <Link className={classes.subscribe} to="/video"> <GrYoutube color="#FE0000" fontSize="2.2rem" /> Abunə ol</Link>
            <div className={classes.icons}>
              <Link to={`${data?.data?.shop_information[0]?.facebook ?? "https://www.facebook.com"}`} target='_blank'> {ShopSocialFb}</Link>
              <Link to={`${data?.data?.shop_information[0]?.instagram ?? "https://www.instagram.com/"}`} target='_blank'> {ShopSocialInsta}</Link>
              <Link to={`${data?.data?.shop_information[0]?.linkedn ?? "https://www.linkedin.com/"}`} target='_blank'> {ShopSocialLinkedn}</Link>
              <Link to={`https://api.whatsapp.com/send?phone=${data?.data?.shop_information[0]?.phone1?.replace(/\D/g, '')}`} target='_blank'> {ShopSocialWp}</Link>
            </div> */}
            </div>
            <div className={`${classes.item__right}`}>
              <p>
                <Link to={`tel:+994 ${data?.data?.shop_information[0]?.phone1}`}>
                  <BsTelephoneFill /> {data?.data?.shop_information[0]?.phone1} </Link>
              </p>
              {data?.data?.shop_information[0]?.phone2 &&
                <p>
                  <Link to={`tel:+994 ${data?.data?.shop_information[0]?.phone2}`}>
                    <BsTelephoneFill />{data?.data?.shop_information[0]?.phone2}
                  </Link>
                </p>}
              {/* <p><Link > {ShopLoc}{data?.data?.shop_information[0]?.address}</Link></p> */}
              <div className={classes.hide_items}>
                {data?.data?.shop_information?.map((shop, index) => (
                  <p key={index}>
                    <Link>{ShopLoc}{shop?.address}</Link>
                  </p>
                ))}
              </div>

            </div>
          </div>

          <div className={`${classes.lineBorder}`}></div>
          <div className={`${classes.item} ${classes.item2}`}>

            <div className={classes.item__left}>
              <p>
                <Link to={websiteLink}>{ShopWeb} {website ? website : "-----"}</Link>
              </p>
              {/* <p><Link>{ShopWork}  {workTime ? workTime : "-----"}</Link></p> */}

              <div className={classes.hide_items}>
                {data?.data?.shop_information?.map((shop, index) => (
                  <p key={index}>
                    <Link>{ShopWork} {shop?.work_time}</Link>
                  </p>
                ))}
              </div>
            </div>

            {/* <div className={classes.item__right}>
            <Link className={classes.service}>Servis xidməti <BsArrowRight /></Link>
          </div> */}
            <div className={`${classes.show_about}`} onClick={handleToggleAbout}>
              <span className={classes.full_content}>Haqqında</span>
            </div>


          </div>

        </div>
      )}

      {isShowabout ? null : (
        <div className={classes.more_text}>
          <span onClick={handleToggle} >Digər ünvanları gör</span>
        </div>
      )}
      {console.log('') || null}

    </section >
  )
}

export default memo(Shopinfo)
