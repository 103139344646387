import React from "react";
import { Link } from "react-router-dom";
import classes from "./boxes.module.css";
import { BiChevronRight } from "react-icons/bi";

const Boxes = (props) => {
    const { shopbox, name, image, onClickLink, onClick, productCount } = props;

    const handleBoxCategoryName = (itemName) => {
        localStorage.setItem('catgName', itemName);
    };

    return (
        <article className={`${classes.box} box_item`}>
            {shopbox && <p className={classes.box__count}> {productCount && <span>{productCount} </span>} məhsul </p>}
            <Link to={onClickLink} onClick={() => handleBoxCategoryName(name)}> <img src={image} alt={name} /></Link>
            {onClickLink ? (
                <Link to={onClickLink} className={classes.box__link} onClick={() => handleBoxCategoryName(name)}>
                    {name} <BiChevronRight className={classes.rightIcon} />
                </Link>
            ) : (
                <p className={classes.box__link} onClick={() => handleBoxCategoryName(name)}>
                    {name} <BiChevronRight className={`${classes.rightIcon} rightIconMobile`} />
                </p>
            )}
        </article>
    );
};

export default Boxes;
