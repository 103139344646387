import React, { useEffect, useState } from "react";
import Meta from "../../components/ui/meta";
import Menu from "../../components/ui/menu";
import Breadcrumb from "../../components/ui/breadcrumb/breadcrumb";
import Filter from "../../components/ui/filter/filter";
import Product from "../../components/product/product";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Mobilfilter from "../../components/ui/mobilfilter/mobilfilter";
import { getProducts } from "../../services/productService";
import { useInfiniteQuery } from "@tanstack/react-query";


const ProductResult = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [orderSelectValue] = useState()
  const [catgName, setCatgName] = useState('')

  const params = {
    paginate: 30,
    page: searchParams.get("page") ?? 1,
    car_make_id: searchParams.get("car_make_id") ?? 0,
    car_model_id: searchParams.get("car_model_id") ?? 0,
    car_generation_id: searchParams.get("car_generation_id") ?? 0
  }

  if(searchParams.get("category_id")) params["category_id"] = searchParams.get("category_id");
  if(searchParams.get("search")) params["search"] = searchParams.get("search");
  if(searchParams.get("product_number")) params["product_number"] = searchParams.get("product_number");
  if(searchParams.get("order")) params["order"] = searchParams.get("order");
  if(searchParams.get("direction")) params["direction"] = searchParams.get("direction");
  if(searchParams.get("shop_id")) params["shop_id"] = searchParams.get("shop_id");
  if(searchParams.get("shop_type")) params["shop_type"] = searchParams.get("shop_type");


  const {
    data,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    ['products_all', params],
    ({ pageParam = 1 }) => getProducts({ ...params, page: pageParam }),
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages.length + 1
        return lastPage?.data?.length !== 0 ? nextPage : undefined
      },
    }
  );

  const page = {
    pages: [
      {
        link: "/products?page=1",
        content: "Məhsullar",
      }
    ]
  };
  const handleOrder = (e) => {
    const queryParams = Object.fromEntries(searchParams.entries());
    switch(e.value) {
      case 1:
        queryParams.order = "id";
        queryParams.direction = "desc";

        break;
      case 2:
        queryParams.order = "price";
        queryParams.direction = "desc";
        break;
      case 3:
        queryParams.order = "price";
        queryParams.direction = "asc";
        break;
      case 4:
        delete queryParams.order;
        delete queryParams.direction;
        queryParams.shop_type = 2;
        break;
      case 5:
        delete queryParams.order;
        delete queryParams.direction;
        queryParams.shop_type = 1;
        break;
      default:
        queryParams.order = "id";
        queryParams.direction = "desc";
        break;
    }

    const updatedSearchParams = new URLSearchParams(queryParams);
    navigate(`${location.pathname}?${updatedSearchParams.toString()}`);
  }


  useEffect(() => {
    const currentCatgName = localStorage.getItem('catgName');
    setCatgName(currentCatgName);
  }, [searchParams]);


  const currentUrl = window.location.href;
  const title = `Kategoriyalar - ${catgName} | avtopro.az`
  const keywords = `${catgName}, kategoriyalar, zapcast, avtomobil, nəqliyyat, nəqliyyat vasitəsi, avtopro, aksesuar, təkərlər, əyləc bəndi, yağlar`
  const description = `${(catgName ? catgName : "") + ' - nəqliyyat vasitələrinə aid hər növ ehtiyyat hissələri və aksesuarların satışı| avtopro.az'}`;

  return (
    <>
      <Meta title={title} keywords={keywords} description={description} currentUrl={currentUrl} />
      <Menu />
      <Breadcrumb page={page} />
      <section className="products__container">
        <Mobilfilter categoryId={searchParams.get("shop_type") ?? 0} />
        <Filter categoryId={searchParams.get("shop_type") ?? 0} />

        <Product
          end="end"
          orderSelectValue={orderSelectValue}
          handleOrder={handleOrder}
          data={data}
          isLoading={isLoading}
          error
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          isFetchingNextPage={isFetchingNextPage}
        />
      </section>
    </>
  );
};

export default ProductResult;


