import React, { useState } from 'react'
import classes from "./service.module.css"
import { Special, OnlineStore, Services, ServiceAuto, ServiceRepair } from '../../../assets/icons/Icons'
import { ModalNav } from '../../../layouts/navbar/modalNavbar/modalNav';
import { ModalOrder } from './modalorder';
import { Link } from 'react-router-dom';

const Service = () => {

  const [isModalOpenNav, setIsModalOpenNav] = useState(false);
  const openModal = () => {
    setIsModalOpenNav(true);
  };

  const [isModalOpenOrder, setIsModalOpenOrder] = useState(false);

  const openModalOrder = () => {
    setIsModalOpenOrder(true);
  };


  return (
    <>
      <section className={`container ${classes.service__container}`}>
        <h3 className='text-title'> Xidmətlər / Yeniliklər </h3>
        <div className={classes.service}>

          {/* <article  style={{cursor:"pointer"}}>
            <Link to="/orderabroad" className={classes.service__icon}> {Special}</Link>
            <p>Xüsusi sifariş et</p>
          </article> */}
          {/* <article>
            <div> {Insurance}</div>
            <p>Sığorta kalkulyator</p>
          </article> */}

          <article onClick={openModalOrder} style={{ cursor: "pointer" }}>
            <Link className={classes.service__icon}> {Special}</Link>
            <p>Xüsusi sifariş et</p>
          </article>
          <article onClick={openModalOrder} style={{ cursor: "pointer" }}>
            <Link to="/ustalar" className={classes.service__icon}> {ServiceRepair}</Link>
            <p>Ustalar</p>
          </article>
          <article onClick={openModalOrder} style={{ cursor: "pointer" }}>
            <Link to="/services" className={classes.service__icon}> {Services}</Link>
            <p>Servislər</p>
          </article>
          <article onClick={openModal} style={{ cursor: "pointer" }}>
            <div className={classes.service__icon}> {OnlineStore}</div>
            <p>Online mağaza yarat</p>
          </article>
          <article onClick={openModalOrder} style={{ cursor: "pointer" }}>
            <Link to="/cardealers" className={classes.service__icon}> {ServiceAuto}</Link>
            <p>Avtosalonlar</p>
          </article>
        </div>
      </section>
      {isModalOpenNav && <ModalNav isModalOpenNav={isModalOpenNav} setIsModalOpenNav={setIsModalOpenNav} />}
      {isModalOpenOrder && <ModalOrder isModalOpenOrder={isModalOpenOrder} setIsModalOpenOrder={setIsModalOpenOrder} />}
    </>


  )
}

export default Service