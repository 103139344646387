import { Helmet } from "react-helmet";
import React from "react";
import image from "../../assets/images/icontitle.png"

const Meta = (props) => {
  const { data, shopName, siteURL, goShop, currentUrl, shopLogo, shopAddress, pageLink, resultPhone, isDetailPage, isShopinnerPage, isDealerinnerPage, isCarnumPage } = props;

  const scriptContentDetail = isDetailPage ? `
    {
      "@context": "https://schema.org",
      "@type": "Product",
      "description": "${data?.data?.product_name} - məhsulun satışı avtopro.az'da",
      "name": "${data?.data?.product_name}",
      "image": "${image}",
      "offers": {
        "@type": "Offer",
        "availability": "1",
        "price": "${data?.data?.price}",
        "priceCurrency": "AZN"
      }
    }
  ` : null;
  const scriptContentCarnumber = isCarnumPage ? `
    {
      "@context": "https://schema.org",
      "@type": "Product",
      "description": "${shopName} - məhsulun satışı avtopro.az'da",
      "name": "${shopName}",
      "image": "${image}",
      "offers": {
        "@type": "Offer",
        "availability": "1",
        "price": "${data?.data?.price}",
        "priceCurrency": "AZN"
      }
    }
  ` : null;
  const scriptContentShopinner = isShopinnerPage ? `
    {
      "@context": "https://schema.org",
      "@type": "Organization",
      "name": "${data?.data?.name}",
      "url": "${siteURL + pageLink}",
      "logo": "${shopLogo}",
      "description": "${data?.data?.name} - mağazada məhsulların satışı avtopro.az'da",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "${shopAddress}",
        "addressLocality": "-",
        "addressRegion": "-",
        "postalCode": "-",
        "addressCountry": "Azerbaijan"
      },
      "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "${resultPhone}",
        "contactType": "customer service"
      }
    }
  ` : null;


  const scriptContentDealerinner = isDealerinnerPage ? `
    {
      "@context": "https://schema.org",
      "@type": "Organization",
      "name": "${data?.data?.name}",
      "url": "${siteURL + pageLink}",
      "logo": "${shopLogo}",
      "description": "${data?.data?.name} - mağazada məhsulların satışı avtopro.az'da",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "${shopAddress}",
        "addressLocality": "-",
        "addressRegion": "-",
        "postalCode": "-",
        "addressCountry": "Azerbaijan"
      },
      "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "${resultPhone}",
        "contactType": "customer service"
      }
    }
  ` : null;


  return (
    <Helmet>

      <title>{props.title}</title>
      <link rel="icon" type="image/png" href={image} sizes="16x16" />
      <link rel="canonical" href={currentUrl} />

      <meta name='og:title' content={props.title} />
      <meta name="author" content="Avtopro" />
      <meta name="robots" content="index, follow" />

      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="Avtopro.az" />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:creator" content="@avtoproaz" />
      <meta property="twitter:image" content={props.image ? props.image : image} />

      <meta property="og:title" content={props.title} />
      <meta property="twitter:title" content={props.title} />
      <meta property="description" content={props.description} />
      <meta property="twitter:description" content={props.description} />
      <meta property="og:url" content={window.location.href} />

      <meta name='keywords' content={props.keywords} />
      <meta name='description' content={props.description} />
      <meta name='og:image' content={props.image ? props.image : image} />


      {isDetailPage && scriptContentDetail && (
        <script type="application/ld+json">
          {scriptContentDetail}
        </script>
      )}
      {isCarnumPage && scriptContentCarnumber && (
        <script type="application/ld+json">
          {scriptContentCarnumber}
        </script>
      )}
      {isShopinnerPage && scriptContentShopinner && (
        <script type="application/ld+json">
          {scriptContentShopinner}
        </script>
      )}
      {isDealerinnerPage && scriptContentDealerinner && (
        <script type="application/ld+json">
          {scriptContentDealerinner}
        </script>
      )}

      {/* <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "Product",
            "description": "${data?.data?.product_name} - məhsulun satışı avtopro.az'da",
            "name": "${data?.data?.product_name}",
            "image": "${image}",
            "offers": {
              "@type": "Offer",
              "availability": "1",
              "price": "${data?.data?.price}",
              "priceCurrency": "AZN"
            },
            "seller": {
              "@type": "Organization",
              "name": "${shopName}",
              "url": "${siteURL + goShop}",
              "logo": "${shopLogo}",
              "description": "${shopName} - məhsulun satışı avtopro.az'da"
            }
          }
        `}
      </script> */}


    </Helmet>
  );
};

export default Meta;