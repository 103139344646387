import * as yup from "yup";

const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
const maxSize = 2 * 1024 * 1024;

export const schema = yup.object().shape({
    // shop_type: yup.number().oneOf([3], 'Shop type must be 3').required(),
    shop_type: yup.number().required(),
    shopName: yup.string().required(),
    address: yup.string().required(),
    city_id: yup.string().required(),
    relevantPerson: yup.string().required(),
    email: yup.string().email().required(),
    phone: yup.string()
        .required()
        .matches(/^\(\d{3}\) \d{3}-\d{2}-\d{2}$/, "10 rəqəm olmalı")
        .min(10, '10 simvol olmalı')
        .max(16, ' 10 simvol olmalı'),
    work_time: yup.string().required(),
    logo: yup
        .mixed()
        .nullable()
        .test("fileSize", "Logo 2mb artıq ola bilməz", (value) => {
            if(value && value[0]) return value[0].size <= maxSize;

            return true;
        })
        .test("fileType", "Logo düzgün formatda deyil(jpg, jpeg və ya png olmalıdır)", (value) => {
            if(value && value[0]) return allowedTypes.includes(value[0].type);

            return true;
        }),
});
