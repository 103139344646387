import React from 'react'
import { svgCancel } from '../../assets/icons/Icons'
import "./order.css"
import Meta from '../../components/ui/meta';

const PaymentCancel = () => {

  const currentUrl = window.location.href;
  const title = "Avtopro - Ödəniş ləğv edildi | avtopro.az";
  const keywords = "ödəniş, avtomobil, balans, razval, xodovoy təmiri, mühərrik təmiri, yağlama, çilingər, elektrik, diaqnostika, fara təmiri, dəmirci"
  const description = "Nəqliyyat vasitələrinə aid hər növ ehtiyyat hissələri və aksesuarların satışı avtopro.az'da";

  return (
    <>
      <Meta title={title} keywords={keywords} description={description} currentUrl={currentUrl} />
      <section className={`container order__container`}>
        <div className='order__item'>
          <p>{svgCancel}</p>
          <h3>Ödəniş ləğv edildi</h3>
        </div>
      </section>
    </>
  )
}

export default PaymentCancel