import React, { useEffect } from 'react'
import { useSimilarProductsSalon } from '../../../hooks/query/products'
import classes from "./similar.module.css"
import Card from '../../../components/ui/cardauto/card';
import SkeletonPost from '../../../components/skletons/SkeletonPost';

const SimilarProduct = (props) => {
    const { category_id, product_id } = props;
    const { data: cars, refetch, isFetching, isStale } = useSimilarProductsSalon({
        limit: 12,
        shop_id: category_id,
        difference_id: product_id
    });
    console.log(props);
    useEffect(() => {
        if(category_id && isStale && product_id) refetch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [category_id, product_id])

    return (
        <section className={`container ${classes.products}`}>
            {/* <h3>Oxşar  məhsullar</h3> */}
            <div className={classes.products__container}>
                {isFetching && [...Array(12).keys()].map(i => {
                    return <SkeletonPost key={i} />
                })}
                {cars?.data?.map((card) => <Card key={card.id} card={card} />)}
            </div>
        </section>
    )
}

export default SimilarProduct