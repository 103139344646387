import request from "../helpers/api/request";

export const getSalons = async (query_params = []) => {
    const params = new URLSearchParams(query_params);
    const queryString = "?" + params.toString();
    const response = await request.get(`/shops${queryString}`);

    return response.data;
};
export const getSalon = async (id) => {
    const response = await request.get(`/shops/${id}`);
    return response.data;
};
export const getShops = async (query_params = []) => {
    const params = new URLSearchParams(query_params);
    const queryString = "?" + params.toString();
    const response = await request.get(`/shops${queryString}`);

    return response.data;
};

export const getShop = async (id) => {
    const response = await request.get(`/shops/${id}`);
    return response.data;
};

export const createShop = async (data) => {
    const response = await request.post(`/shops`, data);

    return response.data;
};


export const mapShop = async () => {
    const response = await request.get(`/shops_maps`);

    return response.data;
};
