import React from 'react'
import Soon from '../../components/soon'
import Menu from '../../components/ui/menu'

const OrderFromAbroad = () => {
  return (
    <>
        <Menu/>
        <Soon/>   
    </>
  )
}

export default OrderFromAbroad