import * as yup from "yup";

export const schema = yup.object().shape({
    // product_code:yup.string(),
    type: yup.string().required(),
    make: yup.string().required(),
    model: yup.string().required(),
    vin_code: yup.string().required(),
    product_name:yup.string().required(),
    year:yup.string().required(),
    description:yup.string().required(),
    fullname:yup.string().required(),
    email:yup.string().required(),
    phone: yup.string()
    .required()
    .matches(/^\(\d{3}\) \d{3}-\d{2}-\d{2}$/, "10 rəqəm olmalı")
    .min(10, '10 simvol olmalı')
    .max(16, ' 10 simvol olmalı'),
});
