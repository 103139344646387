import * as yup from "yup";

const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
const maxSize = 2 * 1024 * 1024;

export const schema = yup.object().shape({
    name: yup.string().required('Ad sahəsi boş qala bilməz'),
    number: yup.string()
        .required('Nömrə sahəsi boş qala bilməz')
        .matches(/^[0-9]+$/, "rəqəm olmalı")
        .min(10, '10 simvol olmalı')
        .max(10, ' 10 simvol olmalı'),
    surname: yup.string().required('Soyad sahəsi boş qala bilməz'),
    address: yup.string().required('Ünvan sahəsi boş qala bilməz'),
    city_id: yup.number().required('Şəhər sahəsi boş qala bilməz'),
    experience: yup.string().required('Təcrübə sahəsi boş qala bilməz'),
    // comment: yup.string().required(),
    // description: yup.string().required(),
    photo: yup
        .mixed()
        .nullable()
        .test("fileSize", "Logo 2mb artıq ola bilməz", function (value) {
            if(value && value[0]) return value[0].size <= maxSize;

            return true;
        })
        .test("fileType", "Logo düzgün formatda deyil(jpg, jpeg və ya png olmalıdır)", function (value) {
            if(value && value[0]) return allowedTypes.includes(value[0].type);

            return true;
        }),
    // position_category_id: yup.array()
    //     .of(
    //         yup.object().shape({
    //             value: yup.number().required(),
    //             label: yup.string().required(),
    //         })
    //     )
    //     .min(1, "En az bir kategori seçilmelidir."),

    // position_category_id: yup.array()
    //     .of(
    //         yup.array()
    //             .of(
    //                 yup.object().shape({
    //                     value: yup.number().required('Marka seçilmelidir.'),
    //                     label: yup.string().required('Marka seçilmelidir.'),
    //                 })
    //             )
    //     )
    //     .min(1, "En az bir marka seçilmelidir."),
    // car_makes: yup.array()
    //     .of(
    //         yup.array()
    //             .of(
    //                 yup.object().shape({
    //                     value: yup.number().required(),
    //                     label: yup.string().required(),
    //                 })
    //             )
    //     )
    //     .min(1, "En az bir marka seçilmelidir."),

    // position_category_id: yup.array().min(1, "sec en az bir categry."),
    // position_category_id: yup.number().required(),
    // position_category_id: yup.array()
    //     .test("notEmpty", "En az bir kategori seçilmelidir.", function (value) {
    //         const path = this.path;
    //         if(!value || value.length === 0) {
    //             return this.createError({
    //                 message: "En az bir kategori seçilmelidir.",
    //                 path: path,
    //             });
    //         }
    //         return true;
    //     }),

    // car_makes: yup.array()
    //     .test("notEmpty", "En az bir marka seçilmelidir.", function (value) {
    //         const path = this.path;
    //         if(!value || value.length === 0) {
    //             return this.createError({
    //                 message: "En az bir marka seçilmelidir.",
    //                 path: path,
    //             });
    //         }
    //         return true;
    //     }),
});
