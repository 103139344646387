import request from "../helpers/api/request";

export const getRegisterNumber = async (query_params = {}) => {
    const params = new URLSearchParams(query_params);
    const queryString = "?" + params.toString();
    const response = await request.get(`/register_numbers${queryString}`);

    return response.data;
}

export const getNumber = async (id) => {
    const response = await request.get(`/register_numbers/${id}`);
    return response.data;
};