import request from "../helpers/api/request";

export const getCarGenerations = async (query_params = {}) => {
    const params = new URLSearchParams(query_params);
    const queryString = "?" + params.toString();
    const response = await request.get(`/car_generations${queryString}`);

    console.log(response);
    console.log("data Generations");

    return response.data;
};

export const getCarSalonGenerations = async (query_params = {}) => {
    const params = new URLSearchParams(query_params);
    const queryString = "?" + params.toString();
    const response = await request.get(`/car/cars${queryString}`);
    console.log(response);
    console.log(queryString);
    console.log("response getCarSalonGenerations");

    return response.data;
};
