import React, { useCallback, useEffect, useState } from 'react'
import Breadcrumb from '../../components/ui/breadcrumb/breadcrumb';
import "./repairer.css"
import Menu from "../../components/ui/menu/index"
import ServiceCard from '../../components/serviceCard';
import Select from 'react-select';
import { FiChevronDown } from "react-icons/fi"
import { FiChevronUp } from "react-icons/fi"
import { Link, useSearchParams } from "react-router-dom";
import Meta from '../../components/ui/meta';
import { usePositionCategory } from '../../hooks/query/repairers';
import { useCities } from '../../hooks/query/cities';
import { usePopularCarMakes } from '../../hooks/query/carMake';
import { CustomStyles } from "../../helpers/contants/s3";
import { getRepairers } from '../../services/repairerService';
import { useInfiniteQuery } from '@tanstack/react-query';


const Repairer = () => {

  const [visible, setVisible] = useState(true)
  const [showFilter, setShowFilter] = useState(true)
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [cityId, setCityId] = useState(searchParams.get("city_id") ?? 0);
  const [carMakeId, setCarMakeId] = useState(searchParams.get("car_make_id") ?? 0);
  const [name, setName] = useState("");
  const [total, setTotal] = useState(0);
  const [totalProduct, setTotalProduct] = useState(0);
  const [showTotalProduct, setShowTotalProduct] = useState(true);


  const params = {
    paginate: 30,
    city_id: cityId,
    car_make_id: carMakeId,
    position_category_id: selectedCategories ?? 0,
    name: name,
  }

  const {
    data,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery(
    ['getrepaierers', params],
    ({ pageParam = 1 }) => getRepairers({ ...params, page: pageParam }),
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages.length + 1
        return lastPage?.data?.length !== 0 ? nextPage : undefined
      },
    }
  )
  useEffect(() => {
    refetch();
  }, [name]);

  useEffect(() => {
    let fetching = false;

    const handleScroll = async (event) => {
      const { scrollHeight, scrollTop, clientHeight } = event.target.scrollingElement;

      if(!fetching && scrollHeight - scrollTop <= clientHeight * 1.2 && hasNextPage) {
        fetching = true;
        const newData = await fetchNextPage(); // This calls the API to fetch the next page.

        const fetchDataTrue = newData?.data?.pages?.map((item) => item?.data?.data)
        const fetchData = fetchDataTrue?.find((item) => item?.length < 30)
        if(fetchData) {
          document.removeEventListener('scroll', handleScroll);
        }

        fetching = false;
      }
    };

    document.addEventListener('scroll', handleScroll);
    return () => document.removeEventListener('scroll', handleScroll);
  }, [fetchNextPage, hasNextPage]);

  const { data: cities } = useCities()
  const { data: positionCategory } = usePositionCategory()
  const { data: popularCarMakes } = usePopularCarMakes()


  useEffect(() => {
    if(cityId && cityId !== 0) refetch();
  }, [cityId]);


  const handleFilter = useCallback(() => {
    setShowFilter(!showFilter)
  }, [showFilter])


  const citySelect = cities?.data?.map((city) => {
    return {
      value: city.id,
      label: city.name
    }
  })


  const [loading, setLoading] = useState(true); // Başlangıçta yükleniyor durumu

  useEffect(() => {
    if(popularCarMakes?.data) {
      setLoading(false); // Veri yüklendikten sonra loading false yapılır
    }
  }, [popularCarMakes]);

  const carMakes = Array.isArray(popularCarMakes?.data) ? popularCarMakes.data.map((car) => {
    return {
      value: car.id,
      label: car.name
    };
  }) : [];


  // const carMakes = popularCarMakes?.data?.map((city) => {
  //   return {
  //     value: city.id,
  //     label: city.name
  //   }
  // })


  const handleCheckboxChange = (e) => {
    const categoryId = parseInt(e.target.value);
    if(e.target.checked) {
      setSelectedCategories([categoryId]);
      searchParams.set("position_category_id", categoryId);
      setSearchParams(searchParams);
    } else {
      setSelectedCategories([]);
      searchParams.delete("position_category_id", categoryId);
      setSearchParams(searchParams);
    }

  }


  const handleCityChange = (e) => {
    setCityId(e.value)
    searchParams.set("city_id", e.value);
    setSearchParams(searchParams);
  }

  const handleCarMakeChange = (e) => {
    setCarMakeId(e.value)
    searchParams.set("car_make_id", e.value);
    setSearchParams(searchParams);
  }

  const dataProducts = data?.pages?.map((item) => item?.data);
  const totalProductItem = dataProducts?.map((item) => item?.total)[0];

  useEffect(() => {
    setTotal(totalProductItem); // totalProduct değiştiğinde total state'ini güncelle
  }, [totalProduct]);

  const handleOnChangeName = () => {
    setShowTotalProduct(false);
    const inputValue = document.getElementById('searchNameService').value;
    const newName = inputValue.trim();
    setName(newName);
    searchParams.set("name", newName);
    setSearchParams(searchParams);

    const filteredCards = data?.pages
      ?.flatMap((page) => page?.data?.data)
      ?.filter((card) =>
        card.name.toLowerCase().includes(newName.toLowerCase())
      );
    const totalProduct = filteredCards?.length || 0;
    setTotalProduct(totalProduct);
  };



  const page = {
    pages: [
      {
        link: "/ustalar",
        content: "Ustalar",
      }
    ],
  };


  // console.log(dataProductsName);

  const currentUrl = window.location.href;
  const title = "Avtopro - Ustalar | avtopro.az";
  const keywords = "ustalar, avtomobil təmiri, balans, razval, xodovoy təmiri, mühərrik təmiri, yağlama, çilingər, elektrik, diaqnostika, fara təmiri, dəmirci"
  const description = "Ustalar tərərfindən nəqliyyat vasitələrinə aid hər növ ehtiyyat hissələri və aksesuarların təmiri və yerləşdirilməsi avtopro.az'da";

  return (
    <>
      <Meta title={title} keywords={keywords} description={description} currentUrl={currentUrl} />
      <Menu />
      <section className='container__bg'>
        <Breadcrumb page={page} />
        <section className='service__container container' >

          <form className='service__left' >
            <h4 className='service__left__title'>Axtarış</h4>
            <div className='filterFlex'>
              <Link onClick={handleFilter} className={showFilter ? "activeA" : ""}>Ümumi axtarış</Link>
              {/* <Link onClick={handleFilter} className={!showFilter ? "activeA" : ""}>Usta adına görə axtar</Link> */}
            </div>
            {
              showFilter && <div className='service__item__select'>
                <Select
                  className="service__select"
                  placeholder={'Bütün şəhərlər '}
                  options={citySelect}
                  onChange={handleCityChange}
                  value={citySelect?.find((option) => option.value === cityId)}
                  styles={CustomStyles}
                />
                <Select
                  className="service__select"
                  placeholder={'Bütün markalar '}
                  options={carMakes}
                  onChange={handleCarMakeChange}
                  value={carMakes?.find((option) => option.value === carMakeId)}
                  styles={CustomStyles}
                />
              </div>
            }
            {
              showFilter && <><div className='service__category'>
                <h3 onClick={() => setVisible(!visible)}>Kateqoriya {visible ? <FiChevronUp /> : <FiChevronDown />}</h3>
                {
                  visible && <div className='service__category__filter'>
                    {
                      positionCategory?.data?.map((item) => (
                        <label key={item.id}>
                          <input
                            type="checkbox"
                            value={item.id}
                            checked={selectedCategories.includes(item.id)}
                            onChange={handleCheckboxChange}
                          />
                          <span></span>
                          {item.name}
                        </label>
                      ))
                    }
                  </div>
                }

              </div>
              </>

            }
            <input type="text" className='serviceFilter' id='searchNameService' placeholder='Usta adına görə axtar' />
            <div className='btn btn__primary serviceNameSearch' type='button' onClick={handleOnChangeName}>Axtar</div>
          </form>



          <div className='service__right'>
            <h4>Ustalar</h4>
            <div className='service__right__title'>
              <p><strong>{showTotalProduct ? totalProductItem : totalProduct}</strong> nəticə tapıldı</p>
              {/* <p><strong>{totalProduct} {totalProductItem}</strong> nəticə tapıldı</p> */}
              <Link to="/ustalar/qeydiyyat" className='register_link'>Usta qeydiyyatı <span className='rightArw'></span></Link>
            </div>
            <div className='service__cards'>
              {data?.pages?.map((page) =>
                page?.data?.data
                  ?.filter((card) =>
                    card.name.toLowerCase().includes(name.toLowerCase().split(' ').join(''))
                  )
                  .map((filteredCard) => (
                    <ServiceCard key={filteredCard.id} data={filteredCard} />
                  ))
              )}
            </div>



          </div>
        </section>
      </section>
    </>

  )
}

export default Repairer

