import request from "../helpers/api/request";

export const getCarModels = async (query_params = {}) => {
    const params = new URLSearchParams(query_params);
    const queryString = "?" + params.toString();
    const response = await request.get(`/car_models${queryString}`);


    console.log(response);
    console.log("data modelll");

    return response.data;
};

export const getCarSalonModels = async (query_params = {}) => {
    const params = new URLSearchParams(query_params);
    const queryString = "?" + params.toString();
    const response = await request.get(`/car/cars${queryString}`);
    console.log(response);
    console.log(queryString);
    console.log("data modelll getCarSalonModels");
    return response.data;
};
