// import React, { memo, useState } from "react";
// import { Link } from "react-router-dom";
// import classes from "./tabpanel.module.css";

// const Tabpanel = (props) => {
//     const { setCategoryId, data, brand, setBrandIndex, brandIndex } = props
//     const [currentIndex, setCurrentIndex] = useState(0);

//     const categoryClick = (id, index, slug) => {
//         setCurrentIndex(index);
//         setCategoryId(id);
//     };

//     const brands = ["Populyar markalar", "Bütün markalar"]

//     const handleBrand = (index) => {
//         setBrandIndex(index)
//     }

//     return (
//         <ul className={classes.tabs}>
//             {brand && brands.map((brand, index) => (
//                 <li key={index}>
//                     <Link
//                         className={index === brandIndex ? "activeTab" : ""}
//                         onClick={() => handleBrand(index)}
//                     >
//                         {brand}
//                     </Link>
//                 </li>
//             ))}
//             {data?.data?.map((category, index) => {
//                 return (
//                     <li key={category.id}>
//                         <Link
//                             className={index === currentIndex ? "activeTab" : ""}
//                             onClick={() => categoryClick(category.id, index, category.category_name)}
//                         >
//                             {category.category_name}
//                         </Link>
//                     </li>
//                 );
//             })}
//         </ul>
//     );
// };

// export default memo(Tabpanel);



import React, { memo, useState } from "react";
import { Link } from "react-router-dom";
import classes from "./tabpanel.module.css";

const Tabpanel = (props) => {
    const { setCategoryId, data, branddata, brand, setBrandIndex, brandIndex } = props
    const [currentIndex, setCurrentIndex] = useState(0);

    const categoryClick = (id, index, slug) => {
        setCurrentIndex(index);
        setCategoryId(id);
    };
    const brandClick = (id, index, countryName) => {
        setCurrentIndex(index);
        setBrandIndex(id);
        setActiveCountry(countryName);

    };


    const brands = branddata || {};
    const sortedCountryNames = Object.keys(brands);

    const [activeCountry, setActiveCountry] = useState(sortedCountryNames[0]);
    // const handleBrand = (index) => {
    //     setBrandIndex(index)
    // }


    return (

        <ul className={classes.tabs}>
            {/* {brand && brands.map((brand, index) => (
                <li key={index}>
                    <Link
                        className={index === brandIndex ? "activeTab" : ""}
                        onClick={() => brandClick(index)}
                    >
                        {brand}
                    </Link>
                </li>
            ))} */}
            {brand && (
                <li>
                    <Link
                        className={brandIndex === 19999991 ? "activeTab" : ""}
                        onClick={() => brandClick(19999991, 0, "Populyar markalar")}
                    >
                        Populyar markalar
                    </Link>
                </li>
            )}
            {sortedCountryNames.map((countryName, index) => (
                // <div key={countryName}>
                //     <h2>{countryName}</h2>
                //     <ul>
                //         {brands[countryName].map((brand) => (
                //             <li key={brand.id}>{brand.name}</li>
                //         ))}
                //     </ul>
                // </div>

                <li key={countryName}
                    onClick={() => brandClick(index, index, countryName)}
                >
                    <Link
                        className={
                            index === currentIndex && brandIndex !== 19999991
                                ? "activeTab"
                                : ""
                        }
                    >
                        {countryName}
                    </Link>
                </li>
            ))}



            {data?.data?.map((category, index) => {
                return (
                    <li key={category.id}>
                        <Link
                            className={index === currentIndex ? "activeTab" : ""}
                            onClick={() => categoryClick(category.id, index, category.category_name)}
                        >
                            {category.category_name}
                        </Link>
                    </li>
                );
            })}
            {/* {data?.data?.map((category, index) => {
                return (
                    <li key={category.id}>
                        <Link
                            className={index === currentIndex ? "activeTab" : ""}
                            onClick={() => categoryClick(category.id, index, category.category_name)}
                        >
                            {category.category_name}
                        </Link>
                    </li>
                );
            })} */}
        </ul>
    );
};

export default memo(Tabpanel);





